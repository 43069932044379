.latest-blog {
    $latest-blog: &;

    padding: 60px 0;
    &__heading {
        font-family: 'Poppins' !important;
        font-size: 51px;
        font-weight: 700;
        color: #262E36;
    }
    &__sub-heading {
        font-family: 'Poppins' !important;
        color: #35B15C;
        font-size: 25px;
        font-weight: 600;
    }
    &__wrapper {
        font-family: 'Poppins' !important;
        display: flex;
        margin-top: 60px;
        flex-wrap: wrap;
        @include media-query(992px){
            flex-wrap: nowrap;
        }
    }
    &__left {
        flex: 0 0 100%;
        @include media-query(992px){
            flex: 0 0 550px;
        }
    }
    &__title {
        font-family: 'Poppins' !important;
        font-size: 31px;
        line-height: 34px;
        font-weight: 600;
        color: #333;
        margin-top: 30px;
        transition: .3s all ease-in-out;
        &:hover {
            color: #35b15c;
        }
    }
    &__author {
        font-family: 'Poppins' !important;
        color: #000;
        font-size: 13px;
        font-weight: 400;
        margin-top: 10px;
        span {
            color: #898989;
        }
        strong {
            font-weight: 600;
        }
    }
    &__right {
        margin-top: 50px;
        @include media-query(992px){
            margin-top: 0;
            margin-left: 70px;
        }
    }
    &__items {
        padding-bottom: 34px;
        margin-bottom: 28px;
        border-bottom: 1px dashed #C8C8C8;
        h5 {
            font-family: 'Poppins' !important;
            font-size: 25px;
            line-height: 32px;
            color: #333;
            transition: .3s all ease-in-out;
        }
        &:hover {
            h5 {
                color: #35b15c;
            }
        }
        &:last-child {
            border-bottom: none;
            margin-top: 35px;
        }
    }
    .block-btn {
        font-family: 'Poppins' !important;
        background: #FF5800;
        color: #fff;
        border: 2px solid #FF5800;
        border-radius: 5px;
        font-size: 17px;
        font-weight: 600;
        text-align: center;
        display: block;
        max-width: 333px;
        padding: 15px 0;
        transition: .3s all ease-in-out;
        svg {
            margin-left: 10px;
        }
        &:hover {
            background: #fff;
            color: #FF5800;
            path {
                fill: #FF5800;
            }
        }
    }
}