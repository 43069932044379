.padding-top-100 {
    padding-top: 80px;
}
.font-family-pro {
    font-family: $main-font;
}
.padding-top-40 {
    padding-top: 40px;
}
.padding-80 {
    padding-top: 40px;
}
.padding-bottom-30 {
    padding-bottom: 30px;
}
.white {
    color: #fff !important;
}
h1 {
    font-size: 24px;
    line-height: 40px;
    font-weight: 700;
    @include responsive(desktop){
        font-size: 62px;
        line-height: 75px;
    }
}
h2 {
    font-size: 24px;
    line-height: 32px;
    font-family: $main-font;
    font-weight: 700;
    @include responsive(desktop){
        font-size: 48px;
        line-height: 61px;
    }
}
.cmn-heading-para {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 20px;
    color: #333;
    font-weight: 500;
    text-align: center;
    font-family: $main-font;
    @include responsive(desktop){
        font-size: 20px;
        line-height: 32px;
        font-family: $main-font;
    }
}
.font-weight-600 {
    font-weight: 600;
}
.margin-top-10 {
    margin-top: 10px;
}
.margin-top-20 {
    margin-top: 20px;
}
.margin-top-30 {
    margin-top: 30px;
}
.margin-bottom-20 {
    margin-bottom: 20px;
}
.cmn-bottom-border {
    border-bottom: 1px solid #dedede;
}
.cmn-top-border {
    border-top: 1px solid #dedede
}
.mobile-off {
    display: none;
    @include responsive(desktop){
        display: block;
    }
}
.bg-grey {
    background-color: #f5f5f5 !important;
}
.lighter-blue {
    color: #4a90e2!important;
}
.font-size-30 {
    font-size: 28px;
}
.btn-center-block {
    margin: 0px auto;
    width: 310px;
    text-transform: uppercase;
    @include responsive(desktop){
       width: inherit;
    }
}
p{
    margin: 0px 0px 25px 0px;
    padding: 0px 0px 0px 0px;
    line-height: 24px;
    .anchor-link {
        font-size: 13px;
        line-height: 19px;
        color: #CBE3FF;
        text-transform: none;
        // margin-top: 0;
        @include responsive(desktop){
            max-width: 340px;
            margin-top: 10px;
        }
    }
}
.o-home-service {
    .anchor-link {
        margin-top: 0;
        @include responsive(desktop){
            margin-top: 15px;
        }
    }
    .n-gradient-green-blue {
        background: linear-gradient(172deg, rgba(3,119,115,1) 7%, rgba(0,81,127,1) 42%, rgba(2,62,102,1) 67%, rgba(5,27,57,1) 100%);
        @include responsive(desktop){
            background: linear-gradient(350deg,#037c71 0,#042b4e 100%);
        }
    }
}

.small-h1 {
    text-align: center;
    h1 {
        color: #fff;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 25px;
        font-weight: 700;
        margin-top: 15px;
        @include responsive(desktop){
            padding: 0 80px;
            font-size: 36px;
            line-height: 51px;
            margin-top: 0;
        }
    }
    h1.padding-0 {
        padding: 0;
    }
    h2 {
        font-size: 22px;
        color: #14E07E;
        // color: #92b3ff;
        margin-top: 25px;
        font-weight: 900;
        font-family: $main-font;
        padding-bottom: 0;
        @include responsive(desktop){
            font-size: 26px;
            margin-top: 25px;
            font-weight: 700;
            margin-bottom: 0;
            padding: 0 0 0;
        }
    }
    .bnr-ofr-text {
        // color: #14E07E;
        color: #b7b7b7;
        font-size: 15px;
        line-height: 22px;
        margin-bottom: 0;
        font-weight: bold;
        font-family: $main-font;
        @include responsive(desktop){
            padding: 0 280px;
            line-height: 36px;
            font-size: 18px;
            font-weight: 600;
            margin-top: 10px;
        }
        a {
            text-decoration: underline;
            // color: #14E07E;
            color: #b7b7b7;
        }
        br {
            display: none;
            @include responsive(desktop){
                display: block;
            }
        }
    }
}

.bnr-ofr-text {
    // padding: 0 15px;
    // @include responsive(desktop){
    //     padding: 0;
    // }
    br {
        display: block;
    }
}

/*services section*/
.web-app-service {
    padding-top: 40px;
    padding-bottom: 20px;
    position: relative;
    @include responsive(desktop){
        padding-top: 65px;
        padding-bottom: 45px;
    }
    .service-wrap {
        text-align: center;
        margin-bottom: 35px;
        .brick.services-pan {
            display: inline-block;
            vertical-align: top;
            margin: 15px;
            border: 1px solid #E0E0E0;
            border-radius: 6px;
            box-shadow: 1px 1px 20px rgba(0,0,0,0.1);
            background-color: #fff;
            text-align: center;
            padding: 50px 15px 10px;
            position: relative;


            @include media-query(992px) {
                // height: 569px;
                width: 46%;
            }
            @include media-query(1200px) {
                width: 30%;
                padding: 50px 30px 30px;
            }

            // @include media-query(991px) {
            //     width: 46%;
            // }
            &:before {
                // content: "";
                // background: url(../images/web-application/services-group-icon.png) no-repeat;
                // width: 61px;
                // height: 56px;
                // position: absolute;
                // left: 0;
                // right: 0;
                // margin: 0 auto;
                // top: 9%;
                // // display: none;
                // transform: translate(0);
                // @include responsive(desktop){
                //     display: block;
                // }
                display: none;
            }
            h3 {
                font-size: 20px;
                position: relative;
                // padding-bottom: 10px;
                flex: 0 0 100%;
                @include responsive(desktop){
                    font-size: 24px;
                    line-height: 32px;
                    padding-bottom: 0;
                }
                // &:before {
                //     display: block;
                //     content: '';
                //     background: #14e07e;
                //     width: 60px;
                //     height: 3px;
                //     margin: 0 auto;
                //     position: absolute;
                //     bottom: 0;
                //     left: 0;
                //     right: 0;
                //     @include responsive(desktop){
                //         display: none;
                //     }
                // }
            }
            p {
                font-size: 16px;
                line-height: 23px;
                @include media-query(992px) {
                    font-size: 17px;
                    line-height: 28px;
                }
                @include media-query(1280px) {
                    font-size: 18px;
                    line-height: 30px;
                }
            }
            a {
                // display: none;
                margin-bottom: 30px;
                display: block;
                @include responsive(desktop){
                    margin-top: auto;
                    margin-bottom: 0;
                }
            }
            i {
                display: block;
                height: 55px;
                img {
                    height: 100%;
                }
            }
        }
            .brick.services-pan__php-service-01 {
                &:before {
                    background-position: 0 0;
                }
            }
            .brick.services-pan__php-service-02 {
                &:before {
                    background-position: -62px 0;
                }
            }
            .brick.services-pan__php-service-03 {
                &:before {
                    background-position: -123px 0;
                }
            }
            .brick.services-pan__php-service-04 {
                &:before {
                    background-position: -183px 0;
                }
            }
            .brick.services-pan__php-service-05 {
                &:before {
                    background-position: 0 -57px;
                }
            }
            .brick.services-pan__php-service-06 {
                &:before {
                    background-position: -62px -55px;
                }
            }
            .brick.services-pan__php-service-07 {
                &:before {
                    background-position: -123px -57px;
                }
            }
            .brick.services-pan__php-service-08 {
                &:before {
                    background-position: -183px -57px;
                }
            }
        }
}
/*faq section*/
.o-case-studies-new {
    padding-top: 40px;
    @include responsive(desktop){
        padding: 80px 0;
    }
    h2 {
        font-size: 24px;
        line-height: 30px;
        padding-bottom: 15px;
        text-align: center;
        color: #333;
        // font-weight: 700;
        @include responsive(desktop){
            font-size: 48px;
            line-height: 61px;
            padding-bottom: 30px;
        }
    }
    .margin-bottom-20 {
        margin-bottom: 10px;
    }
    .faq-pan {
        margin-bottom: 20px;
    }
    .faq-holder {
        box-shadow: 1px 1px 20px rgba(0,0,0,0.1);
        .accordion1 {
                font-size: 16px;
                line-height: 22px;
                padding: 15px 35px 15px 15px;
                color: #333;
                cursor: pointer;
                width: 100%;
                text-align: left;
                font-family: "Montserrat",sans-serif;
                font-weight: bold;
                position: relative;
                text-transform: none;
                display: flex;
                align-items: center;
                padding-right: 45px;
                background-color: #fff;
                @include responsive(desktop){
                    padding: 18px;
                    border: none;
                    outline: none;
                    font-size: 19px;
                    line-height: 25px;
                    transition: 0.4s;
                    padding-right: 18px;
                }
                &:after {
                    content: '\002B';
                    color: #27C070;
                    font-weight: normal;
                    float: right;
                    margin-left: 5px;
                    font-size: 30px;
                    position: absolute;
                    right: 18px;
                    // top: 18px;
                }
            }
            .active1:after {
                content: "\2212";
            }
            .panel1 {
                padding: 0 18px;
                background-color: white;
                overflow: hidden;
                transition: max-height 0.2s ease-out;
                display: none;
                font-size: 19px;
                line-height: 30px;
                font-family: $secondary-font;
                font-weight: 500;
                p {
                    font-size: 16px;
                    line-height: 30px;
                    font-family: $main-font;
                    @include responsive(desktop){
                    font-size: 19px;
                    line-height: 30px;
                    font-family: $secondary-font;
                    font-weight: 500;
                }
            }
        }
    }
}
.o-bottom-form {
    padding: 0;
    @include responsive(desktop){
        padding-right: 15px;
        padding-left: 15px;
        padding: 80px 0 90px;
    }
    .form-text {
        h2 {
            padding-bottom: 20px;
            &:before {
                content: '';
                position: absolute;
                background: #2ebf72;
                width: 50px;
                height: 1px;
                bottom: 7px;
                left: 0;
            }
        }
    }
    .container {
        padding-left: 0;
        padding-right: 0;
        @include responsive(desktop){
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}
/*let's talk*/
.lets-talk {
    background: url(/images/common/schedule-talk-bg.jpg);
    background-size: cover;
    padding: 30px 0 45px;
    margin-top: 0;
    @include responsive(desktop){
        margin-top: 25px;
    }
    h2 {
        color: #fff;
        padding-bottom: 5px;
        font-size: 22px;
        line-height: 30px;
        @include responsive(desktop){
            font-size: 48px;
            line-height: 61px;
            padding-bottom: 25px;
        }
        span {
            display: block;
            @include responsive(desktop){
                display: inline;
            }
        }
    }
}


/*Custom software services*/
.mobile-on-cs {
    display: block;
    @include responsive(desktop){
        display: none !important;
    }
}
.mobile-on-flex {
    display: flex !important;
    @include responsive(desktop){
        display: none !important;
    }
}
.mobile-off {
    display: none;
    @include responsive(desktop){
        display: block;
    }
}
.mobile-off-flex {
    display: none;
    @include responsive(desktop){
        display: flex;
    }
}


.masonry.service-wrap {
    @include responsive(desktop){
        column-count: 3;
        column-gap: 27px;
        margin-bottom: 30px;
    }
}


/*Mobile app*/


.h3-24 {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
}
.c-ticked-list--light {
    li {
        position: relative;
        padding: 0 0 5px 30px;
        line-height: 24px;
        font-size: 16px;
        font-weight: 400;
        font-family: $main-font;
        @include responsive(desktop){
            font-family: $main-font;
            font-weight: 500;
            line-height: 29px;
            font-size: 19px;
            padding: 0 0 15px 35px;
        }
        &:before {
            content: '';
            position: absolute;
            background: url(/images/mobile-app/circle-tick.svg) 50% 50% no-repeat;
            display: block;
            width: 20px;
            height: 20px;
            position: absolute;
            top: 4px;
            left: 0px;
            @include responsive(desktop){
                background: url(/images/common/icon-bullet.svg) 50% 50% no-repeat;
                top: 5px;
            }
        }
    }
}
.o-mobile-app-features--green {
    background: #43AE76;
}
/* Mobile Tab */
.o-mobile-app-features-parent{
    position: relative;
}
.o-mobile-app-features-tab{
    position: absolute;
    top:50px;
    left: 0;
    right: 0;
    z-index: 10;
    display: flex;
    justify-content: space-between;
}

.o-mobile-app-features-tab-btn{
    padding:0.5rem 1rem;
    border:1px solid #fff;
    border-radius: 3px;
    font-family: Montserrat,sans-serif;
    font-size:16px;
    font-weight:600;
    color: #fff;
    text-transform: none;
    margin-right: 10px;

    &:last-child{
        margin: 0;
    }
    &:before, &:after{
        content: '';
        border: 9px solid transparent;
        border-top-color: #fff;
        border-bottom: 0;
        position: absolute;
        left: 50%;
        bottom: -9px;
        transform: translate(-50%, 0);
        display:none;
    }
    &:after{
        border: 8px solid transparent;
        border-top-color: #115687;
        border-bottom: 0;
        bottom: -7px;
        z-index: 1;
    }
    &.active{
        &:before, &:after{
            display:block
        }
    }
    &--bg-blue{
        background: #115687;
    }
    &--bg-green{
        background: #43ae76;
        &:after{
            border-top-color: #43ae76;
        }
    }
}
.service-details.daas-service .service-wrap .brick.services-pan {
    display: block;
    @include responsive(desktop){
        display: inline-block;
    }
}
.no-webp {
    .tl-pt:before {
        background-image: url(/images/common/tl-pt.png);
    }
}
.webp {
    .tl-pt:before {
        background-image: url(/images/webp/common/tl-pt.webp);
    }
}

.tl-pt {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        background-repeat: no-repeat;
        background-position: top left;
        width: 200px;
        height: 80px;
        background-size: 75%;
        top: 0;
        left: 0;
        @include responsive(desktop){
            width: 517px;
            height: 206px;
            background-size: 100%;
        }
    }
}

.no-webp {
    .br-pt:after {
        background-image: url(/images/common/mb-br-pt.png);
        @include responsive(desktop){
            background-image: url(/images/common/br-pt.png);
        }
    }
}
.webp {
    .br-pt:after {
        background-image: url(/images/common/mb-br-pt.webp);
        @include responsive(desktop){
            background-image: url(/images/webp/common/br-pt.webp);
        }
    }
}
.br-pt {
    position: relative;
    &:after {
        content: '';
        position: absolute;
        background-repeat: no-repeat;
        background-position: bottom right;
        width: 160px;
        @include responsive(desktop){
            width: 200px;
        }
        height: 80px;
        // transform: rotate(180deg);
        bottom: 0;
        right: 0;
        background-size: 100%;
        @include responsive(desktop){
            width: 500px;
            height: 240px;
            background-size: 100%;
        }
    }
}

.br-pt.hm-bnr {
    &:after {
        background-position: bottom right;
        @include responsive(desktop){
            background-position: 160px 0;
        }
    }

}

.no-webp {
    .tr-pt:before {
        background-image: url(/images/common/mb-tr-pt.png);
        @include responsive(desktop){
            background-image: url(/images/common/tr-pt.png);
        }
    }
}
.webp {
    .tr-pt:before {
        background-image: url(/images/common/mb-tr-pt.webp);
        @include responsive(desktop){
            background-image: url(/images/webp/common/tr-pt.webp);
        }
    }
}
.tr-pt {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        background-repeat: no-repeat;
        background-position: top right;
        background-size: 100%;
        height: 80px;
        width: 200px;
        top: 0;
        right: 0;
        @include responsive(desktop){
            display: block;
            background-size: 100%;
            width: 530px;
            height: 215px;
        }
    }
}
.no-webp {
    .bl-pt:after {
        background-image: url(/images/common/tr-pt.png);
    }
}
.webp {
    .bl-pt:after {
        background-image: url(/images/webp/common/tr-pt.webp);
    }
}
.bl-pt {
    position: relative;
    &:after {
        content: '';
        position: absolute;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: bottom left;
        height: 75px;
        width: 200px;
        bottom: 0;
        left: 0;
        transform: rotate(-180deg);
        @include responsive(desktop){
            display: block;
            background-size: 100%;
            width: 530px;
            height: 215px;
        }
    }
}

.no-webp {
    .mb-tl-pt:after {
        background-image: url(/images/common/mb-tl-pt.png);
    }
}
.webp {
    .mb-tl-pt:after {
        background-image: url(/images/common/mb-tl-pt.webp);
    }
}

.mb-tl-pt {
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 200px;
        height: 100px;
        background-size: 100%;
        background-position: top left;
        background-repeat: no-repeat;
        transform: translate(0);
        @include responsive(desktop){
            display: none;
        }
    }
}

.no-webp {
    .mb-w-tr-pt:before {
        background-image: url(/images/common/mb-w-tr-pt.png);
    }
}
.webp {
    .mb-w-tr-pt:before {
        background-image: url(/images/common/mb-w-tr-pt.webp);
    }
}

.mb-w-tr-pt {
    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 200px;
        height: 100px;
        background-size: 100%;
        background-position: top right;
        background-repeat: no-repeat;
        z-index: 99;
        @include responsive(desktop){
            display: none;
        }
    }
}


.acnt-p {
    @include responsive(desktop){
        font-size: 16px;
    }
}
.form-panel {
    .u-submit {
        @include responsive(desktop){
            margin-top: 15px;
        }
    }
}
.img-cr-block {
    margin: 0 auto;
    display: block;
}
.ai-chatbots-hero {
    .container {
        padding-top: 100px;
    }
}

.new-srv-slider {
    .col-sm-6, .col-sm-6 {
        flex-wrap: wrap;
        flex: 50% !important;
        max-width: 50%;
    }
}


.o-apply-now {
    .anchor-link {
        max-width: 340px;
    }
}
.cs-slider-img {
    img {
        width: 100%;
        @include responsive(desktop){
            height: 100%;
        }
    }
}

.ai-bot {
    .insights-section .fi-pan a {
        min-height: 370px;
    }
}

.navbar-brand source {
    width: 80%;
    @include responsive(desktop){
        width: auto;
    }
}
.navbar-brand img {
    width: 100%;
    // @include responsive(desktop){
    //     width: auto;
    // }
}


.border-bottom-lightgreen {
    border-bottom: 1px solid #dedede;
}

.o-home-cn-details.pad-bot-40 {
    padding-bottom: 20px;
    @include responsive(desktop){
        padding-bottom: 50px;
     }
}
.cstm-link-p {
    margin-top: 0;
    margin-bottom: 25px;
    @include responsive(desktop){
        margin-top: 5px;
    }
}
.splide__pagination {
    position: static;
    transform: translate(0);
    .splide__pagination__page {
        margin: 3px 5px;
    }
    .splide__pagination__page.is-active {
      transform: scale(1.4);
      background: #27c070;
    }
    // @include responsive(desktop){
    //     display: none;
    // }
}
.o-case-studies-slider {
    .splide__pagination {
        margin-top: 30px;
    }
    .btn_wrapper.pad-top-50 {
        padding-top: 20px;
        @include responsive(desktop){
            padding-top: 20px;
        }
    }
}

.crop-pad-top {
    margin-top: 10px;
    @include responsive(desktop){
        margin-top: 0;
    }
}

.splide__pagination {
    width: 100%;
}
.insights-section {
    padding: 45px 0 50px !important;
    @include responsive(desktop){
        padding: 70px 0 80px!important;
    }
    .btn_wrapper {
        padding-top: 0;
        @include responsive(desktop){
            padding-top: 40px;
        }
    }
}


.splide__pagination__page {
    background: #666565;
}
.pager_new {
    display: block;
    @include responsive(desktop){
        display: none;
    }
}
.lSSlideOuter {
    padding-bottom: 25px;
    position: relative;
}

.fa-circle {
    &:before {
        font-family: 'Font Awesome\ 5 Free';
    }
}
.lSSlideOuter.custom-color .lSPager.lSGallery li i{
    color: #9b9b9b !important;
}
.lSSlideOuter.custom-color .lSPager.lSGallery li.active i,
li.focus .dot_sliders {
    color: #27c070 !important;
}
.lSSlideOuter.custom-color .lSPager.lSGallery {
    margin-top: 75px !important;
}
.o-case-studies-slider {
    .slick-dots {
        margin-bottom: 15px;
    }
}

.career-bnr {
	padding-top: 120px;
	@include responsive(desktop){
		padding-top: 80px;
	}
    img {
        margin: 0 auto;
        @include responsive(desktop){
            margin: 0 auto;
        }
    }
}
.service-wrap {
    .services-pan {
        p {
            font-family: $main-font !important;
            @include responsive(desktop){
                font-family: $main-font !important;
            }
        }
    }
}
.c-icon-desc-3-col-ccn .row>div p {
    font-family: $main-font !important;
    @include responsive(desktop){
        font-family: $main-font !important;
    }
}
.o-case-studies-slider .cs-slider .cs-slider-text h4,
.o-case-studies-slider .cs-slider .cs-slider-text .cs-tech,
.o-case-studies-slider .cs-slider .cs-slider-text p,
.o-case-studies-new .faq-holder .panel1 p,
.insights-section .fi-pan p,
.new-review-box h4,
.o-bottom-form .form-text p.sub-heading,
.o-bottom-form .form-text p,
.o-bottom-form .form-text ul li span {
    font-family: $main-font !important;
    @include responsive(desktop){
        font-family: $main-font !important;
    }
}
.home-faq {
    padding-top: 45px !important;
    padding-bottom: 20px;
    @include responsive(desktop){
        padding: 65px 0 50px !important;
    }
}
.faq-section {
    padding-top: 45px !important;
    @include responsive(desktop){
        padding: 60px 0 60px !important;
    }
    .container {
        position: relative;
        z-index: 9;
    }
}

// .hm-page {
//     h1, h2, h3, h4, h5, h6, a, p, li, span, small, strong {
//         font-family: $main-font !important;
//     }
//     .o-case-studies-slider .cs-slider .cs-slider-text h4,
//     .o-case-studies-slider .cs-slider .cs-slider-text .cs-tech,
//     .o-case-studies-slider .cs-slider .cs-slider-text p,
//     .o-case-studies-new .faq-holder .panel1 p,
//     .insights-section .fi-pan p,
//     .new-review-box h4,
//     .o-bottom-form .form-text p.sub-heading,
//     .o-bottom-form .form-text p,
//     .o-bottom-form .form-text ul li span {
//         font-family: $main-font !important;
//     }
// }


.cto-wrap i {
    height: 70px;
    display: flex;
    justify-content: center;
}

.first-letter-small {
    &:first-letter {
        text-transform: lowercase;
    }
}

.apart-p {
    font-size: 16px;
    line-height: 24px;
    @include responsive(desktop){
        font-size: 19px;
        line-height: 32px;
    }
}


.panel1 {
    ul li {
        font-family: Montserrat,sans-serif;
    }
}

.font-size-50 {
    font-size: 30px;
    @include media-query(992px) {
        font-size: 50px !important;
    }
}

.aprch-blockchain {
    .masonry .brick {
        @include media-query(992px) {
            flex: 1;
        }
    }
}

.ind-off-section {
    .service-wrap {
        @include media-query(992px) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
        .brick.services-pan {
            @include media-query(992px) {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                padding: 50px 30px 50px;
            }
        }
    }
}
.home-ind-off-section .service-wrap .brick.services-pan {
    @include media-query(992px) {
        align-content: flex-start;
        padding: 50px 30px 100px;
    }
    a {
        @include media-query(992px) {
            position: absolute;
            bottom: 50px;
        }
    }
    h3 {
        flex: 0 0 100%;
    }
}

.ind-off-section-com {
    .service-wrap {
        @include media-query(992px) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
        .brick.services-pan {
            @include media-query(992px) {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                padding: 50px 30px 50px;
                align-content: baseline;
            }
        }
    }
}

.mobile-container {
    padding: 0 30px !important;
    @include responsive(desktop){
        padding: 0 15px !important;
    }
}



.new-service-section {
    padding: 40px 0 10px;
    @include media-query(992px) {
        padding: 70px 0 60px;
    }
    h2 {
        margin-bottom: 30px;
        @include media-query(992px) {
            margin-bottom: 70px;
        }
    }
    &-wrapper {
        display: flex;
        flex-wrap: wrap;
        @include media-query(992px) {
            display: inherit;
            column-count: 2;
            column-gap: 30px;
        }
    }
    &-box {
        margin-bottom: 35px;
        text-align: center;
        @include media-query(992px) {
            margin-bottom: 50px;
            break-inside: avoid;
            page-break-inside: avoid;
            display: flex;
        }
        i {
            display: block;
            width: 50px;
            margin: 0 auto 20px;
            @include media-query(992px) {
                display: inline-block;
                margin: 0;
            }
        }
        &-right {
            flex: 1;
            text-align: center;
            @include media-query(992px) {
                padding-left: 20px;
                margin-left: 10px;
                text-align: left;
            }
            h3 {
                font-size: 20px;
                @include media-query(992px) {
                    font-size: 26px;
                }
                font-weight: 500;
                margin-bottom: 15px;
            }
            p {
                font-size: 16px;
                @include media-query(992px) {
                    line-height: 28px;
                }
                @include media-query(1200px) {
                    font-size: 17px;
                }
                @include media-query(1280px) {
                    font-size: 18px;
                }
                margin-bottom: 0;
                a {
                    display: inline;
                    margin: 0;
                    font-size: 18px;
                    width: inherit;
                    position: inherit;
                    color: #333;
                    text-decoration: underline;
                    &:before {
                        display: none;
                    }
                }
            }
             a {
                font-size: 16px;
                display: block;
                margin: 5px auto 0;
                position: relative;
                width: 107px;
                transition: .3s all ease-in-out;
                &:before {
                        content: "➞";
                        position: absolute;
                        right: -17px;
                        top: 0;
                        transition: .3s all ease-in-out;
                }
                @include media-query(1200px) {
                    font-size: 17px;
                    margin-top: 5px;
                    margin-left: 0;
                    margin-right: 0;
                }
                @include media-query(1280px){
                    font-size: 18px;
                }
                &:hover {
                    text-decoration: underline;
                    color: darken(#0d6efd, 10%);
                    &:before {
                        right: -22px;
                    }
                }
             }
        }
    }
    .ns-order-1 {
        order: 1;
    }
    .ns-order-2 {
        order: 2;
    }
    .ns-order-3 {
        order: 3;
    }
    .ns-order-4 {
        order: 4;
    }
    .ns-order-5 {
        order: 5;
    }
    .ns-order-6 {
        order: 6;
    }
    .ns-order-7 {
        order: 7;
    }
    .ns-order-8 {
        order: 8;
    }
    .ns-order-9 {
        order: 9;
    }
    .ns-order-10 {
        order: 10;
    }
    .ns-order-11 {
        order: 11;
    }
    .ns-order-12 {
        order: 12;
    }
    .ns-order-13 {
        order: 13;
    }
    .ns-order-14 {
        order: 14;
    }
    .ns-order-15 {
        order: 15;
    }
}
