// Home Events section start
.o-latest-events{
    position: relative;
    &__list{
      margin: 0 auto 120px;
      max-width: 950px;
      width: 100%;
    }
    &__box{
      position: relative;
      display: flex;
      flex-direction: column;
      min-height: fit-content;
      max-height: fit-content;
      border: 1px solid #d9d9d9;
      box-shadow: 0 10px 20px 0 rgba(0,0,0,.05);
      background-color: #fff;
      text-decoration: none;
      color: #333;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      &:hover{
        color: #333;
      }
      @include media-query(768px) {
        flex-direction: row;
        max-height: 280px;
        min-height: 280px;
      }
    }
    &__box-img{
      position: relative;
      flex: 0 0 auto;
      @include media-query(768px) {
        height: 100%;
        flex: 0 0 53%;
      }
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    &__box-status-current{
        position: absolute;
        right: 5px;
        top: 5px;
        height: 40px;
        background-color: #28a745;
        font-family: Poppins;
        font-size: 16px;
        color: #fff;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 15px;
        @include media-query(768px) {
            top: 15px;
            right: -15px;
        }
    }
    &__box-status-upcoming{
        position: absolute;
        right: 5px;
        top: 5px;
        height: 40px;
        background-color: #fd7e14;
        font-family: Poppins;
        font-size: 16px;
        color: #fff;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 15px;
        @include media-query(768px) {
            top: 15px;
            right: -15px;
        }
    }
    &__box-details{
        padding: 20px;
        flex: 0 0 auto;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        @include media-query(768px) {
            padding: 20px 38px;
            flex: 0 0 47%;
        }
        @include media-query(992px) {
            padding: 25px 60px;
        }
    }
    &__box-name{
      font-family: Poppins;
      font-size: 24px;
      line-height: 29px;
      font-weight: 700;
      color: #333;
      margin-bottom: 5px;
    }
    &__box-sub{
      font-family: Poppins;
      font-size: 18px;
      line-height: 26px;
    }
    &__btn{
      max-width: 248px;
      height: 62px;
      text-transform: capitalize;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__box-btn{
      font-family: Poppins;
      font-size: 18px;
      font-weight: 600;
      color: #333;
      border-radius: 5px;
      border: 1px solid #ff5800;
      max-width: 245px;
      padding: 15px 0;
      display: block;
      text-align: center;
      transition: .3s all ease-in-out;
      margin: 20px 0 0;
      svg{
        transition: .3s all ease-in-out;
        margin-left: 15px;
      }
      &:hover{
        background: #ff5800;
        color: #fff;
        svg{
          transform: translateX(10px);
          path{
            fill: #fff;
          }
        }
      }
    }
    .splide__arrows{
      display: block;
    }
    .splide__arrow{
      font-size: 45px;
      color: #000;
      background-color: #fff;
      width: 45px;
      height: 45px;
      border: 1px solid #3cc065;
      line-height: 0;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 0 5px;
      font-weight: 300;
      bottom: -110px;
      top: inherit;
      svg{
        position: relative;
        height: 20px;
        line-height: 0;
        width: 20px;
      }
      &--prev{
        left: 32%;
        @include media-query(992px) {
          left: 40%;
        }
        @include media-query(1110px) {
          left: 44%;
        }
      }
      &--next{
        right: 32%;
        @include media-query(992px) {
          right: 40%;
        }
        @include media-query(1110px) {
          right: 44%;
        }
      }
    }
  }
  // Home Events section Ends