.h-case-study {
    $h-case-study: &;
    position: relative;
    &__heading {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 221px;
        height: 58px;
        margin-bottom: 0;
        border-radius: 26px;
        padding: 2px;
        overflow: hidden;
        background: rgb(35,212,118);
        background: -moz-linear-gradient(138deg, rgba(35,212,118,1) 0%, rgba(54,90,217,1) 84%);
        background: -webkit-linear-gradient(138deg, rgba(35,212,118,1) 0%, rgba(54,90,217,1) 84%);
        background: linear-gradient(138deg, rgba(35,212,118,1) 0%, rgba(54,90,217,1) 84%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#23d476",endColorstr="#365ad9",GradientType=1);
        &::before {
            content: '';
            position: absolute;
            top: -77px;
            right: -29px;
            bottom: 0;
            left: 0;
            width: 225px;
            height: 225px;
            animation: rotation2 3s linear infinite;
            background: -moz-linear-gradient(180deg, rgba(136,222,177,1) 0%, rgba(26,184,100,1) 20%, rgba(35,116,212,0.62) 52%, rgba(6,87,181,0.62) 100%);
            background: -webkit-linear-gradient(180deg, rgba(136,222,177,1) 0%, rgba(26,184,100,1) 20%, rgba(35,116,212,0.62) 52%, rgba(6,87,181,0.62) 100%);
            background: linear-gradient(180deg, rgba(136,222,177,1) 0%, rgba(26,184,100,1) 20%, rgba(35,116,212,0.62) 52%, rgba(6,87,181,0.62) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1);
            z-index: 9;
        }
    }
    &__heading-inner {
        color: #333;
        font-family: 'Poppins' !important;
        font-size: 25px;
        line-height: 34px;
        font-weight: 600;
        background: #fff;
        background-repeat: no-repeat;
        background-position: -344px -76px;
        display: flex;
        flex: 1;
        height: 100%;
        align-items: center;
        justify-content: center;
        border-radius: 26px;
        position: relative;
        z-index: 9;
    }
    &__title {
        font-family: 'Poppins' !important;
        font-size: 50px;
        line-height: 60px;
        font-weight: 700;
        max-width: 850px;
        margin-bottom: 25px;
        color: #fff;
    }
    &__list {
        display: flex;
        align-items: center;
        // gap: 7px;
        margin-bottom: 25px;
    }
    &__items {
        color: #fff;
        font-family: 'Poppins' !important;
        font-size: 14px;
        line-height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #fff;
        border-radius: 70px;
        padding: 10px 20px;
        border-radius: 70px;
        padding: 10px 20px;
        background: rgba(171,168,168,0.3);
        margin:3px;
    }
    &__read-more {
        font-family: 'Poppins' !important;
        background: #FF5800;
        border: 1px solid #FF5800;
        border-radius: 5px;
        color: #fff;
        font-size: 17px;
        font-weight: 600;
        max-width: 248px;
        height: 62px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;
        margin-top: 35px;
        &:hover {
            background: #fff;
            color: #FF5800;
            path {
                fill: #FF5800;
            }
        }
        svg {
            padding-left: 10px;
        }
    }
    .owl-dots {
        margin-bottom: 25px;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        max-width: 1140px;
        @include media-query(992px) {
            bottom: 60px;
            max-width: 930px;
        }
        @include media-query(1200px) {
            max-width: 1140px;
        }
        .owl-dot {
            background: #fff;
            width: 10px;
            height: 10px;
            display: inline-block;
            border-radius: 50%;
            margin-right: 15px;
            &.active {
                background: #3F6EE7;
                width: 13px;
                height: 13px;
            }
        }
    }
    .splide__pagination {
        margin-bottom: 25px;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        max-width: 1140px;
        @include media-query(992px) {
            bottom: 60px;
            max-width: 930px;
            justify-content: flex-start;
            padding-left: 0;
        }
        @include media-query(1200px) {
            max-width: 1140px;
        }
        .splide__pagination__page {
            background: #fff;
            width: 10px;
            height: 10px;
            display: inline-block;
            border-radius: 50%;
            margin-right: 10px;
            opacity: 1;
            &.is-active {
                background: #3F6EE7;
                width: 13px;
                height: 13px;
            }
        }
    }
    &__slide-img-wrapper {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__container {
        position: absolute;
        top: 60px;
        right: 0;
        left: 0;
        margin: 0 auto;
    }
    &__slide-container {
        position: absolute;
        top: 130px;
        right: 0;
        left: 0;
        margin: 0 auto;
    }
    &__slide-img-wrapper {
        height: 540px;
    }
    .sk-fading-circle {
        margin: 0 auto;
        position: absolute;
        text-align: center;
        top: 225px;
        left: 0;
        right: 0;
    }
    .cs-cont {
        &.invisible {
            height: 540px;
        }
    }
    .sr-only {
        white-space: nowrap;
    }
}