.employees-speak {
    $employees-speak: &;

    // background: url(/images/employees-speak/emp-speak-bg-bottom.png) no-repeat bottom left, #062F5C;
    background-color: #062F5C;
    padding: 50px 0;
    @include media-query(992px){
        padding: 60px 0;
    }
    &__heading {
        margin-bottom: 40px;
        display: flex;
        align-items: center
    }
    &__heading-text {
        flex: 1;
    }
    &__heading-rating {
        flex: 0 0 370px;
        max-width: 370px;
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 48px;
            height: 117px;
            background: url(/images/employees-speak/left-arrow.svg) no-repeat;
        }
        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 69px;
            height: 130px;
            background: url(/images/employees-speak/right-arrow.svg) no-repeat;
        }
    }
    &__rating-title {
        font-family: 'Poppins' !important;
        color: #fff;
        font-size: 18px;
        line-height: 30px;
        font-weight: 600;
    }
    &__rating {
        display: flex;
        align-items: center;
    }
    &__title {
        font-family: 'Poppins' !important;
        font-size: 38px;
        line-height: 50px;
        font-weight: 700;
        color: #fff;
        @include media-query(992px){
            font-size: 51px;
            line-height: 56px;
        }
    }
    &__sub-title {
        font-family: 'Poppins' !important;
        font-size: 25px;
        line-height: 32px;
        font-weight: 600;
        color: #35B15C;
    }
    &__video {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 65px;
        flex-wrap: wrap;
        @include media-query(992px){
            flex-wrap: nowrap;
            justify-content: space-between;
        }
    }
    &__card {
        max-width: 265px;
        height: 230px;
        position: relative;
        width: 44%;
        margin:20px;
        @include media-query(992px){
            width: 24.5%;
            margin:0;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .vid-play-btn {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            font-size: 0;
            &:before {
                content: '';
                position: absolute;
                bottom: -30px;
                right: 20px;
                width: 59px;
                height: 59px;
                background: url(/images/employees-speak/play-btn.svg) no-repeat 0 0;
            }
        }
    }
    .testimonial-clip-modal {
        z-index: 9999999;
        background: rgba(0,0,0,.95);
    }
    .modal {
        position: fixed;
        top: 0;
        left: 0;
        display: none;
        width: 100%;
        height: 100%;
        overflow: hidden;
        outline: 0;
    }
    .modal.show .modal-dialog {
        transform: translate(0,50px);
    }
    .testimonial-clip-modal .modal-dialog {
        max-width: 990px!important;
        margin: 0 auto;
    }
    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem);
        display: flex;
        align-items: center;
    }
    .modal-dialog {
        position: relative;
        width: auto;
        pointer-events: none;
    }
    .testimonial-clip-modal .modal-content {
        background: 0 0;
        padding: 0;
        border: 0;
    }
    .testimonial-clip-modal .modal-header {
        border: 0;
        height: 0;
        padding: 0;
    }
    .modal-header {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 1rem;
        border-bottom: 1px solid #dee2e6;
        border-top-left-radius: calc(0.3rem - 1px);
        border-top-right-radius: calc(0.3rem - 1px);
    }
    .testimonial-clip-modal .modal-content .modal-body {
        padding: 0;
    }
    .modal-backdrop.show {
        opacity: 0;
        display: none;
    }
    .modal.show .modal-dialog{
        transform: translate(0,50px);
    }
    .testimonial-clip-modal .modal-header .btn-close {
        position: absolute;
        top: 0;
        right: 20px;
        background-color: #fff;
        opacity: 1;
        border-radius: 50%;
        @include media-query(992px) {
            top: -7px;
            right: 64px;
        }
    }
    .block-btn {
        font-family: 'Poppins' !important;
        max-width: 330px;
        padding: 15px;
        margin: 0 auto;
        background: #FF5800;
        border: 2px solid #FF5800;
        border-radius: 5px;
        color: #fff;
        display: block;
        text-align: center;
        font-size: 17px;
        font-weight: 600;
        transition: .3s all ease-in-out;
        svg {
            margin-left: 10px;
        }
        &:hover {
            color: #FF5800;
            background: #fff;
            path {
                fill: #FF5800;
            }
        }
    }
}