@charset "UTF-8";
/*
    ==========================
    TRANSITION & HELPER MIXINS
    ==========================
*/
/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/Poppins-Regular.woff2) format("woff2"); }

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/Poppins-Medium.woff2) format("woff2"); }

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/Poppins-SemiBold.woff2) format("woff2"); }

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/Poppins-Bold.woff2) format("woff2"); }

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/fonts/Poppins-ExtraBold.woff2) format("woff2"); }

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/fonts/Poppins-Black.woff2) format("woff2"); }

/* FONTS declear start */
/* FONTS declear end */
/* Site color declear start */
img {
  max-width: 100%;
  height: auto; }

/*======Responsive starts=======*/
/*======Responsive ends=======*/
body {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  color: #333; }
  body.home-page-spacer {
    padding-top: 72px; }
    @media screen and (min-width: 992px) {
      body.home-page-spacer {
        padding-top: 70px; } }
  body.no-padding {
    padding-top: 0; }
  @media (max-width: 768px) {
    body.o-fixedBody {
      position: fixed;
      top: 0;
      width: 100%;
      left: 0; } }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  color: #333;
  position: relative;
  font-weight: 600; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  color: #333;
  position: relative;
  font-weight: 600; }

h1,
.h1 {
  font-size: 34px; }
  @media (min-width: 992px) {
    h1,
    .h1 {
      font-size: 62px;
      line-height: 75px; } }

h2,
.h2 {
  font-size: 30px; }
  @media (min-width: 992px) {
    h2,
    .h2 {
      font-size: 48px; } }

h3,
.h3 {
  font-size: 26px; }

h4,
.h4 {
  font-size: 22px; }

h5,
.h5 {
  font-size: 20px; }

h6,
.h6 {
  font-size: 18px; }

p {
  line-height: 25px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 0 25px;
  font-family: "Montserrat", sans-serif;
  color: #333; }

a,
input,
button,
svg {
  font-family: "Montserrat", sans-serif;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s; }

button,
input[type="submit"],
input[type="reset"] {
  cursor: pointer;
  background: none;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  border: none;
  text-transform: uppercase;
  width: 100%;
  position: relative;
  z-index: 2; }

a,
button,
textarea,
select {
  text-decoration: none; }
  a:hover, a:focus, a:active, a:focus,
  button:hover,
  button:focus,
  button:active,
  button:focus,
  textarea:hover,
  textarea:focus,
  textarea:active,
  textarea:focus,
  select:hover,
  select:focus,
  select:active,
  select:focus {
    text-decoration: none;
    outline: none; }

fieldset {
  border: 1px solid #dbd7d7;
  padding: 15px;
  margin: 0 0 25px; }
  fieldset legend {
    font-size: 14px;
    color: #333;
    width: auto;
    padding: 0 10px;
    border-bottom: none;
    float: none;
    margin: 0; }
  fieldset input[type="text"],
  fieldset input[type="tel"],
  fieldset input[type="url"],
  fieldset select,
  fieldset textarea {
    border: none;
    color: #797979;
    font-weight: 400;
    width: 100%;
    padding: 0 10px; }
  fieldset textarea {
    height: 150px; }

textarea {
  resize: none; }

select::-ms-expand {
  display: none; }

::placeholder {
  color: #797979;
  font-weight: 400;
  opacity: 1; }

:-ms-input-placeholder {
  color: #797979;
  font-weight: 400;
  opacity: 1; }

::-ms-input-placeholder {
  color: #797979;
  font-weight: 400;
  opacity: 1; }

ul {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0; }

.font-family-sour {
  font-family: "Montserrat", sans-serif; }

.d-block {
  display: block; }

.d-inline-block {
  display: inline-block; }

.white-color {
  color: #fff !important; }

.black-color {
  color: #333 !important; }

.white-bg-color {
  background-color: #fff; }

.grey-bg-color {
  background-color: #f5f5f5; }

.green-color {
  color: #14DF7D; }

.blue-color {
  color: #091B38 !important; }

.light-blue-color {
  color: #4A90E2; }

.pad-80 {
  padding: 50px 0; }
  @media (min-width: 992px) {
    .pad-80 {
      padding: 80px 0; } }

.padding-30 {
  padding: 30px 0; }

.pad-40 {
  padding: 40px 0; }

.padding-50 {
  padding: 50px 0; }

.pad-top-0 {
  padding-top: 0 !important; }

.pad-top-20 {
  padding-top: 20px; }

.pad-top-30 {
  padding-top: 30px; }

.pad-top-40 {
  padding-top: 40px; }

.pad-top-50 {
  padding-top: 50px; }

.pad-top-100 {
  padding-top: 100px; }

.pad-top-120 {
  padding-top: 120px; }

.pad-top-50 {
  padding-top: 50px; }

.pad-bot-10 {
  padding-bottom: 10px; }

.pad-bot-20 {
  padding-bottom: 20px; }

.pad-bot-30 {
  padding-bottom: 30px; }

.pad-bot-40 {
  padding-bottom: 40px; }

.pad-bot-50 {
  padding-bottom: 50px; }

.pad-bot-80 {
  padding-bottom: 40px; }

.pad-bot-150 {
  padding-bottom: 150px; }

.padding-all-60 {
  padding: 60px; }

.margin-bottom-0 {
  margin-bottom: 0; }

.center {
  text-align: center; }

.pattern-br:after {
  background: url(/images/common/rb-pt.png) no-repeat bottom right;
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 200px;
  height: 82px; }

.pattern-tl:after {
  background: url(/images/common/lt-pt.png) no-repeat bottom right;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 160px;
  height: 60px; }

.pattern-tr:after {
  background: url(/images/common/rt-pt.png) no-repeat bottom right;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 200px;
  height: 82px; }

.pattern-bl:after {
  background: url(/images/common/rb-pt.png) no-repeat bottom right;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 200px;
  height: 100px; }

.anchor-link {
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  display: inline-block;
  max-width: 325px;
  margin-top: 10px;
  text-decoration: underline;
  font-weight: 600; }
  @media (min-width: 992px) {
    .anchor-link {
      font-size: 14px; } }
  .anchor-link--white {
    color: #cbe3ff; }

.home-link {
  font-family: "Montserrat", sans-serif;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  text-decoration: underline;
  display: block; }
  @media (min-width: 992px) {
    .home-link {
      font-size: 19px; } }
  .home-link:hover {
    color: #000; }

.n-gradient-green-blue {
  background: -moz-linear-gradient(-45deg, #037c71 0%, #042b4e 100%);
  background: -webkit-linear-gradient(-45deg, #037c71 0%, #042b4e 100%);
  background: linear-gradient(135deg, #037c71 0%, #042b4e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#037c71', endColorstr='#042b4e',GradientType=1 ); }

.gradient-green-blue {
  background: #00b35c;
  background: -moz-linear-gradient(-45deg, #00b35c 0%, #0b8a8a 47%, #004475 100%);
  background: -webkit-gradient(left top, right bottom, color-stop(0%, #00b35c), color-stop(47%, #0b8a8a), color-stop(100%, #004475));
  background: -webkit-linear-gradient(-45deg, #00b35c 0%, #0b8a8a 47%, #004475 100%);
  background: -o-linear-gradient(-45deg, #00b35c 0%, #0b8a8a 47%, #004475 100%);
  background: -ms-linear-gradient(-45deg, #00b35c 0%, #0b8a8a 47%, #004475 100%);
  background: linear-gradient(135deg, #00b35c 0%, #0b8a8a 47%, #004475 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00b35c', endColorstr='#004475', GradientType=1 ); }

.mobile-off {
  display: none; }
  @media (min-width: 992px) {
    .mobile-off {
      display: block; } }

.desktop-off {
  display: block; }
  @media (min-width: 992px) {
    .desktop-off {
      display: none; } }

@media screen and (min-width: 1280px) {
  .container {
    max-width: 1170px; } }

.u-border-color--secondary {
  border-color: #495376 !important; }

.close {
  float: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: .2; }

.modal-close {
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: absolute;
  right: -15px;
  top: -15px;
  background: #fff;
  border: 1px solid #fff;
  opacity: 1;
  width: 30px;
  height: 30px;
  border-radius: 50%; }

.left {
  text-align: left !important; }

.font-size-24 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 500; }

.padding-top-20 {
  padding-top: 20px; }

.font-size-42 {
  font-size: 28px;
  line-height: 42px; }
  @media (min-width: 992px) {
    .font-size-42 {
      font-size: 42px;
      line-height: 52px; } }

.font-size-20 {
  font-size: 20px;
  line-height: 32px; }

.office-infrastructure .lets-talk {
  margin-top: 0; }

.office-infrastructure .o-skills-hero__schedule {
  padding-top: 150px !important; }

.new-contact .modal-backdrop {
  display: none; }

#cookiePopup {
  background: #fff;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  box-shadow: 0 0 10px #ccc;
  z-index: 9999;
  text-align: center;
  display: none; }

#cookiePopup img {
  display: inline-block;
  margin-right: 10px; }

#cookiePopup p {
  font-size: 13px;
  color: #000;
  width: 100%;
  margin: 0;
  font-weight: 500;
  padding-top: 20px; }
  @media (min-width: 992px) {
    #cookiePopup p {
      padding-top: 0; } }

#cookiePopup p a {
  text-decoration: underline;
  color: #000; }

#cookiePopup button {
  font-weight: 600;
  border-radius: 5px;
  color: #000;
  border: 1px solid #000;
  width: 90px;
  padding: 0;
  margin: 10px auto 10px;
  display: block; }
  @media (min-width: 992px) {
    #cookiePopup button {
      margin: 10px 0 10px 30px;
      display: inline-block; } }

.cookie-close {
  position: absolute;
  right: 0;
  top: 10px;
  cursor: pointer; }

.index-bnr-logo {
  margin-bottom: 40px; }

.index-bnr-logo img {
  margin: 10px; }

.capitalize {
  text-transform: capitalize !important; }

.font-weight-700 {
  font-weight: 700 !important; }

.o-blogBttn {
  text-decoration: none;
  background: none;
  background-color: transparent;
  padding: 10px 35px;
  display: block;
  color: #333;
  position: relative;
  width: 90%;
  font-size: 12px;
  border: none;
  border-top: 1px solid #333;
  border-bottom: 1px solid #333; }
  .o-blogBttn.o-submitBttn {
    display: inline-block;
    margin: 0 auto;
    width: auto; }
  .o-blogBttn:hover {
    color: #ff0085; }

.draw::before {
  left: 50%;
  top: -2px;
  transition-duration: 0.4s; }

.draw::after {
  left: 50%;
  bottom: -2px;
  transition-duration: 0.4s; }

.draw:hover::before, .draw:hover::after {
  left: 0; }

.draw::before, .draw::after {
  content: "";
  width: 0;
  height: 2px;
  position: absolute;
  transition: all 0.2s linear;
  background: #ff0085; }

.draw:hover::before, .draw:hover::after {
  width: 100%; }

.btn {
  font-size: 16px;
  font-weight: bold;
  max-width: 350px;
  padding: 12px 0px;
  display: block;
  width: 275px;
  text-transform: uppercase; }
  @media (min-width: 992px) {
    .btn {
      display: block;
      text-align: center;
      border-radius: 5px;
      padding: 20px;
      font-size: 18px;
      max-width: 300px;
      text-decoration: none;
      position: relative;
      font-weight: 600;
      margin: 0 auto;
      position: relative;
      text-align: center;
      width: auto; } }
  .btn-orange {
    color: #fff;
    border: 2px solid #FF5800;
    background-color: #FF5800;
    position: relative;
    margin: 0 auto; }
    .btn-orange:after {
      position: relative;
      content: '';
      background: url(/images/common/arrow-white.png) no-repeat;
      width: 20px;
      height: 14px;
      display: inline-block;
      margin-left: 15px;
      top: 50%;
      transform: translatey(-50%);
      position: absolute;
      display: none; }
    .btn-orange:hover {
      background-color: #fff;
      color: #FF5800; }
      .btn-orange:hover:after {
        background: url(/images/common/arrow-orange.png) no-repeat; }
  .btn:focus {
    box-shadow: none; }

.home-fi .btn {
  width: 270px; }
  @media (min-width: 992px) {
    .home-fi .btn {
      width: 350px; } }

a.black-btn {
  display: none; }
  @media (min-width: 992px) {
    a.black-btn {
      width: 134px;
      height: 46px;
      line-height: 42px !important;
      border: 2px solid #333333;
      border-radius: 5px;
      font-size: 18px !important;
      color: #333 !important;
      text-decoration: none;
      font-weight: 400 !important;
      transition: .3s all ease-in;
      display: block;
      background: transparent;
      padding: 0;
      text-transform: capitalize;
      text-decoration: none !important; }
      a.black-btn:after {
        display: none; }
      a.black-btn:hover {
        border: 2px solid #FF5800;
        color: #FF5800 !important; } }

.btn_wrapper.btn2 {
  position: relative;
  z-index: 1; }

.masonry .brick a.service-box-link {
  border: 0 !important;
  color: #4a90e2 !important;
  line-height: 20px !important;
  height: auto !important; }

.btn--transparent {
  background: transparent; }

.o-menu-wrap {
  position: static; }

.o-header {
  background: #0D2642;
  position: fixed;
  z-index: 9999999;
  width: 100%;
  top: 0;
  padding: 10px 0; }
  @media screen and (min-width: 770px) {
    .o-header {
      border-bottom: 1px solid #e0e0e0;
      background: #fff; } }
  @media screen and (min-width: 992px) {
    .o-header {
      min-height: 50px;
      z-index: 999999;
      padding: 0; } }
  .o-header .navbar-brand {
    padding: 8px 0;
    margin-right: 0;
    font-size: 1.25rem; }
    @media screen and (min-width: 992px) {
      .o-header .navbar-brand.env-logo-padding-top-0 {
        padding: 6px 0 0; } }
  .o-header .navbar {
    padding: 0;
    position: static; }
    .o-header .navbar ul > li a {
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      line-height: 45px;
      display: block;
      display: block; }
      .o-header .navbar ul > li a.mobile-anchor-heading {
        font-size: 16px;
        margin-bottom: 8px; }
      @media screen and (min-width: 1px) {
        .o-header .navbar ul > li a {
          padding-left: 20px; } }
      @media screen and (min-width: 992px) {
        .o-header .navbar ul > li a {
          padding: 0 6px; } }
      @media screen and (min-width: 1200px) {
        .o-header .navbar ul > li a {
          padding: 0 11px; } }
      @media screen and (min-width: 1280px) {
        .o-header .navbar ul > li a {
          padding: 0 13px; } }
      @media screen and (min-width: 992px) {
        .o-header .navbar ul > li a {
          color: #333; } }
      .o-header .navbar ul > li a:hover {
        color: #fff; }
        @media screen and (min-width: 992px) {
          .o-header .navbar ul > li a:hover {
            color: #333; } }
      .o-header .navbar ul > li a.skill-brand-img span {
        margin-left: 30px;
        color: #cdcdcd; }
        @media screen and (min-width: 992px) {
          .o-header .navbar ul > li a.skill-brand-img span {
            margin-left: 0; } }
    .o-header .navbar ul > li.active a {
      color: #ff0085; }
    .o-header .navbar ul > li:first-child {
      padding-left: 0; }
    .o-header .navbar ul > li:last-child {
      padding-right: 0; }
    @media screen and (min-width: 992px) {
      .o-header .navbar .col-lg-2 .menu-holder-pan5-pan:nth-child(2) {
        margin-top: 25px; } }
  .o-header .dropdown {
    width: 100vw;
    position: absolute;
    height: 100vh;
    padding: 30px 50%;
    background: url(/images/home/banner-pattern.png) no-repeat 100% 100%;
    background-color: #333;
    color: #fff; }
  .o-header .menu-holder-pan3 {
    max-width: 1170px;
    margin: 0 auto;
    text-align: left; }
  .o-header .request-quote {
    color: #333;
    border: 2px solid #FF5800;
    display: block;
    text-align: center;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    font-size: 15px;
    max-width: 320px;
    text-decoration: none;
    position: relative;
    margin-left: 0;
    text-align: left;
    display: none;
    text-transform: uppercase !important; }
    @media screen and (min-width: 992px) {
      .o-header .request-quote {
        padding: 8px 10px;
        font-size: 14px !important; } }
    @media screen and (min-width: 1200px) {
      .o-header .request-quote {
        padding: 8px 20px;
        font-size: 15px !important; } }
    @media (min-width: 992px) {
      .o-header .request-quote {
        display: block;
        width: 125px;
        text-align: center; } }
  .o-header .hireus {
    color: #333;
    border: 2px solid #FF5800;
    background-color: transparent;
    display: block;
    text-align: center;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    padding: 8px 3px;
    font-size: 13px;
    max-width: 320px;
    text-decoration: none;
    position: relative;
    margin-left: 15px;
    text-align: center;
    top: 2px;
    text-transform: uppercase;
    display: block;
    width: 135px;
    text-align: center;
    font-weight: 700;
    top: 0;
    margin: 0;
    background-size: 15px; }
    @media screen and (min-width: 425px) {
      .o-header .hireus {
        padding: 8px 7px;
        font-size: 13px; } }
    @media screen and (min-width: 768px) {
      .o-header .hireus {
        margin: 0 0 0 15px; } }
    @media screen and (min-width: 992px) {
      .o-header .hireus {
        padding: 8px 5px;
        font-size: 14px;
        background-size: 15px;
        text-transform: none; } }
    @media screen and (min-width: 1200px) {
      .o-header .hireus {
        padding: 10px 6px 10px 6px;
        font-size: 15px; } }
    .o-header .hireus .btn-arrow {
      margin-left: 5px;
      width: 18px;
      display: none; }
      @media screen and (min-width: 1200px) {
        .o-header .hireus .btn-arrow {
          display: inline; } }
      .o-header .hireus .btn-arrow path {
        fill: #333; }
    .o-header .hireus:hover {
      background-color: #FF5800;
      color: #fff; }
      .o-header .hireus:hover svg {
        transform: translate(5px, 0); }
      .o-header .hireus:hover path {
        fill: #fff; }
  .o-header.scrolled .hireus {
    background-color: #FF5800;
    color: #fff; }
    .o-header.scrolled .hireus .btn-arrow path {
      fill: #fff; }
    .o-header.scrolled .hireus:hover {
      background-color: transparent;
      color: #333; }
      .o-header.scrolled .hireus:hover .btn-arrow path {
        fill: #333; }

#navbar .collapse:not(.show) {
  right: -100%;
  display: block; }

.o-navigation-bar-new {
  padding: 0; }
  .o-navigation-bar-new #primaryNav {
    width: 100vw;
    position: fixed;
    top: 70px;
    right: 0;
    height: calc(100vh - 53px);
    overflow-y: scroll;
    background: #020a13;
    -webkit-transition: 0.4s all;
    -moz-transition: 0.4s all;
    -ms-transition: 0.4s all;
    transition: 0.4s all; }
    @media screen and (min-width: 992px) {
      .o-navigation-bar-new #primaryNav {
        overflow-y: visible;
        position: static;
        padding: 0;
        text-align: right;
        width: auto;
        height: auto;
        background: transparent;
        justify-content: flex-end; } }
    .o-navigation-bar-new #primaryNav .request-quote {
      line-height: 19px;
      display: inline-block;
      background-color: #a9cf00;
      color: #fff;
      margin-top: 12px;
      border-radius: 0;
      border: 0;
      width: 125px;
      padding: 10px 20px;
      font-weight: bold;
      text-align: center;
      border-radius: 5px;
      border: 1px solid transparent;
      font-weight: 500; }
      .o-navigation-bar-new #primaryNav .request-quote:hover {
        background-color: #fff;
        color: #fa5701;
        border: 0;
        border: 1px solid #ff5800; }
        .o-navigation-bar-new #primaryNav .request-quote:hover:after {
          background: url(/images/common/arrow-orange.png) 50% 50% no-repeat;
          content: "";
          right: 20px;
          display: none; }
      .o-navigation-bar-new #primaryNav .request-quote:after {
        background: url(/images/common/arrow-white.png) 50% 50% no-repeat;
        right: 15px;
        display: none; }
    .o-navigation-bar-new #primaryNav > ul {
      overflow-x: hidden; }
      @media screen and (min-width: 992px) {
        .o-navigation-bar-new #primaryNav > ul {
          overflow-x: visible; } }
      @media screen and (min-width: 767px) {
        .o-navigation-bar-new #primaryNav > ul > li::after {
          position: relative;
          content: "";
          background-color: transparent;
          display: none;
          bottom: 2px;
          width: 80%;
          height: 2px;
          left: 50%;
          -webkit-transform: translateX(-50%);
          -moz-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          transform: translateX(-50%);
          margin-bottom: -10px; } }
  @media screen and (min-width: 767px) and (min-width: 992px) {
    .o-navigation-bar-new #primaryNav > ul > li::after {
      background: #14df7d; } }
      .o-navigation-bar-new #primaryNav > ul > li.has-submenu {
        padding-right: 0; }
        .o-navigation-bar-new #primaryNav > ul > li.has-submenu > a {
          position: relative;
          line-height: 45px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: none !important; }
          @media screen and (min-width: 992px) {
            .o-navigation-bar-new #primaryNav > ul > li.has-submenu > a {
              padding-right: 9px;
              padding-bottom: 9px; } }
          @media screen and (min-width: 1200px) {
            .o-navigation-bar-new #primaryNav > ul > li.has-submenu > a {
              padding-right: 10px;
              padding-left: 10px;
              padding-bottom: 9px; } }
          @media (max-width: 768px) {
            .o-navigation-bar-new #primaryNav > ul > li.has-submenu > a {
              padding-right: 10px;
              padding-bottom: 10px; } }
          @media screen and (min-width: 992px) {
            .o-navigation-bar-new #primaryNav > ul > li.has-submenu > a {
              background: url(/images/common/nav-down-arrow.svg) 100% calc(50% - 5px) no-repeat; } }
          .o-navigation-bar-new #primaryNav > ul > li.has-submenu > a span {
            font-size: 28px;
            font-weight: 500;
            flex: 0 0 30px;
            height: 30px;
            width: 30px;
            color: #fff;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border: #fff 1px solid;
            line-height: 28px;
            text-align: center;
            margin-right: 15px; }
            @media screen and (min-width: 992px) {
              .o-navigation-bar-new #primaryNav > ul > li.has-submenu > a span {
                margin-right: 0;
                color: #333; } }
          .o-navigation-bar-new #primaryNav > ul > li.has-submenu > a.dropdown-toggle::after {
            display: none; }
        .o-navigation-bar-new #primaryNav > ul > li.has-submenu > span {
          position: relative;
          padding-right: 13px; }
          @media screen and (min-width: 992px) {
            .o-navigation-bar-new #primaryNav > ul > li.has-submenu > span {
              background: url(/images/common/nav-down-arrow.svg) 100% 50% no-repeat; } }
      .o-navigation-bar-new #primaryNav > ul > li.active span {
        line-height: 24px !important; }
      .o-navigation-bar-new #primaryNav > ul > li.active a {
        color: #fff; }
        .o-navigation-bar-new #primaryNav > ul > li.active a:hover {
          color: #fff; }
      .o-navigation-bar-new #primaryNav > ul > li:hover::after {
        display: block; }
      @media screen and (min-width: 992px) {
        .o-navigation-bar-new #primaryNav > ul > li:hover > ul {
          opacity: 1;
          visibility: visible;
          height: auto;
          display: block;
          border: 1px solid #d8d8d8; }
          .o-navigation-bar-new #primaryNav > ul > li:hover > ul.nav-padding-new-30 {
            padding: 20px 15px; } }
      .o-navigation-bar-new #primaryNav > ul > li > ul {
        width: 100vw;
        position: static;
        padding: 0;
        height: 0;
        display: none;
        overflow: hidden;
        background: #020a13;
        border-radius: 0;
        -webkit-transition: 0.4s all;
        -moz-transition: 0.4s all;
        -ms-transition: 0.4s all;
        transition: 0.4s all; }
        @media screen and (min-width: 992px) {
          .o-navigation-bar-new #primaryNav > ul > li > ul {
            position: absolute;
            left: 0;
            top: auto;
            opacity: 1;
            display: block; } }
        .o-navigation-bar-new #primaryNav > ul > li > ul.showNav {
          display: block;
          height: 100%; }
        .o-navigation-bar-new #primaryNav > ul > li > ul li.has-submenu {
          padding-right: 0; }
          .o-navigation-bar-new #primaryNav > ul > li > ul li.has-submenu > a,
          .o-navigation-bar-new #primaryNav > ul > li > ul li.has-submenu > span {
            position: relative;
            padding: 10px 0 10px 10px; }
            @media screen and (min-width: 992px) {
              .o-navigation-bar-new #primaryNav > ul > li > ul li.has-submenu > a,
              .o-navigation-bar-new #primaryNav > ul > li > ul li.has-submenu > span {
                background: url("images/common/nav-right-arrow.svg") 90% 50% no-repeat; } }
          .o-navigation-bar-new #primaryNav > ul > li > ul li.has-submenu:hover > a,
          .o-navigation-bar-new #primaryNav > ul > li > ul li.has-submenu:hover > span {
            background: url("images/common/nav-right-arrow.svg") 90% 50% no-repeat #dadada;
            color: #333;
            border-left: 4px solid #14df7d; }
        .o-navigation-bar-new #primaryNav > ul > li > ul li a {
          color: #fff;
          line-height: 1.2; }
        .o-navigation-bar-new #primaryNav > ul > li > ul li ul {
          left: 100%;
          width: 100%;
          position: absolute;
          background: #fff;
          -webkit-box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.37);
          -moz-box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.37);
          box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.37);
          padding: 0; }
          .o-navigation-bar-new #primaryNav > ul > li > ul li ul li a {
            padding: 10px 0 10px 10px;
            background-color: #fff;
            background-image: none; }
            .o-navigation-bar-new #primaryNav > ul > li > ul li ul li a:hover {
              background-color: #dadada;
              background-image: none;
              color: #333;
              border-left: 4px solid #14df7d; }
  .o-navigation-bar-new .navbar-nav {
    padding-top: 10px; }
  .o-navigation-bar-new .dropdown-menu {
    margin-top: 0;
    border: none;
    display: block;
    opacity: 0;
    height: 0; }
  .o-navigation-bar-new li ul li {
    font-size: 14px; }
    .o-navigation-bar-new li ul li a {
      border-left: 0;
      color: #fff;
      font-size: 14px; }
      .o-navigation-bar-new li ul li a:hover {
        background-color: transparent;
        color: #14df7e; }
        .o-navigation-bar-new li ul li a:hover span {
          background-color: transparent;
          color: #14df7e; }
    .o-navigation-bar-new li ul li span {
      border-left: 0;
      color: #cdcdcd;
      font-size: 14px;
      font-weight: 400; }

.o-navigation-bar #primaryNav li.has-submenu > a {
  background-position: 100% 41%;
  padding-bottom: 9px; }

.o-navigation-bar #primaryNav li.has-submenu > span {
  background-position: 100% 41%;
  padding-bottom: 9px; }

.o-navigation-bar #primaryNav .menu-holder-pan5-pan ul:before {
  display: none; }

.o-navigation-bar #primaryNav .menu-holder-pan5-pan ul li {
  width: 100%;
  padding: 0 0 0 5px;
  margin: 0; }

.o-navigation-bar #primaryNav .menu-holder-pan2-pan ul:before {
  display: none; }

.o-navigation-bar #primaryNav .menu-holder-pan2-pan ul li {
  width: 100%;
  padding: 0 0 0 5px;
  margin: 0; }

.o-navigation-bar img {
  margin-top: 15px;
  padding-top: 0;
  padding-left: 15px; }
  @media screen and (min-width: 992px) {
    .o-navigation-bar img {
      padding-left: 0px; } }

.o-navigation-bar.margin-top-0 img {
  margin-top: 6px; }

.menu-holder-pan3 .menu-heading {
  font-size: 16px;
  color: #fff;
  line-height: 22px;
  margin-top: 10px;
  font-weight: 500;
  margin-bottom: 20px;
  position: relative;
  padding-left: 5px;
  display: block;
  white-space: nowrap; }
  .menu-holder-pan3 .menu-heading:before {
    content: "";
    width: 30px;
    height: 2px;
    background-color: #14df7e;
    position: absolute;
    left: 5px;
    bottom: -6px; }

.menu-holder-pan3 .menu-holder-pan5-pan {
  border-left: none;
  padding-left: 15px;
  height: inherit; }
  @media screen and (min-width: 992px) {
    .menu-holder-pan3 .menu-holder-pan5-pan {
      border-left: 1px solid #495376; } }

.menu-holder-pan3 .menu-holder-pan3-pan {
  border-left: none;
  padding-left: 15px;
  height: inherit; }
  @media screen and (min-width: 992px) {
    .menu-holder-pan3 .menu-holder-pan3-pan {
      border-left: 1px solid #495376; } }
  .menu-holder-pan3 .menu-holder-pan3-pan li {
    width: auto;
    padding-left: 5px; }
    .menu-holder-pan3 .menu-holder-pan3-pan li a:hover {
      background-color: transparent;
      color: #14df7e; }
      .menu-holder-pan3 .menu-holder-pan3-pan li a:hover span {
        background-color: transparent;
        color: #14df7e; }

.menu-holder-pan3 .menu-holder-pan2-pan {
  border-left: none;
  padding-left: 15px;
  height: inherit; }
  @media screen and (min-width: 992px) {
    .menu-holder-pan3 .menu-holder-pan2-pan {
      border-left: 1px solid #495376; } }
  .menu-holder-pan3 .menu-holder-pan2-pan li {
    width: auto;
    padding-left: 10px; }

.navbar-toggler {
  position: relative;
  padding: 0;
  width: 26px;
  height: 22px;
  border: 0;
  margin-right: 15px;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px; }
  .navbar-toggler:focus {
    border: none;
    box-shadow: none; }
  @media all and (max-width: 768px) {
    .navbar-toggler span {
      display: none; } }

#nav-icon4 {
  width: 26px;
  height: 18px;
  position: relative;
  margin: 0 auto;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  right: -3px;
  top: -4px; }

#nav-icon4 span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #fff;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out; }
  @media screen and (min-width: 770px) {
    #nav-icon4 span {
      background: #333; } }

#nav-icon4 span:nth-child(1) {
  top: 0px;
  transform-origin: left center; }

#nav-icon4 span:nth-child(2) {
  top: 8px;
  transform-origin: left center;
  width: 14px;
  right: 0;
  left: inherit; }

#nav-icon4 span:nth-child(3) {
  top: 16px;
  transform-origin: left center;
  width: 20px;
  right: 0;
  left: inherit; }

#nav-icon4.open span:nth-child(1) {
  transform: rotate(45deg);
  top: 1px;
  left: 4px; }

#nav-icon4.open span:nth-child(2) {
  width: 0%;
  opacity: 0; }

#nav-icon4.open span:nth-child(3) {
  transform: rotate(-45deg);
  top: 19px;
  left: 4px;
  width: 26px; }

.mb-justyfy-center {
  align-items: center; }
  @media (min-width: 992px) {
    .mb-justyfy-center {
      align-items: inherit; } }

.high {
  padding: 50px 0; }
  @media (min-width: 992px) {
    .high {
      padding: 80px 0 50px !important; } }
  .high h2 {
    font-weight: 600; }
  .high p {
    font-family: "Montserrat", sans-serif !important; }

.web-app-service .service-wrap .services-pan a {
  width: 134px;
  height: 46px;
  line-height: 42px;
  border: 2px solid #333333;
  border-radius: 5px;
  font-size: 18px;
  line-height: 44px;
  color: #333;
  text-decoration: none;
  font-weight: 400;
  transition: .3s all ease-in;
  background: none;
  padding: 0;
  text-transform: capitalize; }
  .web-app-service .service-wrap .services-pan a:after {
    display: none; }
  .web-app-service .service-wrap .services-pan a:hover {
    border: 2px solid #FF5800;
    color: #FF5800; }

.home-build {
  padding: 50px 0 20px; }
  @media (min-width: 992px) {
    .home-build {
      padding: 80px 0 50px !important; } }

.web-app-service-mod h2 {
  margin-bottom: 30px; }

@media screen and (min-width: 768px) {
  .navbar-expand-md .navbar-collapse {
    display: none !important;
    flex-basis: auto; }
    .navbar-expand-md .navbar-collapse.show {
      display: block !important; }
  .navbar-expand-md .navbar-toggler {
    display: block; } }

@media screen and (min-width: 992px) {
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand-md .navbar-toggler {
    display: none; } }

@media screen and (min-width: 768px) {
  .navbar-expand-md .navbar-nav {
    flex-direction: column; } }

@media screen and (min-width: 992px) {
  .navbar-expand-md .navbar-nav {
    flex-direction: row; } }

a.hiring {
  display: none; }
  @media screen and (min-width: 992px) {
    a.hiring {
      padding: 3px 3px;
      margin-left: 5px;
      flex: 0 0 95px;
      font-size: 12px;
      margin-top: 6px;
      background-color: #4B8FE2;
      color: #fff;
      border-radius: 5px;
      font-weight: 500;
      position: relative;
      display: block; } }
  @media screen and (min-width: 1280px) {
    a.hiring {
      flex: auto;
      padding: 3px 7px;
      font-size: 13px;
      display: block; } }

span.hiring-arrow {
  position: absolute;
  left: -6px;
  bottom: 0px;
  width: 0;
  height: 0;
  border-width: 10px 10px 0 0;
  border-color: #4b8fe2 transparent transparent transparent;
  border-style: solid;
  transform: rotate(180deg); }

.o-header .navbar ul > li a {
  font-size: 14px; }
  @media screen and (min-width: 992px) {
    .o-header .navbar ul > li a {
      font-size: 13px; } }
  @media screen and (min-width: 1200px) {
    .o-header .navbar ul > li a {
      font-size: 14px; } }

.contact-book-btn:disabled {
  pointer-events: none;
  filter: grayscale(70%);
  color: #ccc !important; }

.bottomSendButton:disabled {
  pointer-events: none;
  filter: grayscale(70%);
  color: #ccc !important; }

.contactFormTrigger:disabled {
  pointer-events: none;
  filter: grayscale(70%);
  color: #ccc !important; }

.top-addvertisement-wrapper {
  background: #3CC065; }

.top-addvertisement {
  padding: 10px 15px;
  position: relative; }
  .top-addvertisement::before {
    content: '';
    position: absolute;
    background: url(/images/easter-offering/mcolor-egg.png) no-repeat 0 0;
    width: 50px;
    height: 32px;
    top: 0;
    background-size: 75%;
    display: none; }
    @media screen and (min-width: 992px) {
      .top-addvertisement::before {
        display: block;
        right: 10px; } }
    @media screen and (min-width: 1280px) {
      .top-addvertisement::before {
        right: 30px; } }
  .top-addvertisement::after {
    content: '';
    position: absolute;
    background: url(/images/easter-offering/mcolor-egg2.png) no-repeat 0 0;
    width: 50px;
    height: 32px;
    top: 0;
    background-size: 75%;
    display: none; }
    @media screen and (min-width: 992px) {
      .top-addvertisement::after {
        display: block;
        left: 10px; } }
    @media screen and (min-width: 1280px) {
      .top-addvertisement::after {
        left: 30px; } }
  .top-addvertisement p {
    margin-bottom: 0;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: center; }
    .top-addvertisement p a {
      display: inline-block;
      position: relative;
      font-size: 14px;
      font-weight: 700;
      color: #fff;
      padding: 0 18px 0 5px;
      margin-left: 5px;
      transition: .2s all ease-in-out;
      text-decoration: underline; }
      .top-addvertisement p a:hover {
        text-decoration: underline; }
      .top-addvertisement p a:after {
        content: ">>";
        position: absolute;
        top: 0px;
        right: 0; }

.o-clients {
  position: relative;
  padding: 50px 0 25px;
  text-align: center;
  position: relative;
  border-bottom: 1px solid #cae0e7;
  background-color: #fff; }
  .o-clients h2 {
    font-family: "Montserrat",sans-serif;
    font-weight: 600;
    color: #333;
    font-size: 17px;
    line-height: 20px;
    margin: 0 0 20px 0;
    text-transform: none;
    vertical-align: middle; }
    @media (min-width: 992px) {
      .o-clients h2 {
        line-height: 25px;
        font-weight: bold; } }
  @media screen and (min-width: 992px) {
    .o-clients .zoovu-custom-width {
      flex: 0 0 138px; } }
  @media screen and (min-width: 768px) {
    .o-clients .client-logo-modify .client-logo-new {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden; } }
  @media screen and (min-width: 768px) {
    .o-clients .client-logo-modify .client-logo-new .client-logo-img {
      margin: 15px 15px; } }

.sk-fading-circle {
  margin: 100px auto;
  width: 80px;
  height: 80px;
  position: relative; }

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; }

.sk-fading-circle .sk-circle:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #27c070;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both; }

.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg); }

.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg); }

.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg); }

.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg); }

.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg); }

.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg); }

.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg); }

.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg); }

.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg); }

.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg); }

.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg); }

.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s; }

.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s; }

.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s; }

.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s; }

.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s; }

.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s; }

.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s; }

.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s; }

.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s; }

.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s; }

.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s; }

@-webkit-keyframes sk-circleFadeDelay {
  0%, 39%, 100% {
    opacity: 0; }
  40% {
    opacity: 1; } }

@keyframes sk-circleFadeDelay {
  0%, 39%, 100% {
    opacity: 0; }
  40% {
    opacity: 1; } }

.why-hire-java .service-wrap {
  column-count: inherit; }
  @media screen and (min-width: 992px) {
    .why-hire-java .service-wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: center; } }
  @media screen and (min-width: 992px) {
    .why-hire-java .service-wrap .services-pan {
      flex: 0 0 31.5%; } }

.client-logo-slide .owl-item {
  visibility: hidden; }
  .client-logo-slide .owl-item.active {
    visibility: visible; }

.client-logo-slide .owl-nav {
  display: none; }

.client-logo-slide .owl-dots .owl-dot {
  background: #9b9b9b;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%; }
  .client-logo-slide .owl-dots .owl-dot.active {
    background: #49c684; }

.client-logo-slide .owl-stage {
  display: flex;
  align-items: center; }
  @media screen and (min-width: 992px) {
    .client-logo-slide .owl-stage .owl-item:last-child {
      height: auto; } }
  @media screen and (min-width: 1200px) {
    .client-logo-slide .owl-stage .owl-item:last-child {
      height: 222px; } }

.client-logo-slide .client-logo-img {
  display: flex;
  justify-content: center; }
  .client-logo-slide .client-logo-img img {
    height: 100%;
    object-fit: contain; }
    @media screen and (min-width: 992px) {
      .client-logo-slide .client-logo-img img {
        height: inherit;
        object-fit: inherit; } }

.nw-client-slider .owl-item {
  height: 86px;
  align-items: center;
  justify-content: center;
  display: flex; }
  @media screen and (min-width: 992px) {
    .nw-client-slider .owl-item {
      height: auto; } }

/* Chrome version 29 and above */
@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) and (min-width: 992px) {
  .client-logo-slide .owl-stage .owl-item:last-child {
    height: 198px; } }

.nw-client {
  padding: 20px 0 20px; }
  @media screen and (min-width: 992px) {
    .nw-client {
      padding: 50px 0 25px; } }
  .nw-client .client-logo-slide {
    margin-bottom: 0; }
    @media screen and (min-width: 992px) {
      .nw-client .client-logo-slide {
        margin-bottom: 0 0 1rem; } }
  .nw-client h2 {
    margin: 0; }
    @media screen and (min-width: 992px) {
      .nw-client h2 {
        margin: 0 0 20px 0; } }
  .nw-client .owl-dots {
    display: none; }

.splide__arrows {
  display: none; }

.dev-client {
  background: #F5F7F8 !important; }
  @media screen and (min-width: 992px) {
    .dev-client {
      padding: 60px 0; } }
  .dev-client h2 {
    font-size: 21px;
    line-height: 30px;
    font-weight: 500; }
    @media screen and (min-width: 992px) {
      .dev-client h2 {
        margin-bottom: 25px !important; } }
  .dev-client .client-logo-img {
    height: 96px;
    border: 1px solid #ECE9E9;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    padding: 10px; }
    @media screen and (min-width: 768px) {
      .dev-client .client-logo-img {
        margin: 3px 3px !important;
        padding: 0; } }
    @media screen and (min-width: 992px) {
      .dev-client .client-logo-img {
        flex: 1; } }
    .dev-client .client-logo-img img {
      height: 100%;
      object-fit: contain; }

.company-profile-client .dev-client {
  background: #fff !important;
  border-bottom: none !important; }

.certification {
  border-bottom: 1px solid #E2E2E2;
  background: #F2F5F7;
  padding: 30px 0; }
  @media screen and (min-width: 992px) {
    .certification {
      padding: 0; } }
  @media screen and (min-width: 992px) {
    .certification .certification-left {
      display: flex;
      align-items: center; } }
  .certification .certification-left h4 {
    margin-bottom: 0;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .certification .certification-left h4 {
        text-align: left; } }
  .certification .certification-left svg {
    margin: 0 25px;
    display: none; }
    @media screen and (min-width: 992px) {
      .certification .certification-left svg {
        display: inline-block; } }
  @media screen and (min-width: 992px) {
    .certification .certification-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1; } }
  .certification .certification-items {
    align-items: center;
    justify-content: center;
    display: flex; }
    @media screen and (min-width: 992px) {
      .certification .certification-items {
        flex: 1; } }

.no-webp .o-bottom-form .form-text ul li {
  background-image: url(/images/common/common-sprite.png); }

.webp .o-bottom-form .form-text ul li {
  background-image: url(/images/webp/common/common-sprite.webp); }

.o-bottom-form {
  background: linear-gradient(135deg, #00b35c 0%, #0b8a8a 47%, #004475 100%);
  position: relative; }
  @media (min-width: 992px) {
    .o-bottom-form {
      overflow: hidden; } }
  .o-bottom-form .container {
    position: relative;
    z-index: 9; }
  .o-bottom-form .form-text {
    background-color: #1D2942;
    width: 100%;
    float: none;
    color: #fff;
    text-align: left;
    padding: 40px 30% 15px 35px;
    position: relative; }
    @media (min-width: 992px) {
      .o-bottom-form .form-text {
        width: 50%;
        float: left;
        padding: 70px 60px;
        margin-top: 0; } }
    .o-bottom-form .form-text:before {
      content: '';
      background: #1D2942;
      width: 25px;
      height: 25px;
      transform: rotate(-45deg);
      position: absolute;
      bottom: -12px;
      left: 35px; }
      @media (min-width: 992px) {
        .o-bottom-form .form-text:before {
          display: none; } }
    .o-bottom-form .form-text:after {
      content: '';
      position: absolute;
      top: 45px;
      right: 5px;
      background: url(/images/common/b-form-envelop.webp) no-repeat center center/90%;
      width: 159px;
      height: 159px; }
      @media (min-width: 992px) {
        .o-bottom-form .form-text:after {
          display: none; } }
    .o-bottom-form .form-text h2 {
      font-size: 24px;
      line-height: 36px;
      font-weight: 700;
      color: #fff; }
      @media (min-width: 992px) {
        .o-bottom-form .form-text h2 {
          font-size: 36px;
          line-height: 53px; } }
    .o-bottom-form .form-text .form-bot-text {
      display: none; }
      @media (min-width: 992px) {
        .o-bottom-form .form-text .form-bot-text {
          display: block; } }
    .o-bottom-form .form-text ul {
      display: none; }
      @media (min-width: 992px) {
        .o-bottom-form .form-text ul {
          display: block; } }
      .o-bottom-form .form-text ul li {
        display: inline-block;
        text-align: center;
        padding-top: 70px;
        width: 72px;
        background-repeat: no-repeat;
        background-position: 50% 0; }
        .o-bottom-form .form-text ul li.am-1 {
          background-position: 0 -570px; }
        .o-bottom-form .form-text ul li.am-2 {
          background-position: -73px -570px; }
        .o-bottom-form .form-text ul li.am-3 {
          background-position: -149px -570px; }
        .o-bottom-form .form-text ul li.am-4 {
          background-position: -228px -570px; }
        .o-bottom-form .form-text ul li.am-5 {
          background-image: url(/images/common/ray.png);
          background-size: 100%; }
    .o-bottom-form .form-text p {
      color: #fff;
      font-family: "Montserrat", sans-serif;
      font-size: 13px; }
    .o-bottom-form .form-text p.sub-heading {
      font-size: 16px;
      line-height: 23px;
      font-family: "Montserrat", sans-serif; }
      @media (min-width: 992px) {
        .o-bottom-form .form-text p.sub-heading {
          font-size: 19px;
          line-height: 32px;
          font-family: "Montserrat", sans-serif; } }
    .o-bottom-form .form-text p.subheading-1 {
      font-size: 24px;
      margin: 0 0 10px 0;
      font-family: "Montserrat", sans-serif; }
    .o-bottom-form .form-text .form-bot-text {
      border-top: 1px solid #364462;
      margin-top: 60px;
      padding-top: 60px; }
  .o-bottom-form .form-panel {
    width: 100%;
    float: none;
    padding: 20px 20px 40px;
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-bottom-form .form-panel {
        width: 50%;
        float: left;
        padding: 60px; } }

.common-input {
  padding: 20px 20px 20px 40px;
  color: #333;
  width: 100%;
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 16px;
  border: 0;
  border-bottom: 1px solid #333;
  background-color: transparent; }

.u-name {
  background: url(/images/common/form-icon-1.png) 0 50% no-repeat; }

.u-email {
  background: url(/images/common/form-icon-2.png) 0 50% no-repeat; }

.u-phone {
  background: url(/images/common/form-icon-3.png) 0 50% no-repeat; }

.u-company {
  background: url(/images/common/form-icon-4.png) 0 50% no-repeat; }

.u-message {
  background: url(/images/common/form-icon-5.png) 0 20px no-repeat; }

.attach {
  background: url(/images/common/form-icon-6.png) 0 20px no-repeat;
  padding: 20px 20px 20px 40px; }

.webp .hm-page .mb-w-tr-pt:before {
  display: none; }
  @media (min-width: 992px) {
    .webp .hm-page .mb-w-tr-pt:before {
      display: block; } }

#bottom-form ::-webkit-input-placeholder {
  color: #3b3b3b;
  opacity: 1;
  font-weight: 400; }

.form-left {
  width: 100%;
  float: left;
  padding: 30px;
  margin-top: 0;
  background-color: #1d2942;
  color: #fff; }
  @media (min-width: 992px) {
    .form-left {
      width: 50%;
      padding: 70px 60px 46px; } }

.form-left ul {
  padding-bottom: 0;
  margin-bottom: 0; }
  @media screen and (min-width: 768px) {
    .form-left ul {
      padding-bottom: 40px;
      margin-bottom: 40px;
      border-bottom: 1px solid #515151; } }

.form-left ul li {
  display: inline-block;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 10px; }
  @media (min-width: 992px) {
    .form-left ul li {
      margin-bottom: 0; } }
  .form-left ul li .bottom-form-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden; }
    @media screen and (min-width: 768px) {
      .form-left ul li .bottom-form-img {
        width: 45px;
        height: 45px; } }
    @media screen and (min-width: 992px) {
      .form-left ul li .bottom-form-img {
        width: 80px;
        height: 80px; } }

.form-left ul li span {
  display: block;
  font-size: 16px;
  font-weight: 500;
  padding-top: 10px; }
  @media screen and (min-width: 768px) {
    .form-left ul li span {
      font-size: 12px; } }
  @media screen and (min-width: 992px) {
    .form-left ul li span {
      font-size: 15px; } }

.form-left ul li img {
  filter: grayscale(100%);
  width: 100%; }

.form-left p {
  font-size: 16px; }
  @media screen and (min-width: 992px) {
    .form-left p {
      font-size: 14px; } }

.form-bottom-sec {
  display: inline-block;
  width: 58%;
  font-size: 12px;
  line-height: 18px;
  vertical-align: top;
  margin-bottom: 32px; }
  @media screen and (min-width: 992px) {
    .form-bottom-sec {
      font-size: 14px;
      line-height: 20px; } }
  @media screen and (min-width: 1200px) {
    .form-bottom-sec {
      font-size: 16px;
      line-height: 24px; } }

.form-bottom-sec:nth-child(1) {
  width: 40%; }

.form-bottom-sec:nth-child(3) {
  width: 40%; }

.form-bottom-head {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  display: block;
  vertical-align: top;
  margin-bottom: 5px;
  color: #fff; }
  @media screen and (min-width: 992px) {
    .form-bottom-head {
      font-size: 20px;
      line-height: 32px; } }
  @media screen and (min-width: 1200px) {
    .form-bottom-head {
      font-size: 26px;
      line-height: 32px; } }

.form-icon {
  margin-right: 15px;
  margin-bottom: 10px;
  width: 54px;
  display: inline-block;
  vertical-align: top; }
  @media screen and (min-width: 768px) {
    .form-icon {
      width: 40px;
      margin-right: 10px; } }
  @media screen and (min-width: 992px) {
    .form-icon {
      width: 35px;
      margin-bottom: 0;
      text-align: center;
      margin-right: 20px; } }
  @media screen and (min-width: 1200px) {
    .form-icon {
      width: 54px; } }

.form-txt-pan {
  display: inline-block;
  width: 100%;
  color: #B9C6D0;
  font-weight: 500; }
  @media screen and (min-width: 420px) {
    .form-txt-pan {
      width: 65%; } }
  @media (min-width: 992px) {
    .form-txt-pan {
      width: 70%; } }

.form-bottom-sec:nth-child(1) .form-txt-pan,
.form-bottom-sec:nth-child(3) .form-txt-pan {
  width: 60%;
  display: block; }
  @media screen and (min-width: 420px) {
    .form-bottom-sec:nth-child(1) .form-txt-pan,
    .form-bottom-sec:nth-child(3) .form-txt-pan {
      width: 40%;
      display: inherit; } }
  @media screen and (min-width: 1200px) {
    .form-bottom-sec:nth-child(1) .form-txt-pan,
    .form-bottom-sec:nth-child(3) .form-txt-pan {
      width: 55%; } }

.o-bottom-form h2 {
  padding: 40px 30px 20px; }
  @media (min-width: 992px) {
    .o-bottom-form h2 {
      padding: 0; } }

.form-sub-heading {
  font-size: 18px;
  line-height: 24px;
  padding: 0 30px;
  font-weight: 600; }
  @media (min-width: 992px) {
    .form-sub-heading {
      font-size: 30px;
      line-height: 40px; } }
  .form-sub-heading br {
    display: none; }
    @media (min-width: 992px) {
      .form-sub-heading br {
        display: block; } }

.confidential-text-wrap {
  margin-top: -10px; }
  .confidential-text-wrap ul {
    display: flex;
    justify-content: space-evenly; }
    .confidential-text-wrap ul li {
      color: #ffffff;
      font-size: 12px;
      font-weight: 400;
      display: flex;
      align-items: center;
      width: 50%;
      position: relative;
      padding: 0px 7px;
      justify-content: flex-end; }
      .confidential-text-wrap ul li:after {
        content: "";
        position: absolute;
        width: 1px;
        height: 100%;
        background-color: #6eb9c8;
        right: 0px;
        top: 0px; }
      .confidential-text-wrap ul li:last-child {
        justify-content: flex-start; }
        .confidential-text-wrap ul li:last-child:after {
          display: none; }
      .confidential-text-wrap ul li img {
        width: 12px;
        height: 12px;
        margin-right: 6px; }
  .confidential-text-wrap__inverse {
    margin-top: 15px;
    text-align: center; }
    .confidential-text-wrap__inverse ul {
      display: inline-flex;
      margin-top: 5px; }
      .confidential-text-wrap__inverse ul li {
        color: #000000;
        font-size: 15px;
        width: auto;
        padding: 2px 10px; }
        @media screen and (min-width: 992px) {
          .confidential-text-wrap__inverse ul li {
            padding: 2px 10px;
            font-size: 14px; } }
        .confidential-text-wrap__inverse ul li:first-child {
          padding-left: 0; }
          @media screen and (min-width: 992px) {
            .confidential-text-wrap__inverse ul li:first-child {
              padding: 0px 10px; } }
        .confidential-text-wrap__inverse ul li:last-child {
          padding-right: 0; }
          @media screen and (min-width: 992px) {
            .confidential-text-wrap__inverse ul li:last-child {
              padding: 0px 10px; } }
        .confidential-text-wrap__inverse ul li:after {
          background-color: #D8D8D8; }

.o-contact-form__attachement-text {
  font-size: 16px; }
  @media screen and (min-width: 992px) {
    .o-contact-form__attachement-text {
      font-size: 13px; } }

.padding-top-100 {
  padding-top: 80px; }

.font-family-pro {
  font-family: "Montserrat", sans-serif; }

.padding-top-40 {
  padding-top: 40px; }

.padding-80 {
  padding-top: 40px; }

.padding-bottom-30 {
  padding-bottom: 30px; }

.white {
  color: #fff !important; }

h1 {
  font-size: 24px;
  line-height: 40px;
  font-weight: 700; }
  @media (min-width: 992px) {
    h1 {
      font-size: 62px;
      line-height: 75px; } }

h2 {
  font-size: 24px;
  line-height: 32px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700; }
  @media (min-width: 992px) {
    h2 {
      font-size: 48px;
      line-height: 61px; } }

.cmn-heading-para {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 20px;
  color: #333;
  font-weight: 500;
  text-align: center;
  font-family: "Montserrat", sans-serif; }
  @media (min-width: 992px) {
    .cmn-heading-para {
      font-size: 20px;
      line-height: 32px;
      font-family: "Montserrat", sans-serif; } }

.font-weight-600 {
  font-weight: 600; }

.margin-top-10 {
  margin-top: 10px; }

.margin-top-20 {
  margin-top: 20px; }

.margin-top-30 {
  margin-top: 30px; }

.margin-bottom-20 {
  margin-bottom: 20px; }

.cmn-bottom-border {
  border-bottom: 1px solid #dedede; }

.cmn-top-border {
  border-top: 1px solid #dedede; }

.mobile-off {
  display: none; }
  @media (min-width: 992px) {
    .mobile-off {
      display: block; } }

.bg-grey {
  background-color: #f5f5f5 !important; }

.lighter-blue {
  color: #4a90e2 !important; }

.font-size-30 {
  font-size: 28px; }

.btn-center-block {
  margin: 0px auto;
  width: 310px;
  text-transform: uppercase; }
  @media (min-width: 992px) {
    .btn-center-block {
      width: inherit; } }

p {
  margin: 0px 0px 25px 0px;
  padding: 0px 0px 0px 0px;
  line-height: 24px; }
  p .anchor-link {
    font-size: 13px;
    line-height: 19px;
    color: #CBE3FF;
    text-transform: none; }
    @media (min-width: 992px) {
      p .anchor-link {
        max-width: 340px;
        margin-top: 10px; } }

.o-home-service .anchor-link {
  margin-top: 0; }
  @media (min-width: 992px) {
    .o-home-service .anchor-link {
      margin-top: 15px; } }

.o-home-service .n-gradient-green-blue {
  background: linear-gradient(172deg, #037773 7%, #00517f 42%, #023e66 67%, #051b39 100%); }
  @media (min-width: 992px) {
    .o-home-service .n-gradient-green-blue {
      background: linear-gradient(350deg, #037c71 0, #042b4e 100%); } }

.small-h1 {
  text-align: center; }
  .small-h1 h1 {
    color: #fff;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 25px;
    font-weight: 700;
    margin-top: 15px; }
    @media (min-width: 992px) {
      .small-h1 h1 {
        padding: 0 80px;
        font-size: 36px;
        line-height: 51px;
        margin-top: 0; } }
  .small-h1 h1.padding-0 {
    padding: 0; }
  .small-h1 h2 {
    font-size: 22px;
    color: #14E07E;
    margin-top: 25px;
    font-weight: 900;
    font-family: "Montserrat", sans-serif;
    padding-bottom: 0; }
    @media (min-width: 992px) {
      .small-h1 h2 {
        font-size: 26px;
        margin-top: 25px;
        font-weight: 700;
        margin-bottom: 0;
        padding: 0 0 0; } }
  .small-h1 .bnr-ofr-text {
    color: #b7b7b7;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 0;
    font-weight: bold;
    font-family: "Montserrat", sans-serif; }
    @media (min-width: 992px) {
      .small-h1 .bnr-ofr-text {
        padding: 0 280px;
        line-height: 36px;
        font-size: 18px;
        font-weight: 600;
        margin-top: 10px; } }
    .small-h1 .bnr-ofr-text a {
      text-decoration: underline;
      color: #b7b7b7; }
    .small-h1 .bnr-ofr-text br {
      display: none; }
      @media (min-width: 992px) {
        .small-h1 .bnr-ofr-text br {
          display: block; } }

.bnr-ofr-text br {
  display: block; }

/*services section*/
.web-app-service {
  padding-top: 40px;
  padding-bottom: 20px;
  position: relative; }
  @media (min-width: 992px) {
    .web-app-service {
      padding-top: 65px;
      padding-bottom: 45px; } }
  .web-app-service .service-wrap {
    text-align: center;
    margin-bottom: 35px; }
    .web-app-service .service-wrap .brick.services-pan {
      display: inline-block;
      vertical-align: top;
      margin: 15px;
      border: 1px solid #E0E0E0;
      border-radius: 6px;
      box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
      background-color: #fff;
      text-align: center;
      padding: 50px 15px 10px;
      position: relative; }
      @media screen and (min-width: 992px) {
        .web-app-service .service-wrap .brick.services-pan {
          width: 46%; } }
      @media screen and (min-width: 1200px) {
        .web-app-service .service-wrap .brick.services-pan {
          width: 30%;
          padding: 50px 30px 30px; } }
      .web-app-service .service-wrap .brick.services-pan:before {
        display: none; }
      .web-app-service .service-wrap .brick.services-pan h3 {
        font-size: 20px;
        position: relative;
        flex: 0 0 100%; }
        @media (min-width: 992px) {
          .web-app-service .service-wrap .brick.services-pan h3 {
            font-size: 24px;
            line-height: 32px;
            padding-bottom: 0; } }
      .web-app-service .service-wrap .brick.services-pan p {
        font-size: 16px;
        line-height: 23px; }
        @media screen and (min-width: 992px) {
          .web-app-service .service-wrap .brick.services-pan p {
            font-size: 17px;
            line-height: 28px; } }
        @media screen and (min-width: 1280px) {
          .web-app-service .service-wrap .brick.services-pan p {
            font-size: 18px;
            line-height: 30px; } }
      .web-app-service .service-wrap .brick.services-pan a {
        margin-bottom: 30px;
        display: block; }
        @media (min-width: 992px) {
          .web-app-service .service-wrap .brick.services-pan a {
            margin-top: auto;
            margin-bottom: 0; } }
      .web-app-service .service-wrap .brick.services-pan i {
        display: block;
        height: 55px; }
        .web-app-service .service-wrap .brick.services-pan i img {
          height: 100%; }
    .web-app-service .service-wrap .brick.services-pan__php-service-01:before {
      background-position: 0 0; }
    .web-app-service .service-wrap .brick.services-pan__php-service-02:before {
      background-position: -62px 0; }
    .web-app-service .service-wrap .brick.services-pan__php-service-03:before {
      background-position: -123px 0; }
    .web-app-service .service-wrap .brick.services-pan__php-service-04:before {
      background-position: -183px 0; }
    .web-app-service .service-wrap .brick.services-pan__php-service-05:before {
      background-position: 0 -57px; }
    .web-app-service .service-wrap .brick.services-pan__php-service-06:before {
      background-position: -62px -55px; }
    .web-app-service .service-wrap .brick.services-pan__php-service-07:before {
      background-position: -123px -57px; }
    .web-app-service .service-wrap .brick.services-pan__php-service-08:before {
      background-position: -183px -57px; }

/*faq section*/
.o-case-studies-new {
  padding-top: 40px; }
  @media (min-width: 992px) {
    .o-case-studies-new {
      padding: 80px 0; } }
  .o-case-studies-new h2 {
    font-size: 24px;
    line-height: 30px;
    padding-bottom: 15px;
    text-align: center;
    color: #333; }
    @media (min-width: 992px) {
      .o-case-studies-new h2 {
        font-size: 48px;
        line-height: 61px;
        padding-bottom: 30px; } }
  .o-case-studies-new .margin-bottom-20 {
    margin-bottom: 10px; }
  .o-case-studies-new .faq-pan {
    margin-bottom: 20px; }
  .o-case-studies-new .faq-holder {
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1); }
    .o-case-studies-new .faq-holder .accordion1 {
      font-size: 16px;
      line-height: 22px;
      padding: 15px 35px 15px 15px;
      color: #333;
      cursor: pointer;
      width: 100%;
      text-align: left;
      font-family: "Montserrat",sans-serif;
      font-weight: bold;
      position: relative;
      text-transform: none;
      display: flex;
      align-items: center;
      padding-right: 45px;
      background-color: #fff; }
      @media (min-width: 992px) {
        .o-case-studies-new .faq-holder .accordion1 {
          padding: 18px;
          border: none;
          outline: none;
          font-size: 19px;
          line-height: 25px;
          transition: 0.4s;
          padding-right: 18px; } }
      .o-case-studies-new .faq-holder .accordion1:after {
        content: '\002B';
        color: #27C070;
        font-weight: normal;
        float: right;
        margin-left: 5px;
        font-size: 30px;
        position: absolute;
        right: 18px; }
    .o-case-studies-new .faq-holder .active1:after {
      content: "\2212"; }
    .o-case-studies-new .faq-holder .panel1 {
      padding: 0 18px;
      background-color: white;
      overflow: hidden;
      transition: max-height 0.2s ease-out;
      display: none;
      font-size: 19px;
      line-height: 30px;
      font-family: "Montserrat", sans-serif;
      font-weight: 500; }
      .o-case-studies-new .faq-holder .panel1 p {
        font-size: 16px;
        line-height: 30px;
        font-family: "Montserrat", sans-serif; }
        @media (min-width: 992px) {
          .o-case-studies-new .faq-holder .panel1 p {
            font-size: 19px;
            line-height: 30px;
            font-family: "Montserrat", sans-serif;
            font-weight: 500; } }

.o-bottom-form {
  padding: 0; }
  @media (min-width: 992px) {
    .o-bottom-form {
      padding-right: 15px;
      padding-left: 15px;
      padding: 80px 0 90px; } }
  .o-bottom-form .form-text h2 {
    padding-bottom: 20px; }
    .o-bottom-form .form-text h2:before {
      content: '';
      position: absolute;
      background: #2ebf72;
      width: 50px;
      height: 1px;
      bottom: 7px;
      left: 0; }
  .o-bottom-form .container {
    padding-left: 0;
    padding-right: 0; }
    @media (min-width: 992px) {
      .o-bottom-form .container {
        padding-left: 15px;
        padding-right: 15px; } }

/*let's talk*/
.lets-talk {
  background: url(/images/common/schedule-talk-bg.jpg);
  background-size: cover;
  padding: 30px 0 45px;
  margin-top: 0; }
  @media (min-width: 992px) {
    .lets-talk {
      margin-top: 25px; } }
  .lets-talk h2 {
    color: #fff;
    padding-bottom: 5px;
    font-size: 22px;
    line-height: 30px; }
    @media (min-width: 992px) {
      .lets-talk h2 {
        font-size: 48px;
        line-height: 61px;
        padding-bottom: 25px; } }
    .lets-talk h2 span {
      display: block; }
      @media (min-width: 992px) {
        .lets-talk h2 span {
          display: inline; } }

/*Custom software services*/
.mobile-on-cs {
  display: block; }
  @media (min-width: 992px) {
    .mobile-on-cs {
      display: none !important; } }

.mobile-on-flex {
  display: flex !important; }
  @media (min-width: 992px) {
    .mobile-on-flex {
      display: none !important; } }

.mobile-off {
  display: none; }
  @media (min-width: 992px) {
    .mobile-off {
      display: block; } }

.mobile-off-flex {
  display: none; }
  @media (min-width: 992px) {
    .mobile-off-flex {
      display: flex; } }

@media (min-width: 992px) {
  .masonry.service-wrap {
    column-count: 3;
    column-gap: 27px;
    margin-bottom: 30px; } }

/*Mobile app*/
.h3-24 {
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600; }

.c-ticked-list--light li {
  position: relative;
  padding: 0 0 5px 30px;
  line-height: 24px;
  font-size: 16px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif; }
  @media (min-width: 992px) {
    .c-ticked-list--light li {
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      line-height: 29px;
      font-size: 19px;
      padding: 0 0 15px 35px; } }
  .c-ticked-list--light li:before {
    content: '';
    position: absolute;
    background: url(/images/mobile-app/circle-tick.svg) 50% 50% no-repeat;
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 4px;
    left: 0px; }
    @media (min-width: 992px) {
      .c-ticked-list--light li:before {
        background: url(/images/common/icon-bullet.svg) 50% 50% no-repeat;
        top: 5px; } }

.o-mobile-app-features--green {
  background: #43AE76; }

/* Mobile Tab */
.o-mobile-app-features-parent {
  position: relative; }

.o-mobile-app-features-tab {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between; }

.o-mobile-app-features-tab-btn {
  padding: 0.5rem 1rem;
  border: 1px solid #fff;
  border-radius: 3px;
  font-family: Montserrat,sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  text-transform: none;
  margin-right: 10px; }
  .o-mobile-app-features-tab-btn:last-child {
    margin: 0; }
  .o-mobile-app-features-tab-btn:before, .o-mobile-app-features-tab-btn:after {
    content: '';
    border: 9px solid transparent;
    border-top-color: #fff;
    border-bottom: 0;
    position: absolute;
    left: 50%;
    bottom: -9px;
    transform: translate(-50%, 0);
    display: none; }
  .o-mobile-app-features-tab-btn:after {
    border: 8px solid transparent;
    border-top-color: #115687;
    border-bottom: 0;
    bottom: -7px;
    z-index: 1; }
  .o-mobile-app-features-tab-btn.active:before, .o-mobile-app-features-tab-btn.active:after {
    display: block; }
  .o-mobile-app-features-tab-btn--bg-blue {
    background: #115687; }
  .o-mobile-app-features-tab-btn--bg-green {
    background: #43ae76; }
    .o-mobile-app-features-tab-btn--bg-green:after {
      border-top-color: #43ae76; }

.service-details.daas-service .service-wrap .brick.services-pan {
  display: block; }
  @media (min-width: 992px) {
    .service-details.daas-service .service-wrap .brick.services-pan {
      display: inline-block; } }

.no-webp .tl-pt:before {
  background-image: url(/images/common/tl-pt.png); }

.webp .tl-pt:before {
  background-image: url(/images/webp/common/tl-pt.webp); }

.tl-pt {
  position: relative; }
  .tl-pt:before {
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    background-position: top left;
    width: 200px;
    height: 80px;
    background-size: 75%;
    top: 0;
    left: 0; }
    @media (min-width: 992px) {
      .tl-pt:before {
        width: 517px;
        height: 206px;
        background-size: 100%; } }

.no-webp .br-pt:after {
  background-image: url(/images/common/mb-br-pt.png); }
  @media (min-width: 992px) {
    .no-webp .br-pt:after {
      background-image: url(/images/common/br-pt.png); } }

.webp .br-pt:after {
  background-image: url(/images/common/mb-br-pt.webp); }
  @media (min-width: 992px) {
    .webp .br-pt:after {
      background-image: url(/images/webp/common/br-pt.webp); } }

.br-pt {
  position: relative; }
  .br-pt:after {
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    background-position: bottom right;
    width: 160px;
    height: 80px;
    bottom: 0;
    right: 0;
    background-size: 100%; }
    @media (min-width: 992px) {
      .br-pt:after {
        width: 200px; } }
    @media (min-width: 992px) {
      .br-pt:after {
        width: 500px;
        height: 240px;
        background-size: 100%; } }

.br-pt.hm-bnr:after {
  background-position: bottom right; }
  @media (min-width: 992px) {
    .br-pt.hm-bnr:after {
      background-position: 160px 0; } }

.no-webp .tr-pt:before {
  background-image: url(/images/common/mb-tr-pt.png); }
  @media (min-width: 992px) {
    .no-webp .tr-pt:before {
      background-image: url(/images/common/tr-pt.png); } }

.webp .tr-pt:before {
  background-image: url(/images/common/mb-tr-pt.webp); }
  @media (min-width: 992px) {
    .webp .tr-pt:before {
      background-image: url(/images/webp/common/tr-pt.webp); } }

.tr-pt {
  position: relative; }
  .tr-pt:before {
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 100%;
    height: 80px;
    width: 200px;
    top: 0;
    right: 0; }
    @media (min-width: 992px) {
      .tr-pt:before {
        display: block;
        background-size: 100%;
        width: 530px;
        height: 215px; } }

.no-webp .bl-pt:after {
  background-image: url(/images/common/tr-pt.png); }

.webp .bl-pt:after {
  background-image: url(/images/webp/common/tr-pt.webp); }

.bl-pt {
  position: relative; }
  .bl-pt:after {
    content: '';
    position: absolute;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom left;
    height: 75px;
    width: 200px;
    bottom: 0;
    left: 0;
    transform: rotate(-180deg); }
    @media (min-width: 992px) {
      .bl-pt:after {
        display: block;
        background-size: 100%;
        width: 530px;
        height: 215px; } }

.no-webp .mb-tl-pt:after {
  background-image: url(/images/common/mb-tl-pt.png); }

.webp .mb-tl-pt:after {
  background-image: url(/images/common/mb-tl-pt.webp); }

.mb-tl-pt:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 100px;
  background-size: 100%;
  background-position: top left;
  background-repeat: no-repeat;
  transform: translate(0); }
  @media (min-width: 992px) {
    .mb-tl-pt:after {
      display: none; } }

.no-webp .mb-w-tr-pt:before {
  background-image: url(/images/common/mb-w-tr-pt.png); }

.webp .mb-w-tr-pt:before {
  background-image: url(/images/common/mb-w-tr-pt.webp); }

.mb-w-tr-pt:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 200px;
  height: 100px;
  background-size: 100%;
  background-position: top right;
  background-repeat: no-repeat;
  z-index: 99; }
  @media (min-width: 992px) {
    .mb-w-tr-pt:before {
      display: none; } }

@media (min-width: 992px) {
  .acnt-p {
    font-size: 16px; } }

@media (min-width: 992px) {
  .form-panel .u-submit {
    margin-top: 15px; } }

.img-cr-block {
  margin: 0 auto;
  display: block; }

.ai-chatbots-hero .container {
  padding-top: 100px; }

.new-srv-slider .col-sm-6, .new-srv-slider .col-sm-6 {
  flex-wrap: wrap;
  flex: 50% !important;
  max-width: 50%; }

.o-apply-now .anchor-link {
  max-width: 340px; }

.cs-slider-img img {
  width: 100%; }
  @media (min-width: 992px) {
    .cs-slider-img img {
      height: 100%; } }

.ai-bot .insights-section .fi-pan a {
  min-height: 370px; }

.navbar-brand source {
  width: 80%; }
  @media (min-width: 992px) {
    .navbar-brand source {
      width: auto; } }

.navbar-brand img {
  width: 100%; }

.border-bottom-lightgreen {
  border-bottom: 1px solid #dedede; }

.o-home-cn-details.pad-bot-40 {
  padding-bottom: 20px; }
  @media (min-width: 992px) {
    .o-home-cn-details.pad-bot-40 {
      padding-bottom: 50px; } }

.cstm-link-p {
  margin-top: 0;
  margin-bottom: 25px; }
  @media (min-width: 992px) {
    .cstm-link-p {
      margin-top: 5px; } }

.splide__pagination {
  position: static;
  transform: translate(0); }
  .splide__pagination .splide__pagination__page {
    margin: 3px 5px; }
  .splide__pagination .splide__pagination__page.is-active {
    transform: scale(1.4);
    background: #27c070; }

.o-case-studies-slider .splide__pagination {
  margin-top: 30px; }

.o-case-studies-slider .btn_wrapper.pad-top-50 {
  padding-top: 20px; }
  @media (min-width: 992px) {
    .o-case-studies-slider .btn_wrapper.pad-top-50 {
      padding-top: 20px; } }

.crop-pad-top {
  margin-top: 10px; }
  @media (min-width: 992px) {
    .crop-pad-top {
      margin-top: 0; } }

.splide__pagination {
  width: 100%; }

.insights-section {
  padding: 45px 0 50px !important; }
  @media (min-width: 992px) {
    .insights-section {
      padding: 70px 0 80px !important; } }
  .insights-section .btn_wrapper {
    padding-top: 0; }
    @media (min-width: 992px) {
      .insights-section .btn_wrapper {
        padding-top: 40px; } }

.splide__pagination__page {
  background: #666565; }

.pager_new {
  display: block; }
  @media (min-width: 992px) {
    .pager_new {
      display: none; } }

.lSSlideOuter {
  padding-bottom: 25px;
  position: relative; }

.fa-circle:before {
  font-family: 'Font Awesome\ 5 Free'; }

.lSSlideOuter.custom-color .lSPager.lSGallery li i {
  color: #9b9b9b !important; }

.lSSlideOuter.custom-color .lSPager.lSGallery li.active i,
li.focus .dot_sliders {
  color: #27c070 !important; }

.lSSlideOuter.custom-color .lSPager.lSGallery {
  margin-top: 75px !important; }

.o-case-studies-slider .slick-dots {
  margin-bottom: 15px; }

.career-bnr {
  padding-top: 120px; }
  @media (min-width: 992px) {
    .career-bnr {
      padding-top: 80px; } }
  .career-bnr img {
    margin: 0 auto; }
    @media (min-width: 992px) {
      .career-bnr img {
        margin: 0 auto; } }

.service-wrap .services-pan p {
  font-family: "Montserrat", sans-serif !important; }
  @media (min-width: 992px) {
    .service-wrap .services-pan p {
      font-family: "Montserrat", sans-serif !important; } }

.c-icon-desc-3-col-ccn .row > div p {
  font-family: "Montserrat", sans-serif !important; }
  @media (min-width: 992px) {
    .c-icon-desc-3-col-ccn .row > div p {
      font-family: "Montserrat", sans-serif !important; } }

.o-case-studies-slider .cs-slider .cs-slider-text h4,
.o-case-studies-slider .cs-slider .cs-slider-text .cs-tech,
.o-case-studies-slider .cs-slider .cs-slider-text p,
.o-case-studies-new .faq-holder .panel1 p,
.insights-section .fi-pan p,
.new-review-box h4,
.o-bottom-form .form-text p.sub-heading,
.o-bottom-form .form-text p,
.o-bottom-form .form-text ul li span {
  font-family: "Montserrat", sans-serif !important; }
  @media (min-width: 992px) {
    .o-case-studies-slider .cs-slider .cs-slider-text h4,
    .o-case-studies-slider .cs-slider .cs-slider-text .cs-tech,
    .o-case-studies-slider .cs-slider .cs-slider-text p,
    .o-case-studies-new .faq-holder .panel1 p,
    .insights-section .fi-pan p,
    .new-review-box h4,
    .o-bottom-form .form-text p.sub-heading,
    .o-bottom-form .form-text p,
    .o-bottom-form .form-text ul li span {
      font-family: "Montserrat", sans-serif !important; } }

.home-faq {
  padding-top: 45px !important;
  padding-bottom: 20px; }
  @media (min-width: 992px) {
    .home-faq {
      padding: 65px 0 50px !important; } }

.faq-section {
  padding-top: 45px !important; }
  @media (min-width: 992px) {
    .faq-section {
      padding: 60px 0 60px !important; } }
  .faq-section .container {
    position: relative;
    z-index: 9; }

.cto-wrap i {
  height: 70px;
  display: flex;
  justify-content: center; }

.first-letter-small:first-letter {
  text-transform: lowercase; }

.apart-p {
  font-size: 16px;
  line-height: 24px; }
  @media (min-width: 992px) {
    .apart-p {
      font-size: 19px;
      line-height: 32px; } }

.panel1 ul li {
  font-family: Montserrat,sans-serif; }

.font-size-50 {
  font-size: 30px; }
  @media screen and (min-width: 992px) {
    .font-size-50 {
      font-size: 50px !important; } }

@media screen and (min-width: 992px) {
  .aprch-blockchain .masonry .brick {
    flex: 1; } }

@media screen and (min-width: 992px) {
  .ind-off-section .service-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; } }

@media screen and (min-width: 992px) {
  .ind-off-section .service-wrap .brick.services-pan {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 50px 30px 50px; } }

@media screen and (min-width: 992px) {
  .home-ind-off-section .service-wrap .brick.services-pan {
    align-content: flex-start;
    padding: 50px 30px 100px; } }

@media screen and (min-width: 992px) {
  .home-ind-off-section .service-wrap .brick.services-pan a {
    position: absolute;
    bottom: 50px; } }

.home-ind-off-section .service-wrap .brick.services-pan h3 {
  flex: 0 0 100%; }

@media screen and (min-width: 992px) {
  .ind-off-section-com .service-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; } }

@media screen and (min-width: 992px) {
  .ind-off-section-com .service-wrap .brick.services-pan {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 50px 30px 50px;
    align-content: baseline; } }

.mobile-container {
  padding: 0 30px !important; }
  @media (min-width: 992px) {
    .mobile-container {
      padding: 0 15px !important; } }

.new-service-section {
  padding: 40px 0 10px; }
  @media screen and (min-width: 992px) {
    .new-service-section {
      padding: 70px 0 60px; } }
  .new-service-section h2 {
    margin-bottom: 30px; }
    @media screen and (min-width: 992px) {
      .new-service-section h2 {
        margin-bottom: 70px; } }
  .new-service-section-wrapper {
    display: flex;
    flex-wrap: wrap; }
    @media screen and (min-width: 992px) {
      .new-service-section-wrapper {
        display: inherit;
        column-count: 2;
        column-gap: 30px; } }
  .new-service-section-box {
    margin-bottom: 35px;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .new-service-section-box {
        margin-bottom: 50px;
        break-inside: avoid;
        page-break-inside: avoid;
        display: flex; } }
    .new-service-section-box i {
      display: block;
      width: 50px;
      margin: 0 auto 20px; }
      @media screen and (min-width: 992px) {
        .new-service-section-box i {
          display: inline-block;
          margin: 0; } }
    .new-service-section-box-right {
      flex: 1;
      text-align: center; }
      @media screen and (min-width: 992px) {
        .new-service-section-box-right {
          padding-left: 20px;
          margin-left: 10px;
          text-align: left; } }
      .new-service-section-box-right h3 {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 15px; }
        @media screen and (min-width: 992px) {
          .new-service-section-box-right h3 {
            font-size: 26px; } }
      .new-service-section-box-right p {
        font-size: 16px;
        margin-bottom: 0; }
        @media screen and (min-width: 992px) {
          .new-service-section-box-right p {
            line-height: 28px; } }
        @media screen and (min-width: 1200px) {
          .new-service-section-box-right p {
            font-size: 17px; } }
        @media screen and (min-width: 1280px) {
          .new-service-section-box-right p {
            font-size: 18px; } }
        .new-service-section-box-right p a {
          display: inline;
          margin: 0;
          font-size: 18px;
          width: inherit;
          position: inherit;
          color: #333;
          text-decoration: underline; }
          .new-service-section-box-right p a:before {
            display: none; }
      .new-service-section-box-right a {
        font-size: 16px;
        display: block;
        margin: 5px auto 0;
        position: relative;
        width: 107px;
        transition: .3s all ease-in-out; }
        .new-service-section-box-right a:before {
          content: "➞";
          position: absolute;
          right: -17px;
          top: 0;
          transition: .3s all ease-in-out; }
        @media screen and (min-width: 1200px) {
          .new-service-section-box-right a {
            font-size: 17px;
            margin-top: 5px;
            margin-left: 0;
            margin-right: 0; } }
        @media screen and (min-width: 1280px) {
          .new-service-section-box-right a {
            font-size: 18px; } }
        .new-service-section-box-right a:hover {
          text-decoration: underline;
          color: #0257d5; }
          .new-service-section-box-right a:hover:before {
            right: -22px; }
  .new-service-section .ns-order-1 {
    order: 1; }
  .new-service-section .ns-order-2 {
    order: 2; }
  .new-service-section .ns-order-3 {
    order: 3; }
  .new-service-section .ns-order-4 {
    order: 4; }
  .new-service-section .ns-order-5 {
    order: 5; }
  .new-service-section .ns-order-6 {
    order: 6; }
  .new-service-section .ns-order-7 {
    order: 7; }
  .new-service-section .ns-order-8 {
    order: 8; }
  .new-service-section .ns-order-9 {
    order: 9; }
  .new-service-section .ns-order-10 {
    order: 10; }
  .new-service-section .ns-order-11 {
    order: 11; }
  .new-service-section .ns-order-12 {
    order: 12; }
  .new-service-section .ns-order-13 {
    order: 13; }
  .new-service-section .ns-order-14 {
    order: 14; }
  .new-service-section .ns-order-15 {
    order: 15; }

/* Contact Form Modal */
.contact-modal .modal-content {
  padding: 30px;
  background-color: #fff; }
  .contact-modal .modal-content .modal-header button.close img {
    width: 20px; }
  .contact-modal .modal-content .modal-header figure {
    width: 70px;
    height: 70px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 25px; }
    .contact-modal .modal-content .modal-header figure img {
      display: block;
      position: absolute;
      z-index: 10;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50% !important; }
    .contact-modal .modal-content .modal-header figure:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      animation: spin, 10s, linear, 0s, infinite; }
  .contact-modal .modal-content .modal-header .close {
    position: absolute;
    right: 30px;
    top: 30px;
    background-color: transparent;
    width: auto; }
  .contact-modal .modal-content .modal-header h5 {
    font-size: 18px;
    padding-bottom: 20px;
    padding-top: 20px;
    text-align: left; }
  .contact-modal .modal-content .modal-header p {
    font-size: 20px;
    line-height: 33px;
    font-weight: 600;
    font-family: "Montserrat",sans-serif; }
  .contact-modal .modal-content input.form-control {
    width: 100%; }
  .contact-modal .modal-content .btn {
    display: inline-block;
    text-align: center;
    border-radius: 5px;
    padding: 10px 0px;
    font-size: 18px;
    text-transform: uppercase;
    max-width: 158px;
    text-decoration: none;
    position: relative;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    color: #FFFFFF;
    border: 2px solid #FF5800;
    background-color: #FF5800;
    width: 250px; }

/* ~./Contact Form Modal */
#attachedFiles {
  margin-top: 20px; }
  #attachedFiles li {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 8px 15px;
    border-radius: 30px;
    background: #fafafa; }
    #attachedFiles li input {
      line-height: 20px;
      width: 87%;
      font-size: 14px; }
    #attachedFiles li button {
      background: url("/images/common/icon-delete.svg") 0 0 no-repeat;
      width: 14px;
      height: 19px;
      margin-right: 10px;
      vertical-align: middle; }

.u-name {
  background: url(/images/common/form-icon-1.png) 0 50% no-repeat;
  border-bottom: 1px solid #ccc; }

.u-email {
  background: url(/images/common/form-icon-2.png) 0 50% no-repeat;
  border-bottom: 1px solid #ccc; }

.u-phone {
  background: url(/images/common/form-icon-3.png) 0 50% no-repeat;
  border-bottom: 1px solid #ccc; }

.u-company {
  background: url(/images/common/form-icon-4.png) 0 50% no-repeat;
  border-bottom: 1px solid #ccc; }

.u-message {
  background: url(/images/common/form-icon-5.png) 0 20px no-repeat;
  border-bottom: 1px solid #ccc; }

.attach {
  background: url(/images/common/attach-icon.svg) no-repeat;
  font-family: "Montserrat", sans-serif;
  padding: 10px 20px 0 40px;
  background-position: 0 15px; }
  @media (min-width: 992px) {
    .attach {
      background-position: 0 30px;
      padding: 20px 20px 0 40px; } }
  .attach .attach-inputfile {
    display: none; }
  .attach label {
    color: blue;
    cursor: pointer;
    text-decoration: underline; }
    .attach label:hover {
      text-decoration: none; }

.common-input {
  border-bottom: 1px solid #000;
  padding: 20px 20px 20px 40px;
  color: #000;
  width: 100%;
  margin-bottom: 5px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  font-size: 16px; }
  @media (min-width: 992px) {
    .common-input {
      padding: 20px 20px 20px 40px; } }

.u-submit {
  display: block;
  text-align: center;
  border-radius: 5px;
  padding: 10px 0px;
  font-size: 15px;
  text-transform: uppercase;
  max-width: 350px;
  text-decoration: none;
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #fff;
  width: 250px;
  margin: 0 auto; }
  @media (min-width: 992px) {
    .u-submit {
      padding: 19px 0px;
      font-size: 18px;
      width: 250px; } }
  .u-submit:after {
    content: '';
    background: url(/images/common/arrow-white.png) no-repeat;
    width: 20px;
    height: 14px;
    display: inline-block;
    margin-left: 15px;
    z-index: -1;
    display: none; }

/* placeholder color */
::-webkit-input-placeholder {
  font-weight: 300; }

::-moz-placeholder {
  font-weight: 300; }

:-ms-input-placeholder {
  font-weight: 300; }

:-moz-placeholder {
  font-weight: 300; }

label.error {
  color: red;
  text-align: left;
  display: block;
  font-weight: 300; }

.mm-slideout {
  transition: transform .4s ease;
  z-index: 100; }

body[data-page="contact-us"] .o-contact-form {
  /* media query for tablet landscape */ }
  body[data-page="contact-us"] .o-contact-form--mobile-top {
    background: url("images/contact-us/contact-form-top-border-mobile.png") 0 0 repeat-x; }
  @media (min-width: 992px) {
    body[data-page="contact-us"] .o-contact-form {
      position: relative;
      box-shadow: 0px, 10px, 35px, rgba(0, 0, 0, 0.1);
      padding: 0;
      background: transparent;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px; } }
  body[data-page="contact-us"] .o-contact-form--mobile {
    text-align: center;
    display: block;
    padding-top: 66px;
    padding: 60px 0 40px 0;
    /* media query for tablet landscape */ }
    @media (min-width: 992px) {
      body[data-page="contact-us"] .o-contact-form--mobile {
        padding: 76px 0 40px 0; } }
    body[data-page="contact-us"] .o-contact-form--mobile h2 {
      font-size: 36px;
      line-height: 54px;
      /* media query for tablet landscape */ }
      @media (min-width: 992px) {
        body[data-page="contact-us"] .o-contact-form--mobile h2 {
          font-size: 48px;
          line-height: 58px; } }
    body[data-page="contact-us"] .o-contact-form--mobile h3 {
      font-size: 16px;
      font-weight: 400;
      /* media query for tablet landscape */ }
      @media (min-width: 992px) {
        body[data-page="contact-us"] .o-contact-form--mobile h3 {
          font-size: 28px;
          line-height: 35px;
          font-weight: 500; } }
    body[data-page="contact-us"] .o-contact-form--mobile h5 {
      font-size: 16px;
      font-weight: 400;
      /* media query for tablet landscape */ }
      @media (min-width: 992px) {
        body[data-page="contact-us"] .o-contact-form--mobile h5 {
          font-size: 28px;
          line-height: 35px;
          font-weight: 500; } }
    body[data-page="contact-us"] .o-contact-form--mobile h6, body[data-page="contact-us"] .o-contact-form--mobile .contact-linkedin, body[data-page="contact-us"] .o-contact-form--mobile .attach, body[data-page="contact-us"] .o-contact-form--mobile .u-company {
      display: none;
      /* media query for tablet landscape */ }
      @media (min-width: 992px) {
        body[data-page="contact-us"] .o-contact-form--mobile h6, body[data-page="contact-us"] .o-contact-form--mobile .contact-linkedin, body[data-page="contact-us"] .o-contact-form--mobile .attach, body[data-page="contact-us"] .o-contact-form--mobile .u-company {
          display: block; } }
    body[data-page="contact-us"] .o-contact-form--mobile .group .choosecountry {
      display: none;
      /* media query for tablet landscape */ }
      @media (min-width: 992px) {
        body[data-page="contact-us"] .o-contact-form--mobile .group .choosecountry {
          display: block; } }
    body[data-page="contact-us"] .o-contact-form--mobile .group input[type='text'] {
      padding: 20px 20px 20px 0;
      background: url("/assets/images/common/icon-phone.svg") 0 50% no-repeat;
      /* media query for tablet landscape */ }
      @media (min-width: 992px) {
        body[data-page="contact-us"] .o-contact-form--mobile .group input[type='text'] {
          padding: 20px 20px 20px 40px;
          background: none; } }
  body[data-page="contact-us"] .o-contact-form--contact-form-footer {
    padding: 20px 0;
    margin-top: 30px;
    text-align: left;
    /*mobile first for tablet landscape & small desktop*/ }
    @media (min-width: 992px) {
      body[data-page="contact-us"] .o-contact-form--contact-form-footer {
        text-align: center; } }
    body[data-page="contact-us"] .o-contact-form--contact-form-footer ul li {
      margin: 0 15px; }
  body[data-page="contact-us"] .o-contact-form__attachement-text {
    padding-left: 18px; }

body[data-page="contact-us"] .contact-mobile {
  display: none; }

.o-contact-form {
  /*mobile first for tablet landscape & small desktop*/
  /* Contact Form Background  */
  /* ~./Contact Form Background */
  /* Heart Animation */
  /* ~./Heart Animation */
  /* Privacy Text  */
  /* ~./Privacy Text */
  /* Attachement Text  */
  /* ~./Attachement Text */
  /* Contact Form Footer  */
  /* ~./Contact Form Footer */
  /* PSD to HTML Place your order form */
  /* ~./ PSD to HTML Place your order form */ }
  @media (min-width: 992px) {
    .o-contact-form {
      background-size: 30%;
      color: #000;
      overflow: hidden;
      text-align: center;
      position: relative; } }
  .o-contact-form h2 {
    margin-bottom: 15px;
    /*mobile first for tablet landscape & small desktop*/ }
    @media (min-width: 992px) {
      .o-contact-form h2 {
        font-size: 60px;
        letter-spacing: 2.5px;
        text-shadow: 1px 1px 0px #333, -1px 1px 0px #333, 1px -1px 0px #333, -1px -1px 0px #333; } }
  @media (min-width: 992px) {
    .o-contact-form h3 {
      font-size: 28px;
      line-height: 35px;
      font-weight: 500; } }
  .o-contact-form h3 br {
    display: none;
    /* mobile first for small desktop */ }
    @media (min-width: 992px) {
      .o-contact-form h3 br {
        display: block; } }
  @media (min-width: 992px) {
    .o-contact-form h5 {
      font-size: 28px;
      line-height: 35px;
      font-weight: 500; } }
  .o-contact-form h5 br {
    display: none;
    /* mobile first for small desktop */ }
    @media (min-width: 992px) {
      .o-contact-form h5 br {
        display: block; } }
  .o-contact-form h4 {
    font-size: 18px;
    padding-bottom: 20px;
    padding-top: 20px;
    text-align: left; }
  .o-contact-form h6 {
    font-size: 18px;
    padding-bottom: 20px;
    padding-top: 20px;
    text-align: left; }
  .o-contact-form--white-bg {
    /*mobile first for tablet landscape & small desktop*/ }
    @media (min-width: 992px) {
      .o-contact-form--white-bg {
        background-color: #fff;
        box-shadow: 0px, 0px, 20px, rgba(0, 0, 0, 0.2);
        padding: 80px 0 0;
        border-radius: 3px;
        overflow: hidden;
        display: block; } }
  .o-contact-form .form-animate {
    transition: 0.1s, ease-out;
    animation: formanimation, 0.3s, ease-in-out, 0s, 2; }
  .o-contact-form__heart {
    display: inline-block;
    margin-left: -4px;
    line-height: 49px;
    vertical-align: top;
    animation: pulse, 1.5s, ease-out, 0s, infinite;
    padding-left: 10px;
    padding-right: 10px;
    /* mobile first for mobiles */ }
    @media (min-width: 992px) {
      .o-contact-form__heart {
        padding-left: 20px;
        padding-right: 20px; } }
    @media (min-width: 992px) {
      .o-contact-form__heart img {
        height: 58px; } }
  .o-contact-form__privacy {
    margin: 20px 0 20px 0;
    font-size: 15px;
    text-align: center; }
    .o-contact-form__privacy i {
      margin-right: 10px; }
  .o-contact-form__attachement-text a {
    color: #4492DC;
    text-decoration: underline; }
    .o-contact-form__attachement-text a:hover {
      text-decoration: none; }
  .o-contact-form__attachement-text label {
    color: #4492DC;
    text-decoration: underline;
    cursor: pointer; }
    .o-contact-form__attachement-text label:hover {
      text-decoration: none; }
  .o-contact-form--contact-form-footer {
    background-color: #f2f2f2;
    padding: 40px 0;
    margin-top: 60px; }
    .o-contact-form--contact-form-footer ul li {
      display: inline-block;
      font-size: 16px;
      color: #000;
      margin: 0 30px; }
      .o-contact-form--contact-form-footer ul li i {
        margin-right: 20px; }
      .o-contact-form--contact-form-footer ul li strong {
        font-weight: 700; }
    .o-contact-form--contact-form-footer a {
      color: #000; }
      .o-contact-form--contact-form-footer a:hover {
        text-decoration: underline; }
    .o-contact-form--contact-form-footer .o-clients {
      padding: 30px 0 0 0; }
  .o-contact-form--place-order .tagline {
    font-size: 12px; }
  .o-contact-form--place-order .attach span {
    vertical-align: top; }

.common-input.u-message {
  background: url(/images/common/email-icon.png) 0 20px no-repeat; }

.form-inside {
  padding-top: 0; }
  @media (min-width: 992px) {
    .form-inside {
      padding-top: 30px; } }
  .form-inside p {
    font-size: 16px;
    color: #333333;
    padding-bottom: 5px !important;
    line-height: 28px;
    margin-top: 15px; }
    @media (min-width: 992px) {
      .form-inside p {
        margin-top: 0;
        font-size: 18px;
        line-height: 30px; } }
    .form-inside p a {
      color: #4a90e2; }

.bnr-form .group input[type="text"] {
  border-bottom: 1px solid #C9C8C8;
  padding: 20px 20px 20px 40px;
  color: #333;
  width: 100%;
  margin-bottom: 10px;
  font-weight: 400; }

.bnr-form input {
  border: none;
  border-bottom: 1px solid #000 !important; }
  @media (min-width: 992px) {
    .bnr-form input {
      border-bottom: 1px solid #C9C8C8; } }

.bnr-form .u-name {
  border-bottom: 1px solid #C9C8C8;
  padding: 20px 20px 20px 40px;
  color: #333;
  width: 100%;
  margin-bottom: 10px;
  font-weight: 400; }

.bnr-form .u-email {
  padding: 20px 20px 20px 40px;
  color: #333;
  width: 100%;
  margin-bottom: 10px;
  font-weight: 400; }

.bnr-form .u-company {
  padding: 20px 20px 20px 40px;
  color: #333;
  width: 100%;
  margin-bottom: 10px;
  font-weight: 400; }

.bnr-form .u-message.bnr-msg {
  border-bottom: 1px solid #000;
  margin-bottom: 10px;
  position: relative;
  font-weight: 400; }
  @media (min-width: 992px) {
    .bnr-form .u-message.bnr-msg {
      border-bottom: 1px solid #C9C8C8; } }

.bnr-form .u-message.bnr-msg textarea {
  background: url("/images/common/form-icon-5.png") 0 20px no-repeat;
  padding: 20px 20px 20px 40px;
  color: #333;
  min-height: 100px;
  width: 100%;
  margin-bottom: 30px;
  border: none; }

.bnr-form .attach {
  margin-top: 5px;
  margin-bottom: 15px;
  text-align: left; }

.bnr-form .attach span {
  margin-left: 10px;
  display: inline-block; }

.bnr-form .o-contact-form__attachement-text {
  font-size: 16px;
  color: #878787; }

.bnr-form .attach span {
  margin-left: 10px;
  display: inline-block;
  font-size: 15px;
  margin-bottom: 5px;
  line-height: 20px;
  margin-top: 5px; }

.bnr-form .attach {
  padding: 20px 25px 5px 25px; }

.o-contact-form__privacy {
  margin: 20px 0 20px 0;
  font-size: 15px;
  text-align: center;
  font-family: "Montserrat",sans-serif;
  font-weight: 400;
  color: #414446 !important; }
  @media (min-width: 992px) {
    .o-contact-form__privacy {
      font-size: 17px; } }

.u-message span#requirements_feedback {
  position: absolute;
  right: 25px;
  bottom: 12px;
  font-size: 14px;
  color: #333;
  font-weight: 300; }

.small-heading {
  text-align: left;
  display: block;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 35px;
  padding-left: 15px;
  display: none; }
  @media (min-width: 992px) {
    .small-heading {
      padding: 0;
      display: block; } }

input, textarea {
  font-size: 16px; }

.s-btn-modifier {
  width: 265px; }
  @media (min-width: 992px) {
    .s-btn-modifier {
      width: 478px; } }

.cntct-pg-section {
  background: #fff;
  padding-top: 20px !important; }
  @media (min-width: 992px) {
    .cntct-pg-section {
      padding: 100px 20px 60px !important; } }

.new-contact .o-contact-us .col-md-6 {
  padding: 0; }
  @media (min-width: 992px) {
    .new-contact .o-contact-us .col-md-6 {
      padding: 0 15px; } }

.new-contact .u-submit.s-btn-modifier {
  max-width: 260px; }
  @media (min-width: 992px) {
    .new-contact .u-submit.s-btn-modifier {
      max-width: 350px; } }

.new-contact .o-footer {
  padding: 0 0 40px; }
  @media (min-width: 992px) {
    .new-contact .o-footer {
      padding: 0 0 40px; } }

.cn-form {
  position: relative;
  background: #fff; }
  .cn-form:before {
    content: '';
    position: absolute;
    background: url(/images/contact-us/dashed-line.png) 0 0 repeat;
    height: 5px;
    width: 100%;
    top: 0;
    left: 0;
    right: 0; }
  .cn-form:after {
    content: '';
    position: absolute;
    background: url(/images/contact-us/dashed-line.png) 0 0 repeat;
    height: 5px;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    display: none; }
    @media (min-width: 992px) {
      .cn-form:after {
        display: block; } }
  .cn-form .row .col-md-10 {
    margin: 0 auto; }

.o-contact-us {
  padding-top: 0 !important; }
  @media (min-width: 992px) {
    .o-contact-us {
      padding-top: 0 !important; } }

.contact-addr .office ul {
  background: #fff; }

.o-contact-form__contact-form {
  padding: 0 15px; }
  @media (min-width: 992px) {
    .o-contact-form__contact-form {
      padding: 0; } }

.opt-phn {
  position: relative; }
  .opt-phn input {
    background: transparent;
    width: 100%;
    font-size: 14px; }

.url-list label {
  text-decoration: none;
  color: #333;
  font-size: 16px;
  cursor: default; }

.url-input {
  padding: 0;
  font-size: 16px;
  display: initial;
  display: initial; }

.contact-modal .modal-content button#cloudFileAdd {
  background-color: #FD5900;
  color: #ffffff;
  font-size: 17px;
  line-height: 16px;
  height: 42px;
  padding: 10px 20px;
  text-transform: uppercase;
  display: block !important;
  width: 150px;
  margin: 0 0 0 !important;
  border-radius: 4px;
  cursor: pointer;
  float: right;
  font-weight: 600;
  text-align: left; }
  .contact-modal .modal-content button#cloudFileAdd:after {
    content: '';
    display: inline-block;
    width: 15px;
    height: 13px;
    background: url("/images/common/arrow-white.png") 50% 50% no-repeat;
    background-size: auto;
    background-size: 15px;
    margin-left: 10px;
    position: absolute;
    right: 10%;
    top: 50%;
    transform: translateY(-50%); }

.contact-modal .modal-content button#cloudFileAdd,
.contact-modal .modal-content .cloudModalFooter button {
  background-color: #FD5900;
  color: #ffffff;
  font-size: 17px;
  line-height: 16px;
  height: 42px;
  padding: 10px 20px;
  text-transform: uppercase;
  display: block !important;
  width: 150px;
  margin: 0 0 0 !important;
  border-radius: 4px;
  cursor: pointer;
  float: right;
  font-weight: 600;
  text-align: left; }
  .contact-modal .modal-content button#cloudFileAdd:after,
  .contact-modal .modal-content .cloudModalFooter button:after {
    content: '';
    display: inline-block;
    width: 18px;
    height: 13px;
    background: url("/images/common/arrow-white.png") 50% 50% no-repeat;
    background-size: auto;
    background-size: 15px;
    margin-left: 10px;
    position: absolute;
    right: 10%;
    top: 50%;
    transform: translateY(-50%); }

.contact-h1 {
  padding-left: 0;
  padding-top: 0;
  position: relative;
  font-weight: 900;
  text-transform: none;
  padding-left: 40px;
  font-size: 42px !important;
  line-height: 61px;
  color: #333 !important;
  display: inline-block;
  letter-spacing: 2.5px;
  text-shadow: none;
  margin-bottom: 15px; }
  .contact-h1 .white-text {
    position: relative;
    color: #fff !important; }
  .contact-h1:before {
    content: '';
    position: absolute;
    height: 70px;
    width: 105px;
    right: inherit;
    left: 105px;
    top: 0; }
    @media (min-width: 992px) {
      .contact-h1:before {
        right: 0;
        top: 0;
        left: inherit; } }

.drop-p {
  font-size: 18px !important;
  line-height: 33px;
  padding-bottom: 20px;
  padding-top: 20px;
  text-align: left;
  color: #333 !important;
  font-family: "Montserrat",sans-serif;
  font-weight: 600;
  margin-bottom: 0; }

.new-contact .o-contact-us {
  /* media query for tablet landscape */ }
  @media (min-width: 992px) {
    .new-contact .o-contact-us {
      padding-top: 50px;
      padding-bottom: 20px; } }
  .new-contact .o-contact-us .subheading {
    font-weight: 600; }
  .new-contact .o-contact-us .office {
    margin-bottom: 30px; }
    .new-contact .o-contact-us .office .main-img {
      position: relative;
      vertical-align: top;
      margin-bottom: 20px; }
      .new-contact .o-contact-us .office .main-img img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
        @media (min-width: 992px) {
          .new-contact .o-contact-us .office .main-img img {
            height: auto; } }
      .new-contact .o-contact-us .office .main-img h3 {
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        font-size: 24px;
        position: absolute;
        left: 50%;
        top: 56%;
        transform: translate(-50%, -50%);
        height: 48px;
        color: #fff;
        /*text-shadow: 2px 2px #3e3d3d;*/ }
      .new-contact .o-contact-us .office .main-img .location-icon {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        position: absolute;
        top: 20px;
        right: 20px;
        margin: auto;
        z-index: 99;
        text-align: center;
        cursor: pointer;
        background: rgba(0, 0, 0, 0.2); }
        .new-contact .o-contact-us .office .main-img .location-icon img {
          width: 16px;
          height: auto;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%); }
        .new-contact .o-contact-us .office .main-img .location-icon:hover {
          background: rgba(255, 88, 0, 0.2); }
    .new-contact .o-contact-us .office ul {
      padding-bottom: 30px; }
      .new-contact .o-contact-us .office ul li {
        color: #000;
        font-size: 15px;
        font-weight: 300;
        margin-bottom: 0; }
        @media (min-width: 992px) {
          .new-contact .o-contact-us .office ul li {
            margin-bottom: 15px;
            font-size: 16px;
            margin: 0 0 25px 0; } }
        .new-contact .o-contact-us .office ul li img {
          margin-right: 10px;
          display: none;
          vertical-align: top;
          margin-top: 0; }
          @media (min-width: 992px) {
            .new-contact .o-contact-us .office ul li img {
              display: inline-block; } }
        .new-contact .o-contact-us .office ul li .mailicon {
          width: 15px;
          padding-top: 3px;
          opacity: 0.5; }
        .new-contact .o-contact-us .office ul li span {
          display: inline-block;
          width: 88%; }
        .new-contact .o-contact-us .office ul li a {
          color: #4492DC;
          cursor: pointer;
          font-family: "Montserrat", sans-serif; }
          @media (min-width: 992px) {
            .new-contact .o-contact-us .office ul li a {
              color: #333; } }
          .new-contact .o-contact-us .office ul li a.email {
            color: #4492DC; }
          .new-contact .o-contact-us .office ul li a:hover {
            text-decoration: underline; }

.new-contact .o-contact-form--white-bg input#phoneNumber {
  background: url(/images/common/icon-phone.png) 0 50% no-repeat; }

.new-contact .s-f-right input#phoneNumber {
  background-image: none; }

.new-contact .o-contact-form--white-bg input.pc-phone {
  padding-left: 40px !important; }

.new-contact .o-contact-us-new h2 {
  text-transform: none;
  padding-left: 40px;
  font-size: 42px !important;
  display: inline-block;
  padding-top: 30px; }

.new-contact .o-contact-us-new .subheading {
  font-size: 22px;
  line-height: 38px;
  padding-top: 30px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  padding-right: 0;
  padding-bottom: 75px; }
  @media (min-width: 992px) {
    .new-contact .o-contact-us-new .subheading {
      padding-bottom: 120px;
      padding-right: 125px; } }
  .new-contact .o-contact-us-new .subheading strong {
    font-weight: 700;
    color: #27c070; }

.new-contact .o-contact-us-new .o-contact-form--white-bg {
  background: url(/images/contact-us/dashed-line.png) 0 0 repeat-x;
  padding: 30px 0 50px !important;
  position: relative;
  background-color: #fff;
  box-shadow: none;
  border-bottom: 1px solid #EBEBEB; }
  @media (min-width: 992px) {
    .new-contact .o-contact-us-new .o-contact-form--white-bg {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
      border-bottom: 0;
      padding: 20px 0 40px !important; } }
  .new-contact .o-contact-us-new .o-contact-form--white-bg .form-text {
    position: relative;
    padding: 0 15px;
    text-align: left;
    margin-bottom: 0; }
    @media (min-width: 992px) {
      .new-contact .o-contact-us-new .o-contact-form--white-bg .form-text {
        text-align: center;
        padding: 0 0 20px 0;
        margin-bottom: 25px; } }
    .new-contact .o-contact-us-new .o-contact-form--white-bg .form-text:after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0;
      border-bottom: 1px solid #4A90E2;
      width: 200px;
      transform: translate(-50%, 0);
      width: 240px;
      display: none; }
      @media (min-width: 992px) {
        .new-contact .o-contact-us-new .o-contact-form--white-bg .form-text:after {
          display: block; } }

@media (min-width: 992px) {
  .new-contact .ywg {
    padding: 70px 0 20px; } }

.new-contact .ywg h2 {
  text-transform: uppercase;
  font-size: 40px; }

.new-contact .ywg ul li {
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  margin: 0 9px 55px 9px;
  vertical-align: top;
  font-weight: 500;
  /* media query for tablet landscape */ }
  @media screen and (min-width: 992px) {
    .new-contact .ywg ul li {
      width: 22%;
      display: inline-block;
      text-align: center; } }
  @media screen and (min-width: 1200px) {
    .new-contact .ywg ul li {
      width: 23%; } }
  .new-contact .ywg ul li img {
    float: none;
    margin: 0 auto;
    display: block;
    width: 50px; }
    @media (min-width: 992px) {
      .new-contact .ywg ul li img {
        width: auto; } }

.new-contact .addr-section {
  padding: 50px 0 0; }
  @media (min-width: 992px) {
    .new-contact .addr-section {
      padding: 75px 0 50px; } }

.new-contact .contact-addr {
  padding-bottom: 0; }
  @media (min-width: 992px) {
    .new-contact .contact-addr {
      padding-top: 60px;
      padding-bottom: 40px; } }
  .new-contact .contact-addr h2 {
    text-transform: inherit;
    font-size: 30px;
    font-weight: 900;
    margin-bottom: 35px;
    font-family: "Montserrat", sans-serif;
    position: relative;
    text-align: left; }
    @media (min-width: 992px) {
      .new-contact .contact-addr h2 {
        font-size: 48px;
        text-align: center;
        margin-bottom: 50px; } }
    .new-contact .contact-addr h2:before {
      content: '';
      position: absolute;
      background: #49C684;
      height: 2px;
      width: 50px;
      bottom: -15px;
      left: 0;
      display: block; }
      @media (min-width: 992px) {
        .new-contact .contact-addr h2:before {
          display: none;
          bottom: 0; } }
  .new-contact .contact-addr .office {
    box-shadow: none;
    overflow: hidden;
    margin-bottom: 40px;
    display: block;
    padding-bottom: 15px;
    margin-bottom: 15px; }
    @media (min-width: 992px) {
      .new-contact .contact-addr .office {
        display: block;
        padding: 0;
        margin: 0 0 40px;
        box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1);
        border-bottom: 0; } }
    .new-contact .contact-addr .office .main-img {
      margin-bottom: 0;
      width: 100%;
      height: auto;
      border-radius: 0;
      overflow: hidden; }
      @media (min-width: 992px) {
        .new-contact .contact-addr .office .main-img {
          width: auto;
          height: auto;
          border-radius: 0; } }
      .new-contact .contact-addr .office .main-img a {
        width: 100%;
        height: 100%;
        display: block; }
        .new-contact .contact-addr .office .main-img a img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
    .new-contact .contact-addr .office ul {
      padding-bottom: 10px;
      padding-top: 15px; }
      @media screen and (min-width: 992px) {
        .new-contact .contact-addr .office ul {
          padding: 15px 15px 10px; } }
      @media screen and (min-width: 1200px) {
        .new-contact .contact-addr .office ul {
          min-height: 241px; } }
      .new-contact .contact-addr .office ul li {
        padding-left: 0;
        font-weight: 500; }
    .new-contact .contact-addr .office .office-left {
      float: left;
      width: 100%;
      overflow: hidden;
      /* media query for tablet landscape */ }
      @media (min-width: 992px) {
        .new-contact .contact-addr .office .office-left {
          width: 32%;
          height: auto;
          border-radius: 0; } }
    .new-contact .contact-addr .office .office-right {
      float: right;
      width: 100%;
      /* media query for tablet landscape */
      margin-top: 20px; }
      @media (min-width: 992px) {
        .new-contact .contact-addr .office .office-right {
          width: 64%;
          padding: 30px 0 0 0;
          margin-top: 0; } }
      .new-contact .contact-addr .office .office-right .c-name {
        color: #000;
        font-weight: 500;
        font-size: 16px; }
      .new-contact .contact-addr .office .office-right p {
        font-size: 17px;
        font-weight: bold;
        margin-bottom: 15px; }
      .new-contact .contact-addr .office .office-right .addr {
        font-family: "Montserrat", sans-serif;
        font-size: 15px;
        line-height: 24px;
        font-weight: 500;
        float: left;
        width: 100%;
        background: none;
        padding-left: 0;
        /* media query for tablet landscape */
        margin-bottom: 0; }
        @media screen and (min-width: 992px) {
          .new-contact .contact-addr .office .office-right .addr {
            width: 46%;
            background: url(/images/contact-us/map.png) 0 5px no-repeat;
            padding-left: 40px;
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 15px; } }
        @media screen and (min-width: 1200px) {
          .new-contact .contact-addr .office .office-right .addr {
            width: 54%;
            font-size: 17px;
            line-height: 27px; } }
      .new-contact .contact-addr .office .office-right .contact-new {
        float: none;
        /* media query for tablet landscape */ }
        @media (min-width: 992px) {
          .new-contact .contact-addr .office .office-right .contact-new {
            margin-right: 50px;
            float: right; } }
        .new-contact .contact-addr .office .office-right .contact-new ul {
          padding: 0; }
          .new-contact .contact-addr .office .office-right .contact-new ul li {
            list-style-type: none;
            padding-left: 0;
            vertical-align: top;
            font-size: 15px;
            /* media query for tablet landscape */ }
            @media (min-width: 992px) {
              .new-contact .contact-addr .office .office-right .contact-new ul li {
                font-size: 16px; } }

.new-contact .blue-bg {
  background-color: #132a49;
  background-repeat: no-repeat;
  background-position: 0 100%; }
  .new-contact .blue-bg h2 {
    color: #fff;
    text-transform: inherit;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    margin-bottom: 60px; }
    @media (min-width: 992px) {
      .new-contact .blue-bg h2 {
        font-size: 48px; } }
  .new-contact .blue-bg li span {
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    margin-top: 0;
    display: flex;
    flex: 1;
    justify-content: flex-start;
    line-height: 24px;
    padding-left: 20px; }
    @media (min-width: 992px) {
      .new-contact .blue-bg li span {
        font-size: 18px;
        margin-top: 25px;
        display: block;
        padding-left: 0; } }

.new-contact .office-right p {
  font-family: "Montserrat", sans-serif;
  font-size: 14px; }
  .new-contact .office-right p:before {
    display: none; }

.new-contact .office h4 {
  font-size: 15px;
  margin-bottom: 5px;
  line-height: 27px; }
  @media (min-width: 992px) {
    .new-contact .office h4 {
      font-size: 17px;
      margin-bottom: 15px; } }

.new-contact .office p {
  position: relative;
  padding-left: 0;
  font-family: "Montserrat", sans-serif;
  margin: 0 0 0 0; }
  @media (min-width: 992px) {
    .new-contact .office p {
      padding-left: 35px;
      margin: 0 0 25px 0; } }
  .new-contact .office p:before {
    content: '';
    background: url(/images/contact-us/map.png) no-repeat 0 0;
    height: 50px;
    width: 30px;
    position: absolute;
    left: 0;
    top: 3px;
    background-size: 15px;
    display: none; }
    @media (min-width: 992px) {
      .new-contact .office p:before {
        display: block; } }
  .new-contact .office p br {
    display: none; }
    @media (min-width: 992px) {
      .new-contact .office p br {
        display: block; } }

.new-contact .office img {
  width: 22px;
  margin-right: 5px; }

.new-contact .office a:hover {
  text-decoration: none; }

.new-contact .o-contact-us-new h2 {
  padding-left: 0;
  padding-top: 0;
  position: relative;
  font-weight: 900;
  font-size: 40px; }
  .new-contact .o-contact-us-new h2:before {
    content: '';
    position: absolute;
    height: 70px;
    width: 105px;
    right: -6px;
    top: -7px; }
    @media (min-width: 992px) {
      .new-contact .o-contact-us-new h2:before {
        right: 0;
        top: 0; } }
  .new-contact .o-contact-us-new h2 span {
    position: relative; }
  .new-contact .o-contact-us-new h2 .white-text {
    color: #fff;
    text-shadow: none; }

.new-contact .o-contact-form {
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-top: 0; }

.new-contact .o-skills-hero__background.cntct-bnr {
  display: none; }
  @media (min-width: 992px) {
    .new-contact .o-skills-hero__background.cntct-bnr {
      display: block; } }

.new-contact .gd-compny {
  text-align: center !important;
  color: #9B9B9B !important;
  font-size: 16px !important;
  text-transform: uppercase; }

@media (min-width: 992px) {
  .new-contact .o-contact-form__contact-form {
    text-align: center; } }

.new-contact .o-contact-us {
  position: relative;
  top: 0;
  z-index: 9; }
  @media (min-width: 992px) {
    .new-contact .o-contact-us {
      top: 0; } }

.new-contact .grp-logo {
  display: block;
  margin: 0 auto; }
  @media (min-width: 992px) {
    .new-contact .grp-logo {
      display: inherit; } }

.new-contact .o-navigation-bar #primaryNav .request-quote {
  display: none !important; }

.logo-group {
  position: relative; }
  .logo-group:before {
    content: '';
    position: absolute;
    background: #f9f5f5;
    height: 100%;
    width: 1px;
    left: 50%; }
  .logo-group-sub {
    display: flex;
    border-bottom: 1px solid #f9f5f5;
    align-items: center; }
    .logo-group-sub-box {
      flex: 1;
      text-align: center;
      padding: 35px 15px; }
  .logo-group .no-border {
    border-bottom: 0; }

.cntct-bnr {
  position: absolute;
  height: 340px;
  display: none; }
  @media screen and (min-width: 992px) {
    .cntct-bnr {
      height: 390px;
      display: block; } }
  @media screen and (min-width: 1200px) {
    .cntct-bnr {
      height: 340px; } }

.contact-h1 {
  padding-left: 0;
  padding-top: 0;
  font-size: 36px !important;
  line-height: 61px;
  color: #333 !important;
  display: block;
  text-shadow: none;
  margin-bottom: 15px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  text-align: left; }
  @media (min-width: 992px) {
    .contact-h1 {
      display: inline-block;
      text-transform: capitalize;
      padding-top: 0;
      position: relative;
      font-weight: 900;
      font-size: 42px !important;
      letter-spacing: 2.5px; } }

.u-align-center {
  text-align: center; }

@media (min-width: 992px) {
  .cntct-pg-section {
    padding: 100px 20px 60px !important; } }

.u-submit.s-btn-modifier {
  display: block;
  text-align: center;
  border-radius: 5px;
  padding: 13px 0px;
  font-size: 14px;
  text-transform: uppercase;
  max-width: 350px;
  text-decoration: none;
  position: relative;
  font-family: "Montserrat",sans-serif;
  font-weight: 600;
  color: #fff;
  border: 2px solid #FF5800;
  background-color: #FF5800;
  width: 100%;
  margin: 25px auto 0; }
  @media (min-width: 992px) {
    .u-submit.s-btn-modifier {
      width: 478px;
      font-size: 18px;
      padding: 19px 0px;
      margin: 0 auto; } }

.modal-dialog {
  max-width: 600px !important; }

.quickform-holder .quickform .o-contact-form .o-contact-form--white-bg {
  padding: 0 20px 7px 20px;
  background-color: #fff; }

.quickform-holder .quickform .o-contact-form .o-contact-form--white-bg .un,
.quickform-holder .quickform .o-contact-form .o-contact-form--white-bg .ue,
.quickform-holder .quickform .o-contact-form .o-contact-form--white-bg .group {
  position: relative; }

.quickform-holder .quickform .o-contact-form .o-contact-form--white-bg .un:after,
.quickform-holder .quickform .o-contact-form .o-contact-form--white-bg .ue:after {
  border-bottom: 1px solid #C9C8C8;
  content: "";
  width: 100%;
  position: absolute;
  right: 0;
  bottom: 5px; }

.quickform-holder .quickform .o-contact-form .o-contact-form--white-bg .un input,
.quickform-holder .quickform .o-contact-form .o-contact-form--white-bg .ue input,
.quickform-holder .quickform .o-contact-form .o-contact-form--white-bg .group input {
  padding: 10px 0;
  background: transparent;
  width: 100%;
  border: 0; }

.quickform-holder .quickform .o-contact-form .o-contact-form--white-bg .c-btn--lg {
  margin: 0;
  border-radius: 5px;
  display: block;
  font-weight: 400;
  background: url(/images/common/contact-form-button.png) no-repeat 0 0;
  border: 0;
  padding-left: 60px;
  padding-bottom: 10px;
  background-size: 100%;
  padding: 2px 0 6px 35px;
  line-height: 0;
  font-size: 15px;
  width: 100%;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (min-width: 992px) {
    .quickform-holder .quickform .o-contact-form .o-contact-form--white-bg .c-btn--lg {
      height: 80px;
      font-size: 18px; } }

.o-thank-you__top {
  max-width: 850px;
  margin: 0 auto;
  padding: 50px 0px 30px;
  border-bottom: 1px solid #E9E9E9;
  text-align: center; }

body[data-page="thank-you"] .o-contact-form .o-contact-form--white-bg {
  max-width: 790px;
  margin: 60px auto;
  text-align: left;
  display: block;
  padding: 40px; }

body[data-page="thank-you"] .o-contact-form .o-contact-form--white-bg .fieldset-content {
  margin-bottom: 20px;
  padding: 10px; }

body[data-page="thank-you"] .o-contact-form .o-contact-form--white-bg .fieldset-content label {
  display: block;
  text-align: left;
  font-family: "Montserrat",sans-serif;
  font-weight: 300;
  color: #414446;
  font-size: 16px;
  line-height: 28px; }

body[data-page="thank-you"] .o-contact-form .o-contact-form--white-bg .fieldset-content label input {
  margin-right: 10px; }

body[data-page="thank-you"] .attach {
  background-image: none;
  padding: 0; }

body[data-page="thank-you"] .o-contact-form__attachement-text {
  font-size: 16px;
  display: block;
  color: #878787; }

.o-thank-you__top h3 {
  margin-bottom: 10px;
  font-size: 36px;
  line-height: 44px; }

.o-contact-form h5 {
  font-size: 26px;
  line-height: 35px;
  font-weight: 500; }

body[data-page="thank-you"] .u-name {
  background: url(/images/common/form-icon-1.png) 0 50% no-repeat;
  border: 0;
  border-bottom: 1px solid #C9C8C8;
  padding: 20px 20px 20px 40px;
  color: #333;
  width: 100%;
  margin-bottom: 10px;
  font-weight: 400; }

body[data-page="thank-you"] .u-email {
  background: url(/images/common/form-icon-2.png) 0 50% no-repeat;
  border: 0;
  border-bottom: 1px solid #C9C8C8;
  padding: 20px 20px 20px 40px;
  color: #333;
  width: 100%;
  margin-bottom: 10px;
  font-weight: 400; }

body[data-page="thank-you"] .u-phone {
  background: url(/images/common/form-icon-3.png) 0 50% no-repeat;
  border: 0;
  border-bottom: 1px solid #C9C8C8;
  padding: 20px 20px 20px 40px;
  color: #333;
  width: 100%;
  margin-bottom: 10px;
  font-weight: 400; }

body[data-page="thank-you"] .u-message textarea {
  background: url(/images/common/icon-message.svg) 0 20px no-repeat;
  padding: 20px 20px 20px 40px;
  color: #333;
  min-height: 130px;
  width: 100%;
  margin-bottom: 15px;
  border: 0; }

body[data-page="thank-you"] .group select {
  margin-top: 10px;
  width: 100%;
  padding: 10px;
  height: 50px;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #C9C8C8; }

body[data-page="thank-you"] .c-btn {
  display: block;
  outline: none;
  cursor: pointer;
  font-family: "Montserrat",sans-serif;
  font-weight: 600;
  color: #fff;
  background: #FF5800;
  border-radius: 4px;
  text-transform: uppercase;
  border: 2px solid #FF5800;
  line-height: 60px;
  font-size: 20px;
  padding: 0 24px;
  border-radius: 10px;
  width: auto;
  margin: 0 auto; }

input {
  outline: none; }

.mobile-off {
  display: none; }
  @media (min-width: 992px) {
    .mobile-off {
      display: block; } }

.cu-lets-talk {
  background: #091B38;
  padding: 96px 25px 45px;
  position: relative;
  text-align: left; }
  @media screen and (min-width: 992px) {
    .cu-lets-talk {
      padding: 45px 25px; } }
  .cu-lets-talk:before {
    content: '';
    position: absolute;
    top: 80px;
    right: 5px;
    background: url(/images/common/b-form-envelop.webp) no-repeat center center/100%;
    width: 159px;
    height: 159px; }
  .cu-lets-talk:after {
    content: '';
    background: #091B38;
    width: 25px;
    height: 25px;
    transform: rotate(-45deg);
    position: absolute;
    bottom: -12px;
    left: 35px;
    z-index: 9; }
  .cu-lets-talk h3 {
    color: #fff;
    font-weight: 700;
    font-size: 30px;
    font-family: "Montserrat", sans-serif; }
  .cu-lets-talk p {
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    padding-right: 40%;
    margin-bottom: 0; }
    .cu-lets-talk p a {
      color: #36E079; }

.row.bnr-form {
  padding: 0 25px; }
  @media (min-width: 992px) {
    .row.bnr-form {
      padding: 0; } }

.msgg-colo {
  padding: 0; }
  @media (min-width: 992px) {
    .msgg-colo {
      padding: 0 15px; } }

.mobile-off-inline {
  display: none; }
  @media (min-width: 992px) {
    .mobile-off-inline {
      display: inline-block; } }

.good-sec {
  padding: 30px 0 35px;
  border-bottom: 1px solid #dedede; }
  @media (min-width: 992px) {
    .good-sec {
      padding: 20px 0 35px; } }
  .good-sec h5 {
    text-align: center;
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    margin-bottom: 20px; }
  .good-sec ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 360px;
    margin: 0 auto; }
    .good-sec ul li {
      padding: 10px; }
  .good-sec ul li img {
    filter: grayscale(0.9); }

.ind-ofc {
  min-height: auto; }
  @media screen and (min-width: 992px) {
    .ind-ofc {
      height: auto; } }
  @media screen and (min-width: 1200px) {
    .ind-ofc {
      height: 282px; } }

.or-hd {
  font-size: 30px;
  margin-bottom: 30px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700; }

.logo-group-sub-box img, .logo-group-sub-box svg {
  height: 45px;
  filter: grayscale(0.9); }

.logo-group-sub-box .st-logo {
  height: 35px; }

.fieldset-content label {
  cursor: pointer;
  margin-bottom: 4px; }
  .fieldset-content label input {
    position: relative;
    top: 1px; }

.attachment-list li {
  margin-right: 0px !important;
  width: 100%; }
  .attachment-list li input {
    border-radius: 0px;
    width: calc(100% - 25px) !important; }
  .attachment-list li label {
    white-space: nowrap;
    width: calc(100% - 28px);
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 17px; }

.contact-thankyou-attachment li {
  border-radius: 30px;
  background: #fafafa;
  margin-bottom: 10px;
  padding: 8px 15px; }

.attach-icon {
  display: inline-block;
  background: url("/images/common/icon-attachment.svg") 0 0 no-repeat;
  width: 18px;
  height: 18px;
  margin-right: 8px;
  vertical-align: middle; }

.cloudModalFooter button {
  border: none !important; }

.disabledAttachButton button {
  pointer-events: none;
  background-color: #ebb79b !important;
  opacity: .9; }

.new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg {
  padding: 0 15px 7px 15px; }
  .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg input,
  .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg textarea {
    border-bottom: 1px solid #aaa !important; }
    .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg input::placeholder,
    .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg textarea::placeholder {
      color: #000;
      opacity: 1; }
  .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .un:after {
    display: none; }
  .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .un .u-name {
    background: url(/images/common/form-icon-1.png) 0 50% no-repeat; }
  .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .ue:after {
    display: none; }
  .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .ue .u-email {
    background: url(/images/common/form-icon-2.png) 0 50% no-repeat; }
  .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .new-b-a-call {
    background-image: none;
    background-color: #FC591F;
    border-radius: 11px;
    font-size: 14px;
    font-weight: 700;
    height: auto;
    line-height: 14px;
    padding: 13px 0; }
    @media screen and (min-width: 992px) {
      .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .new-b-a-call {
        padding: 20px 0;
        font-size: 17px; } }
  .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .cmn-left-pd-wrp input {
    padding: 12px 15px 12px 30px; }
    @media screen and (min-width: 1280px) {
      .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .cmn-left-pd-wrp input {
        padding: 15px 15px 15px 30px; } }
    @media screen and (min-width: 1400px) {
      .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .cmn-left-pd-wrp input {
        padding: 20px 15px 20px 30px; } }
    .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .cmn-left-pd-wrp input.u-phone {
      background: url(/images/common/form-icon-3.png) 0 50% no-repeat; }
  .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .attach {
    background-position: 0 10px;
    padding: 0 20px 5px 30px;
    text-align: left;
    margin-bottom: 10px;
    margin-top: 5px; }
  .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list .comp-attach,
  .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list .cloud-attach {
    margin-bottom: 10px; }
    .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list .comp-attach .attachedFileUrl,
    .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list .cloud-attach .attachedFileUrl {
      height: auto; }
    .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list .comp-attach .attachedFile,
    .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list .cloud-attach .attachedFile {
      border-bottom: none !important; }
  .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list li.cloud-attach,
  .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list li.styled-list {
    background: #f8f8f8;
    max-width: 100%;
    border-radius: 5px;
    position: relative;
    text-align: left;
    display: flex;
    align-items: center;
    overflow: hidden;
    height: 30px;
    border: 1px solid #d1d1d1; }
    .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list li.cloud-attach .removeAttachedFile2,
    .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list li.styled-list .removeAttachedFile2 {
      background: #f5f5f5;
      border: 0;
      margin-right: 0;
      padding: 0 0;
      position: absolute;
      right: 0;
      height: 100%;
      width: 50px;
      border-left: 1px solid #d1d1d1; }
      .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list li.cloud-attach .removeAttachedFile2 img,
      .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list li.styled-list .removeAttachedFile2 img {
        position: relative;
        top: 0;
        width: 15px; }
      .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list li.cloud-attach .removeAttachedFile2:hover,
      .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list li.styled-list .removeAttachedFile2:hover {
        background: #ff1c31; }
        .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list li.cloud-attach .removeAttachedFile2:hover img,
        .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list li.styled-list .removeAttachedFile2:hover img {
          filter: brightness(0) saturate(100%) invert(99%) sepia(1%) saturate(2410%) hue-rotate(122deg) brightness(117%) contrast(101%); }
    .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list li.cloud-attach .attachedFileUrl,
    .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list li.styled-list .attachedFileUrl {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 84%; }
    .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list li.cloud-attach span,
    .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list li.styled-list span {
      color: #333;
      font-weight: 400;
      font-size: 14px;
      flex: 0 0 60%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list li.cloud-attach i,
    .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list li.styled-list i {
      margin-right: 10px;
      background: #f5f5f5;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #d1d1d1;
      height: 100%; }
      .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list li.cloud-attach i img,
      .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list li.styled-list i img {
        width: 20px; }
  .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list .inp-wrapper {
    width: 0;
    height: 0;
    background: none;
    visibility: hidden;
    opacity: 0;
    padding: 0 !important;
    margin: 0 !important; }
    .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list .inp-wrapper .cloned {
      width: 0;
      height: 0;
      background: none;
      visibility: hidden;
      opacity: 0;
      padding: 0 !important;
      margin: 0 !important; }
  .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg textarea {
    padding: 12px 20px 15px 30px; }
    @media screen and (min-width: 1280px) {
      .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg textarea {
        padding: 15px 20px 20px 30px; } }
  @media screen and (min-width: 992px) {
    .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg {
      padding: 0 30px 7px 30px; } }

.new-quickform-holder .big-girl {
  width: 377px; }
  @media screen and (min-width: 992px) {
    .new-quickform-holder .big-girl {
      left: 0;
      top: 43px; } }

.new-quickform-holder .o-contact-form--white-bg {
  box-shadow: none; }

.new-quickform-holder .s-f-left {
  padding: 30px 0 15px; }
  @media screen and (min-width: 992px) {
    .new-quickform-holder .s-f-left {
      padding: 0; } }

.new-quickform-holder .add-computer-file-new .comp-file {
  position: absolute;
  right: -9999px;
  visibility: hidden;
  opacity: 0; }

.o-bottom-form .url-list,
.o-contact-form-contact-us .url-list {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  .o-bottom-form .url-list li,
  .o-contact-form-contact-us .url-list li {
    text-align: left; }
  .o-bottom-form .url-list label,
  .o-contact-form-contact-us .url-list label {
    color: #000;
    font-size: 13px !important; }
  .o-bottom-form .url-list.add-height,
  .o-contact-form-contact-us .url-list.add-height {
    overflow-y: auto;
    scroll-behavior: smooth; }
    @media screen and (min-width: 992px) {
      .o-bottom-form .url-list.add-height,
      .o-contact-form-contact-us .url-list.add-height {
        height: 65px; } }
    @media screen and (min-width: 1280px) {
      .o-bottom-form .url-list.add-height,
      .o-contact-form-contact-us .url-list.add-height {
        height: 81px; } }
    @media screen and (min-width: 1400px) {
      .o-bottom-form .url-list.add-height,
      .o-contact-form-contact-us .url-list.add-height {
        height: 115px; } }
    @media screen and (min-width: 1800px) {
      .o-bottom-form .url-list.add-height,
      .o-contact-form-contact-us .url-list.add-height {
        height: 115px; } }
  .o-bottom-form .url-list::-webkit-scrollbar,
  .o-contact-form-contact-us .url-list::-webkit-scrollbar {
    width: 5px; }
  .o-bottom-form .url-list::-webkit-scrollbar-track,
  .o-contact-form-contact-us .url-list::-webkit-scrollbar-track {
    background: #f1f1f1; }
  .o-bottom-form .url-list::-webkit-scrollbar-thumb,
  .o-contact-form-contact-us .url-list::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px; }
  .o-bottom-form .url-list::-webkit-scrollbar-thumb:hover,
  .o-contact-form-contact-us .url-list::-webkit-scrollbar-thumb:hover {
    background: #555; }

.o-bottom-form .attach,
.o-contact-form-contact-us .attach {
  background-position: 0 10px;
  padding: 0 20px 5px 30px;
  text-align: left;
  margin-bottom: 10px;
  margin-top: 5px; }

.o-bottom-form .url-list .comp-attach,
.o-bottom-form .url-list .cloud-attach,
.o-contact-form-contact-us .url-list .comp-attach,
.o-contact-form-contact-us .url-list .cloud-attach {
  margin-bottom: 10px; }
  .o-bottom-form .url-list .comp-attach .attachedFileUrl,
  .o-bottom-form .url-list .cloud-attach .attachedFileUrl,
  .o-contact-form-contact-us .url-list .comp-attach .attachedFileUrl,
  .o-contact-form-contact-us .url-list .cloud-attach .attachedFileUrl {
    height: auto; }
  .o-bottom-form .url-list .comp-attach .attachedFile,
  .o-bottom-form .url-list .cloud-attach .attachedFile,
  .o-contact-form-contact-us .url-list .comp-attach .attachedFile,
  .o-contact-form-contact-us .url-list .cloud-attach .attachedFile {
    border-bottom: none !important; }

.o-bottom-form .url-list li.cloud-attach,
.o-bottom-form .url-list li.styled-list,
.o-contact-form-contact-us .url-list li.cloud-attach,
.o-contact-form-contact-us .url-list li.styled-list {
  background: #f8f8f8;
  max-width: 100%;
  border-radius: 5px;
  position: relative;
  text-align: left;
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 35px;
  border: 1px solid #d1d1d1; }
  .o-bottom-form .url-list li.cloud-attach .removeAttachedFile2,
  .o-bottom-form .url-list li.styled-list .removeAttachedFile2,
  .o-contact-form-contact-us .url-list li.cloud-attach .removeAttachedFile2,
  .o-contact-form-contact-us .url-list li.styled-list .removeAttachedFile2 {
    background: #f5f5f5;
    border: 0;
    margin-right: 0;
    padding: 0 0;
    position: absolute;
    right: 0;
    height: 100%;
    width: 50px;
    border-left: 1px solid #d1d1d1; }
    .o-bottom-form .url-list li.cloud-attach .removeAttachedFile2 img,
    .o-bottom-form .url-list li.styled-list .removeAttachedFile2 img,
    .o-contact-form-contact-us .url-list li.cloud-attach .removeAttachedFile2 img,
    .o-contact-form-contact-us .url-list li.styled-list .removeAttachedFile2 img {
      position: relative;
      top: 0;
      width: 15px; }
    .o-bottom-form .url-list li.cloud-attach .removeAttachedFile2:hover,
    .o-bottom-form .url-list li.styled-list .removeAttachedFile2:hover,
    .o-contact-form-contact-us .url-list li.cloud-attach .removeAttachedFile2:hover,
    .o-contact-form-contact-us .url-list li.styled-list .removeAttachedFile2:hover {
      background: #ff1c31; }
      .o-bottom-form .url-list li.cloud-attach .removeAttachedFile2:hover img,
      .o-bottom-form .url-list li.styled-list .removeAttachedFile2:hover img,
      .o-contact-form-contact-us .url-list li.cloud-attach .removeAttachedFile2:hover img,
      .o-contact-form-contact-us .url-list li.styled-list .removeAttachedFile2:hover img {
        filter: brightness(0) saturate(100%) invert(99%) sepia(1%) saturate(2410%) hue-rotate(122deg) brightness(117%) contrast(101%); }
  .o-bottom-form .url-list li.cloud-attach .attachedFileUrl,
  .o-bottom-form .url-list li.styled-list .attachedFileUrl,
  .o-contact-form-contact-us .url-list li.cloud-attach .attachedFileUrl,
  .o-contact-form-contact-us .url-list li.styled-list .attachedFileUrl {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 84%; }
  .o-bottom-form .url-list li.cloud-attach span,
  .o-bottom-form .url-list li.styled-list span,
  .o-contact-form-contact-us .url-list li.cloud-attach span,
  .o-contact-form-contact-us .url-list li.styled-list span {
    color: #333;
    font-weight: 400;
    font-size: 14px;
    flex: 0 0 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .o-bottom-form .url-list li.cloud-attach i,
  .o-bottom-form .url-list li.styled-list i,
  .o-contact-form-contact-us .url-list li.cloud-attach i,
  .o-contact-form-contact-us .url-list li.styled-list i {
    margin-right: 10px;
    background: #f5f5f5;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #d1d1d1;
    height: 100%; }
    .o-bottom-form .url-list li.cloud-attach i img,
    .o-bottom-form .url-list li.styled-list i img,
    .o-contact-form-contact-us .url-list li.cloud-attach i img,
    .o-contact-form-contact-us .url-list li.styled-list i img {
      width: 20px; }

.o-bottom-form .url-list .inp-wrapper,
.o-contact-form-contact-us .url-list .inp-wrapper {
  width: 0;
  height: 0;
  background: none;
  visibility: hidden;
  opacity: 0;
  padding: 0 !important;
  margin: 0 !important; }
  .o-bottom-form .url-list .inp-wrapper .cloned,
  .o-contact-form-contact-us .url-list .inp-wrapper .cloned {
    width: 0;
    height: 0;
    background: none;
    visibility: hidden;
    opacity: 0;
    padding: 0 !important;
    margin: 0 !important; }

.o-contact-form-contact-us .url-list li.cloud-attach span {
  flex: 0 0 70%; }

.attachedFile {
  width: 0;
  height: 0;
  padding: 0;
  visibility: hidden;
  position: absolute;
  pointer-events: none;
  right: -999999px; }

.navbar-toggler {
  display: block; }
  @media screen and (min-width: 992px) {
    .navbar-toggler {
      display: none; } }

.new-nav__wrapper {
  position: fixed;
  right: -100%;
  height: calc(100vh - 72px);
  top: 72px;
  overflow-y: auto;
  overflow-x: hidden;
  transition: 0.4s all ease;
  background: #24cd7b;
  background: linear-gradient(280deg, #24cd7b 0%, #355fd5 70%);
  padding: 15px 15px 0; }
  @media screen and (min-width: 992px) {
    .new-nav__wrapper {
      display: block;
      position: static;
      left: inherit;
      right: inherit;
      width: auto;
      height: auto;
      transition: none;
      padding: 0;
      background: none;
      overflow: visible; } }
  .new-nav__wrapper.show {
    position: absolute;
    width: 100vw;
    right: 0; }

.new-nav__list {
  position: relative;
  display: block; }
  @media screen and (min-width: 992px) {
    .new-nav__list {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-left: 35px; } }

.new-nav__child-wrapper {
  display: none;
  margin-top: 15px;
  padding-left: 15px;
  padding-right: 15px; }
  @media screen and (min-width: 992px) {
    .new-nav__child-wrapper {
      position: absolute;
      top: 81px;
      left: 0;
      width: 334px;
      transition: 0.3s all ease;
      transform: translate(0px, 10px);
      visibility: hidden;
      opacity: 0;
      display: block;
      background: #fff;
      box-shadow: 1px 0 13px 7px rgba(0, 0, 0, 0.1);
      margin-top: 0;
      padding-left: 0;
      padding-right: 0; } }
  .new-nav__child-wrapper.showNav {
    display: block; }
  @media screen and (min-width: 992px) {
    .new-nav__child-wrapper:before {
      content: "";
      position: absolute;
      height: 10px;
      top: -10px;
      left: 0;
      right: 0;
      width: 100%; } }

.new-nav__items {
  padding: 10px 0;
  margin: 0;
  position: relative; }
  @media screen and (min-width: 992px) {
    .new-nav__items {
      margin: 0 10px;
      padding: 24px 0; } }
  .new-nav__items:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    background: #29ad95;
    width: 100%;
    height: 4px;
    visibility: hidden;
    opacity: 0;
    transform: scale(1.2);
    transition: 0.3s all ease-in-out; }
  @media screen and (min-width: 992px) {
    .new-nav__items:hover .new-nav__child-wrapper {
      visibility: visible;
      opacity: 1;
      transform: translate(0); } }
  @media screen and (min-width: 992px) {
    .new-nav__items:hover:before {
      transform: scale(1);
      visibility: visible;
      opacity: 1; } }
  .new-nav__items:hover .new-nav__links {
    color: #fff; }
    @media screen and (min-width: 992px) {
      .new-nav__items:hover .new-nav__links {
        color: #35b15c; } }
  .new-nav__items:hover .new-nav__arrow path {
    stroke: #35b15c; }
  @media screen and (min-width: 992px) {
    .new-nav__items--left-gap {
      padding-left: 12px;
      margin-right: 10px; } }
  @media screen and (min-width: 1200px) {
    .new-nav__items--left-gap {
      padding-left: 20px; } }
  @media screen and (min-width: 992px) {
    .new-nav__items--left-gap::before {
      left: 11px;
      width: 79px; } }
  @media screen and (min-width: 1200px) {
    .new-nav__items--left-gap::before {
      left: 11px;
      width: 75px; } }
  .new-nav__items--left-gap:hover:before {
    display: none; }
  @media screen and (min-width: 992px) {
    .new-nav__items--left-gap:hover .new-nav__links--left-bar {
      background-size: 100% 100%; } }

.new-nav__links {
  font-family: "Poppins";
  font-size: 21px;
  font-weight: 600;
  color: #fff;
  transition: 0.2s all ease;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media screen and (min-width: 992px) {
    .new-nav__links {
      color: #333;
      font-weight: 500;
      font-size: 13px;
      position: static;
      display: inherit; } }
  @media screen and (min-width: 1200px) {
    .new-nav__links {
      font-size: 16px; } }
  .new-nav__links span {
    font-size: 28px;
    font-weight: 300;
    flex: 0 0 30px;
    height: 30px;
    width: 30px;
    color: #fff;
    border-radius: 50%;
    border: #fff 1px solid;
    line-height: 30px;
    text-align: center;
    margin-right: 0; }

.new-nav__arrow {
  margin-left: 5px; }

.new-nav__child-items {
  position: relative; }
  @media screen and (min-width: 992px) {
    .new-nav__child-items {
      padding: 0; } }
  @media screen and (min-width: 992px) {
    .new-nav__child-items:hover {
      background: #24cd7b;
      background: linear-gradient(120deg, #24cd7b 0%, #355fd5 60%); } }
  .new-nav__child-items:hover .new-nav__child-links {
    color: #fff; }
    @media screen and (min-width: 992px) {
      .new-nav__child-items:hover .new-nav__child-links:after {
        border: none; } }
  .new-nav__child-items:hover path {
    stroke: #fff; }
  @media screen and (min-width: 992px) {
    .new-nav__child-items:hover .new-nav__child-wrapper-second {
      visibility: visible;
      opacity: 1;
      transform: translate(0); } }
  .new-nav__child-items svg {
    position: absolute;
    display: none; }
    @media screen and (min-width: 992px) {
      .new-nav__child-items svg {
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        display: block; } }
    @media screen and (min-width: 992px) {
      .new-nav__child-items svg.before-link {
        position: relative;
        top: -1px;
        right: 0;
        transform: none;
        display: inline-block;
        margin-right: 5px; } }
  .new-nav__child-items:last-child .new-nav__child-links:after {
    display: block; }
    @media screen and (min-width: 992px) {
      .new-nav__child-items:last-child .new-nav__child-links:after {
        display: none; } }
  .new-nav__child-items--top-space {
    margin-top: 15px; }
    @media screen and (min-width: 992px) {
      .new-nav__child-items--top-space {
        margin-top: 0; } }

.new-nav__child-links {
  font-family: "Poppins" !important;
  display: block;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  position: relative;
  padding: 5px 0 5px; }
  @media screen and (min-width: 992px) {
    .new-nav__child-links {
      color: #333;
      font-size: 16px;
      font-weight: 600;
      padding: 16px 30px;
      transition: none; } }
  .new-nav__child-links:after {
    content: '';
    position: absolute;
    top: 13px;
    left: -11px;
    background: url(/images/common/white-right-bracket.svg) no-repeat 0 0;
    width: 6px;
    height: 10px; }
    @media screen and (min-width: 992px) {
      .new-nav__child-links:after {
        content: "";
        position: absolute;
        top: inherit;
        bottom: -1px;
        left: 0;
        right: 0;
        width: 82%;
        height: 1px;
        margin: 0 auto;
        border-bottom: 1px dashed #d9d9d9;
        background: none; } }
  .new-nav__child-links--head {
    margin-bottom: 10px;
    font-weight: 500; }
    @media screen and (min-width: 992px) {
      .new-nav__child-links--head {
        margin-bottom: 0;
        font-weight: 600; } }
    .new-nav__child-links--head:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      background: #14df7d;
      width: 35px;
      height: 2px;
      display: block; }
      @media screen and (min-width: 992px) {
        .new-nav__child-links--head:before {
          display: none; } }

.new-nav__child-wrapper-second {
  background: none;
  padding: 0;
  position: relative; }
  @media screen and (min-width: 992px) {
    .new-nav__child-wrapper-second {
      position: absolute;
      top: 0;
      right: -303px;
      width: 303px;
      transition: 0.3s all ease;
      transform: translate(-30px, 0);
      visibility: hidden;
      opacity: 0;
      background: #355fd5;
      padding: 10px 0; } }
  .new-nav__child-wrapper-second--seperator {
    padding-bottom: 15px; }
    @media screen and (min-width: 992px) {
      .new-nav__child-wrapper-second--seperator {
        padding: 10px 0;
        margin-bottom: 0; } }
    .new-nav__child-wrapper-second--seperator:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 100%;
      background: #fff;
      display: none; }
      @media screen and (min-width: 992px) {
        .new-nav__child-wrapper-second--seperator:before {
          display: none; } }
  .new-nav__child-wrapper-second:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 90%;
    background: #fff;
    display: block; }
    @media screen and (min-width: 992px) {
      .new-nav__child-wrapper-second:after {
        display: none; } }
  .new-nav__child-wrapper-second--tree1:after {
    height: 78%; }
  .new-nav__child-wrapper-second--tree2:after {
    height: 74%; }

.new-nav__child-items-second {
  padding: 0 0 0 20px;
  position: relative; }
  @media screen and (min-width: 992px) {
    .new-nav__child-items-second {
      padding: 1px 15px; } }
  @media screen and (min-width: 992px) {
    .new-nav__child-items-second:hover .new-nav__child-links-second {
      color: #fff;
      background: #26459b; } }
  .new-nav__child-items-second:before {
    content: '';
    position: absolute;
    top: 15px;
    left: 0;
    width: 15px;
    height: 1px;
    background: #fff;
    display: block; }
    @media screen and (min-width: 992px) {
      .new-nav__child-items-second:before {
        display: none; } }

.new-nav__child-links-second {
  font-family: "Poppins" !important;
  display: block;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  position: relative;
  background: none;
  border-radius: 4px;
  padding: 5px 0;
  transition: 0.2s all linear; }
  @media screen and (min-width: 992px) {
    .new-nav__child-links-second {
      padding: 8px 15px;
      font-weight: 500; } }

.overflow-y-hidden {
  overflow-y: hidden; }

@keyframes animate {
  0% {
    background-position: 400%; }
  100% {
    background-position: 0%; } }

.new-home-banner {
  position: relative;
  background-color: #0d2642;
  padding: 25px 0 40px; }
  @media screen and (min-width: 992px) {
    .new-home-banner {
      background-image: url(/images/new-home-banner/banner-gradient.png);
      background-repeat: no-repeat;
      background-position: 0 0;
      padding: 60px 0 160px; } }
  @media screen and (min-width: 992px) {
    .new-home-banner:after {
      content: "";
      width: 453px;
      height: 199px;
      background-image: url(/images/new-home-banner/banner-gradient2.png);
      background-repeat: no-repeat;
      background-position: 0 0;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 0; } }
  .new-home-banner__wrapper {
    position: relative; }
    @media screen and (min-width: 992px) {
      .new-home-banner__wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap; } }
    @media screen and (min-width: 992px) {
      .new-home-banner__wrapper--out {
        align-items: center; } }
    .new-home-banner__wrapper--leap-mwc:before {
      content: '';
      position: absolute;
      background-image: url(/images/new-home-banner/leap-mwc-gradiant.svg);
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 100%;
      left: 140px;
      bottom: -235px;
      width: 854px;
      height: 685px;
      display: none; }
      @media screen and (min-width: 992px) {
        .new-home-banner__wrapper--leap-mwc:before {
          display: block; } }
  .new-home-banner__left {
    flex: 0 0 69%;
    position: relative; }
    @media screen and (min-width: 992px) {
      .new-home-banner__left {
        flex: 0 0 65%;
        padding-right: 80px; } }
    @media screen and (min-width: 1025px) {
      .new-home-banner__left {
        flex: 0 0 69%; } }
    .new-home-banner__left::before {
      display: none; }
      @media screen and (min-width: 992px) {
        .new-home-banner__left::before {
          content: "";
          position: absolute;
          top: 5px;
          right: 5px;
          width: 2px;
          height: 565px;
          background: url(/images/new-home-banner/banner-devider2.png) no-repeat 0 0;
          display: block; } }
    @media screen and (min-width: 992px) {
      .new-home-banner__left--leap-mwc {
        padding-top: 20px; } }
    .new-home-banner__left--leap-mwc:before {
      display: none; }
    @media screen and (min-width: 992px) {
      .new-home-banner__left--leap-mwc {
        flex: 0 0 58%;
        padding-right: 25px; } }
    @media screen and (min-width: 1025px) {
      .new-home-banner__left--leap-mwc {
        flex: 0 0 60%; } }
    .new-home-banner__left--leap-mwc .new-home-banner__sub-heading-white {
      margin-top: 15px; }
      @media screen and (min-width: 992px) {
        .new-home-banner__left--leap-mwc .new-home-banner__sub-heading-white {
          font-size: 58px;
          line-height: 68px;
          margin-top: 0;
          margin-bottom: 25px; } }
      .new-home-banner__left--leap-mwc .new-home-banner__sub-heading-white .new-home-banner__sub-heading-green {
        color: #3CC065; }
    .new-home-banner__left--leap-mwc .new-home-banner__blue-para {
      color: #97B3CE;
      font-weight: 500;
      font-family: Poppins;
      text-align: center; }
      @media screen and (min-width: 992px) {
        .new-home-banner__left--leap-mwc .new-home-banner__blue-para {
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 35px;
          text-align: left; } }
    .new-home-banner__left--leap-mwc .new-home-banner__btn {
      margin-left: 0; }
    @media screen and (min-width: 992px) {
      .new-home-banner__left--out {
        flex: 1; } }
    .new-home-banner__left--out:before {
      display: none; }
  .new-home-banner__right {
    flex: 1; }
    @media screen and (min-width: 992px) {
      .new-home-banner__right {
        padding-left: 65px; } }
    .new-home-banner__right--out {
      padding: 20px 15px;
      border: 1px solid #355BBF;
      background: #04254A;
      text-align: center;
      margin-top: 20px; }
      @media screen and (min-width: 992px) {
        .new-home-banner__right--out {
          padding: 50px;
          flex: 0 0 482px;
          text-align: left;
          margin-top: 0; } }
    .new-home-banner__right--leap-mwc {
      padding: 25px 15px;
      margin-top: 20px;
      position: relative; }
      @media screen and (min-width: 992px) {
        .new-home-banner__right--leap-mwc {
          padding: 15px 15px;
          position: relative;
          margin-top: 0; } }
      .new-home-banner__right--leap-mwc .new-home-banner__right-deatils {
        top: 0; }
      .new-home-banner__right--leap-mwc .new-home-banner__right-deatils {
        font-weight: 700; }
    .new-home-banner__right--leap-mwc-top {
      background: rgba(0, 0, 0, 0.7);
      padding: 25px;
      margin-bottom: 20px; }
      .new-home-banner__right--leap-mwc-top p {
        margin-bottom: 0; }
      .new-home-banner__right--leap-mwc-top .date {
        font-family: Poppins;
        background: #DE002B;
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        color: #fff;
        display: block;
        width: 100%;
        text-align: center;
        border-top: 1px solid #fff; }
    .new-home-banner__right--leap-mwc-top-box {
      display: flex;
      flex-direction: column;
      border: 2px solid #fff; }
    .new-home-banner__right--leap-mwc-bottom-box {
      display: flex;
      flex-direction: column;
      border: 2px solid #fff; }
    .new-home-banner__right--leap-mwc-bottom {
      background: rgba(0, 0, 0, 0.7);
      padding: 25px; }
      .new-home-banner__right--leap-mwc-bottom p {
        margin-top: 10px;
        margin-bottom: 0;
        font-family: Poppins;
        font-size: 16px;
        line-height: 26px;
        font-weight: 500;
        color: #fff; }
      .new-home-banner__right--leap-mwc-bottom .date {
        font-family: Poppins;
        background: #007D9F;
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        color: #fff;
        display: block;
        width: 100%;
        text-align: center;
        border-top: 1px solid #fff; }
    .new-home-banner__right--leap-mwc-top {
      background: rgba(0, 0, 0, 0.7);
      border: 1px solid rgba(101, 115, 132, 0.62);
      padding: 25px 25px 20px; }
      .new-home-banner__right--leap-mwc-top p {
        margin-top: 10px;
        margin-bottom: 0;
        font-family: Poppins;
        font-size: 16px;
        line-height: 26px;
        font-weight: 500;
        color: #fff;
        text-align: center; }
        @media screen and (min-width: 992px) {
          .new-home-banner__right--leap-mwc-top p {
            text-align: left;
            font-size: 18px; } }
    .new-home-banner__right--leap-mwc-top-box-leap {
      width: 100%;
      display: flex;
      align-items: center; }
    .new-home-banner__right--leap-mwc-top-box-leap-left {
      flex: 1;
      position: relative;
      background-color: #fff; }
      .new-home-banner__right--leap-mwc-top-box-leap-left:before {
        position: absolute;
        content: "";
        width: 80%;
        height: 80%;
        background-color: #000;
        left: 0;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        margin: auto; }
      .new-home-banner__right--leap-mwc-top-box-leap-left img {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .new-home-banner__right--leap-mwc-top-box-leap-right {
      flex: 1;
      background: #0C0C68;
      align-self: stretch;
      display: flex;
      align-items: center;
      justify-content: center; }
      .new-home-banner__right--leap-mwc-top-box-leap-right span {
        font-family: Poppins;
        font-weight: 800;
        color: #fff; }
        @media screen and (min-width: 992px) {
          .new-home-banner__right--leap-mwc-top-box-leap-right span {
            font-size: 34px; } }
    .new-home-banner__right--leap-mwc-bottom {
      background: rgba(0, 0, 0, 0.7);
      padding: 25px 25px 20px;
      border: 1px solid rgba(101, 115, 132, 0.62); }
      .new-home-banner__right--leap-mwc-bottom p {
        margin-top: 10px;
        margin-bottom: 0;
        font-family: Poppins;
        font-size: 16px;
        line-height: 26px;
        font-weight: 500;
        color: #fff;
        text-align: center; }
        @media screen and (min-width: 992px) {
          .new-home-banner__right--leap-mwc-bottom p {
            text-align: left;
            font-size: 18px; } }
    .new-home-banner__right--leap-mwc-bottom-box-leap {
      width: 100%;
      display: flex;
      align-items: center; }
    .new-home-banner__right--leap-mwc-bottom-box-leap-left {
      position: relative;
      flex: 1;
      background-color: #fff; }
      .new-home-banner__right--leap-mwc-bottom-box-leap-left:before {
        position: absolute;
        content: "";
        width: 80%;
        height: 80%;
        background-color: #000;
        left: 0;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        margin: auto; }
      .new-home-banner__right--leap-mwc-bottom-box-leap-left img {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .new-home-banner__right--leap-mwc-bottom-box-leap-right {
      flex: 1;
      background: #22569D;
      align-self: stretch;
      display: flex;
      align-items: center;
      justify-content: center; }
      .new-home-banner__right--leap-mwc-bottom-box-leap-right span {
        font-family: Poppins;
        font-weight: 800;
        color: #fff;
        text-align: center; }
        @media screen and (min-width: 992px) {
          .new-home-banner__right--leap-mwc-bottom-box-leap-right span {
            font-size: 24px; } }
        .new-home-banner__right--leap-mwc-bottom-box-leap-right span small {
          font-size: 19px;
          display: block; }
    .new-home-banner__right--convergence-gitex {
      position: relative; }
      .new-home-banner__right--convergence-gitex .new-home-banner__right--leap-mwc-top-box-leap-left {
        padding: 12px 17px; }
        .new-home-banner__right--convergence-gitex .new-home-banner__right--leap-mwc-top-box-leap-left::before {
          display: none; }
        .new-home-banner__right--convergence-gitex .new-home-banner__right--leap-mwc-top-box-leap-left img {
          width: inherit;
          height: inherit;
          object-fit: initial; }
      .new-home-banner__right--convergence-gitex .new-home-banner__right--leap-mwc-bottom-box-leap-right {
        background: #0E1488; }
        .new-home-banner__right--convergence-gitex .new-home-banner__right--leap-mwc-bottom-box-leap-right.bg-darkgreen {
          background: #00666C; }
      .new-home-banner__right--convergence-gitex .new-home-banner__right--leap-mwc-top .date {
        background: #F70026;
        padding: 6px 0;
        font-weight: 700; }
        @media screen and (min-width: 992px) {
          .new-home-banner__right--convergence-gitex .new-home-banner__right--leap-mwc-top .date {
            font-size: 21px; } }
      .new-home-banner__right--convergence-gitex .new-home-banner__right--leap-mwc-bottom-box-leap-right span {
        font-size: 20px;
        font-weight: 600; }
        @media screen and (min-width: 992px) {
          .new-home-banner__right--convergence-gitex .new-home-banner__right--leap-mwc-bottom-box-leap-right span {
            font-size: 28px;
            line-height: 30px;
            font-weight: 800; } }
        .new-home-banner__right--convergence-gitex .new-home-banner__right--leap-mwc-bottom-box-leap-right span small {
          display: block;
          font-size: 16px;
          font-weight: 600; }
          @media screen and (min-width: 992px) {
            .new-home-banner__right--convergence-gitex .new-home-banner__right--leap-mwc-bottom-box-leap-right span small {
              font-size: 20px;
              font-weight: 800; } }
      .new-home-banner__right--convergence-gitex .new-home-banner__right--leap-mwc-bottom-box-leap-left {
        padding: 10px 0; }
        .new-home-banner__right--convergence-gitex .new-home-banner__right--leap-mwc-bottom-box-leap-left::before {
          display: none; }
        .new-home-banner__right--convergence-gitex .new-home-banner__right--leap-mwc-bottom-box-leap-left img {
          width: inherit;
          height: inherit;
          object-fit: initial; }
      .new-home-banner__right--convergence-gitex .new-home-banner__right--leap-mwc-bottom .date {
        padding: 6px 0;
        font-weight: 700; }
        @media screen and (min-width: 992px) {
          .new-home-banner__right--convergence-gitex .new-home-banner__right--leap-mwc-bottom .date {
            font-size: 21px; } }
  .new-home-banner__heading {
    color: #fff;
    font-weight: 700;
    font-family: "Poppins" !important;
    text-align: center;
    font-size: 30px;
    line-height: 38px;
    position: relative;
    padding-bottom: 20px; }
    @media screen and (min-width: 992px) {
      .new-home-banner__heading {
        font-size: 56px;
        line-height: 68px;
        text-align: left;
        padding-bottom: 30px; } }
    @media screen and (min-width: 992px) {
      .new-home-banner__heading:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        background: #91accd;
        height: 2px;
        width: 265px; } }
    .new-home-banner__heading--leap-mwc {
      padding-bottom: 0;
      font-size: 26px;
      line-height: normal;
      margin-bottom: 0; }
      @media screen and (min-width: 992px) {
        .new-home-banner__heading--leap-mwc {
          font-size: 34px;
          line-height: 50px;
          font-weight: 600;
          margin-bottom: 10px; } }
      .new-home-banner__heading--leap-mwc:before {
        content: '';
        position: absolute;
        background: #84A5F9;
        width: 168px;
        height: 5px;
        display: none; }
        @media screen and (min-width: 992px) {
          .new-home-banner__heading--leap-mwc:before {
            display: block; } }
    .new-home-banner__heading--leap-mwc:before {
      display: none; }
    .new-home-banner__heading--out {
      position: relative; }
      @media screen and (min-width: 992px) {
        .new-home-banner__heading--out {
          font-size: 31px;
          line-height: 40px;
          font-weight: 700; } }
      .new-home-banner__heading--out:before {
        content: '';
        position: absolute;
        background: #84A5F9;
        width: 168px;
        height: 5px;
        display: none; }
        @media screen and (min-width: 992px) {
          .new-home-banner__heading--out:before {
            display: block; } }
    .new-home-banner__heading--green-out {
      color: #2DBE70; }
  .new-home-banner__arrow {
    position: absolute;
    top: -86px;
    right: -76px; }
  .new-home-banner__sub-heading {
    color: #3cc065;
    font-family: "Poppins" !important; }
    @media screen and (min-width: 992px) {
      .new-home-banner__sub-heading {
        font-size: 31px;
        line-height: 40px;
        font-weight: 600; } }
  .new-home-banner__sub-heading-white {
    color: #fff;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .new-home-banner__sub-heading-white {
        font-size: 60px;
        line-height: 68px;
        font-weight: 700;
        margin-top: 35px;
        text-align: left; } }
  .new-home-banner__right-heading {
    font-family: "Poppins" !important;
    font-size: 17px;
    line-height: 24px;
    font-weight: 600;
    color: #3cc065;
    margin-top: 25px;
    margin-bottom: 20px;
    text-align: center;
    display: block; }
    @media screen and (min-width: 992px) {
      .new-home-banner__right-heading {
        text-align: left;
        font-size: 26px;
        line-height: 42px;
        color: #fff;
        margin-top: 0; } }
    .new-home-banner__right-heading .banner-line-break li {
      display: inline-block;
      margin-right: 4px; }
      .new-home-banner__right-heading .banner-line-break li:last-child {
        margin-right: 0px; }
      @media screen and (min-width: 992px) {
        .new-home-banner__right-heading .banner-line-break li {
          display: block;
          margin-right: 0px; } }
    .new-home-banner__right-heading a {
      color: #3cc065;
      display: inline-block;
      margin-right: 4px; }
      @media screen and (min-width: 992px) {
        .new-home-banner__right-heading a {
          margin-right: 6px;
          color: #fff; } }
      @media screen and (min-width: 992px) {
        .new-home-banner__right-heading a span {
          background: linear-gradient(#3cc065) left no-repeat, #fff;
          background-size: 0% 100%;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          transition: 0.2s all ease-in-out;
          display: inline-block; } }
      @media screen and (min-width: 992px) {
        .new-home-banner__right-heading a:hover span {
          background-size: 100% 100%; } }
  .new-home-banner__right-bottom-bar {
    position: relative;
    display: inline-block; }
    .new-home-banner__right-bottom-bar:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 2px;
      width: 100%;
      border-radius: 10px;
      animation: 4s linear animate infinite; }
      @media screen and (min-width: 992px) {
        .new-home-banner__right-bottom-bar:before {
          height: 4px;
          border-radius: 30px; } }
    .new-home-banner__right-bottom-bar--first:before {
      background: -moz-linear-gradient(90deg, #03a9f4, #ff5800, #fbba14, #03a9f4);
      background: -webkit-linear-gradient(90deg, #03a9f4, #ff5800, #fbba14, #03a9f4);
      background: linear-gradient(90deg, #03a9f4, #ff5800, #fbba14, #03a9f4);
      background-size: 200%; }
    .new-home-banner__right-bottom-bar--second:before {
      background: -moz-linear-gradient(90deg, #ffe70e, #78e6ff, #15fe8a, #ffe70e);
      background: -webkit-linear-gradient(90deg, #ffe70e, #78e6ff, #15fe8a, #ffe70e);
      background: linear-gradient(90deg, #ffe70e, #78e6ff, #15fe8a, #ffe70e);
      background-size: 200%; }
  .new-home-banner__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center; }
    @media screen and (min-width: 992px) {
      .new-home-banner__list {
        flex-direction: column;
        flex-wrap: inherit;
        align-items: flex-start;
        justify-content: inherit; } }
  .new-home-banner__list-item {
    border-width: 1px;
    border-style: solid;
    border-radius: 26px;
    padding: 1px;
    transition: 0.3s all ease-in-out;
    transition: width 0.3s ease;
    position: relative;
    overflow: hidden;
    margin: 0 2.5px 5px; }
    @media screen and (min-width: 992px) {
      .new-home-banner__list-item {
        border-radius: 30px;
        transform: translateZ(0);
        border: none;
        padding: 1.5px;
        margin: 0 0 5px; } }
    .new-home-banner__list-item path {
      transition: 0.3s all ease-in-out; }
    .new-home-banner__list-item:hover svg {
      transform: translate(30px, 0); }
    .new-home-banner__list-item:before {
      content: "";
      position: absolute;
      top: -80px;
      right: 0;
      bottom: 0;
      left: -13px;
      width: 250px;
      height: 250px;
      transition: 0.3s all ease; }
    @media screen and (min-width: 992px) {
      .new-home-banner__list-item--border-color1 {
        width: 155px; } }
    .new-home-banner__list-item--border-color1::before {
      background: #64b9f4; }
    @media screen and (min-width: 992px) {
      .new-home-banner__list-item--border-color1:hover {
        width: 181px; } }
    .new-home-banner__list-item--border-color1:hover path {
      fill: #fff; }
    .new-home-banner__list-item--border-color1:hover:before {
      animation: rotation2 3s linear infinite;
      background: -moz-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: -webkit-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1); }
    @media screen and (min-width: 992px) {
      .new-home-banner__list-item--border-color2 {
        width: 155px; } }
    .new-home-banner__list-item--border-color2:before {
      background: #e26826; }
    @media screen and (min-width: 992px) {
      .new-home-banner__list-item--border-color2:hover {
        width: 185px; } }
    .new-home-banner__list-item--border-color2:hover path {
      fill: #fff; }
    .new-home-banner__list-item--border-color2:hover:before {
      animation: rotation2 3s linear infinite;
      background: -moz-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: -webkit-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1); }
    @media screen and (min-width: 992px) {
      .new-home-banner__list-item--border-color3 {
        width: 195px; } }
    .new-home-banner__list-item--border-color3:before {
      background: #fbba14; }
    @media screen and (min-width: 992px) {
      .new-home-banner__list-item--border-color3:hover {
        width: 225px; } }
    .new-home-banner__list-item--border-color3:hover path {
      fill: #fff; }
    .new-home-banner__list-item--border-color3:hover:before {
      animation: rotation2 3s linear infinite;
      background: -moz-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: -webkit-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1); }
    @media screen and (min-width: 992px) {
      .new-home-banner__list-item--border-color4 {
        width: 155px; } }
    .new-home-banner__list-item--border-color4:before {
      background: #648ff4;
      width: 310px;
      height: 310px;
      left: -20px;
      top: -120px; }
    @media screen and (min-width: 992px) {
      .new-home-banner__list-item--border-color4:hover {
        width: 180px; } }
    .new-home-banner__list-item--border-color4:hover path {
      fill: #fff; }
    .new-home-banner__list-item--border-color4:hover:before {
      animation: rotation2 3s linear infinite;
      background: -moz-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: -webkit-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1); }
    @media screen and (min-width: 992px) {
      .new-home-banner__list-item--border-color5 {
        width: 260px; } }
    .new-home-banner__list-item--border-color5:before {
      background: #804b8b;
      width: 310px;
      height: 310px;
      left: -20px;
      top: -120px; }
    @media screen and (min-width: 992px) {
      .new-home-banner__list-item--border-color5:hover {
        width: 275px; } }
    .new-home-banner__list-item--border-color5:hover path {
      fill: #fff; }
    .new-home-banner__list-item--border-color5:hover:before {
      animation: rotation2 3s linear infinite;
      background: -moz-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: -webkit-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1); }
    @media screen and (min-width: 992px) {
      .new-home-banner__list-item--border-color6 {
        width: 165px; } }
    .new-home-banner__list-item--border-color6:before {
      background: #3b95d3; }
    @media screen and (min-width: 992px) {
      .new-home-banner__list-item--border-color6:hover {
        width: 195px; } }
    .new-home-banner__list-item--border-color6:hover path {
      fill: #fff; }
    .new-home-banner__list-item--border-color6:hover:before {
      animation: rotation2 3s linear infinite;
      background: -moz-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: -webkit-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1); }
    @media screen and (min-width: 992px) {
      .new-home-banner__list-item--border-color7 {
        width: 155px; } }
    .new-home-banner__list-item--border-color7:before {
      background: #e98e5c; }
    @media screen and (min-width: 992px) {
      .new-home-banner__list-item--border-color7:hover {
        width: 185px; } }
    .new-home-banner__list-item--border-color7:hover path {
      fill: #fff; }
    .new-home-banner__list-item--border-color7:hover:before {
      animation: rotation2 3s linear infinite;
      background: -moz-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: -webkit-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1); }
    @media screen and (min-width: 992px) {
      .new-home-banner__list-item--border-color8 {
        width: 115px; } }
    .new-home-banner__list-item--border-color8:before {
      background: #3cc065; }
    @media screen and (min-width: 992px) {
      .new-home-banner__list-item--border-color8:hover {
        width: 145px; } }
    .new-home-banner__list-item--border-color8:hover path {
      fill: #fff; }
    .new-home-banner__list-item--border-color8:hover:before {
      animation: rotation2 3s linear infinite;
      background: -moz-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: -webkit-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1); }
    @media screen and (min-width: 992px) {
      .new-home-banner__list-item--border-color9 {
        width: 270px; } }
    .new-home-banner__list-item--border-color9:before {
      background: #E98E5C;
      width: 310px;
      height: 310px;
      left: -20px;
      top: -120px; }
    @media screen and (min-width: 992px) {
      .new-home-banner__list-item--border-color9:hover {
        width: 285px; } }
    .new-home-banner__list-item--border-color9:hover path {
      fill: #fff; }
    .new-home-banner__list-item--border-color9:hover:before {
      animation: rotation2 3s linear infinite;
      background: -moz-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: -webkit-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1); }
    .new-home-banner__list-item a {
      font-family: "Poppins" !important;
      color: #fff;
      font-size: 18px;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 0 5px;
      background: #0d2642;
      border-radius: 30px;
      padding: 5px 15px;
      position: relative;
      z-index: 9; }
      @media screen and (min-width: 992px) {
        .new-home-banner__list-item a {
          font-size: 26px;
          padding: 5px 25px; } }
      .new-home-banner__list-item a svg {
        transition: 0.3s all ease-in-out; }
  .new-home-banner__left-arrow {
    position: absolute;
    left: 0;
    top: -15px; }
  .new-home-banner__right-arrow {
    position: absolute;
    right: 0;
    top: -15px; }
  .new-home-banner__right-deatils {
    font-family: "Poppins" !important;
    color: #fff;
    font-weight: 400; }
    @media screen and (min-width: 992px) {
      .new-home-banner__right-deatils {
        position: relative;
        top: -25px;
        font-size: 31px;
        line-height: 40px; } }
  .new-home-banner__right-deatils-green {
    color: #3CC065;
    font-weight: 700; }
  @media screen and (min-width: 992px) {
    .new-home-banner__rating {
      margin: 20px auto 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
      padding: 0 50px 0 0;
      position: relative; } }
  .new-home-banner__rated-text {
    flex: 0 0 49%;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .new-home-banner__rated-text {
        text-align: left; } }
  .new-home-banner__rated-heading {
    font-family: "Poppins" !important;
    color: #fff;
    font-size: 24px;
    line-height: 30px;
    font-weight: 700; }
  .new-home-banner__rated-sub-heading {
    font-family: "Poppins" !important;
    color: #3cc065;
    font-size: 21px;
    line-height: 27px;
    font-weight: 500; }
  .new-home-banner__rated-icon {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between; }
    @media screen and (min-width: 992px) {
      .new-home-banner__rated-icon {
        margin-left: 40px; } }
  .new-home-banner__icon-item {
    flex: 0 0 50%;
    display: flex;
    justify-content: center;
    padding: 5px 0; }
  .new-home-banner__btn-wrapper {
    margin-top: 25px; }
    @media screen and (min-width: 992px) {
      .new-home-banner__btn-wrapper {
        margin-bottom: 40px;
        position: absolute;
        bottom: -135px;
        left: 0;
        margin-top: 0; } }
    @media screen and (min-width: 1025px) {
      .new-home-banner__btn-wrapper {
        bottom: -85px; } }
  .new-home-banner__btn {
    border-color: #ff5800;
    color: #fff;
    border-width: 2px;
    background: #ff5800 !important; }
    @media screen and (min-width: 992px) {
      .new-home-banner__btn {
        width: 380px;
        background: none !important; }
        .new-home-banner__btn:hover {
          background: none;
          color: #fff;
          transition: 0.3s all ease-in-out; } }
    .new-home-banner__btn path {
      fill: #fff; }
      @media screen and (min-width: 992px) {
        .new-home-banner__btn path {
          fill: #ff5800; } }
  .new-home-banner__btn-arrow {
    margin-left: 10px; }
  .new-home-banner__leftlist {
    width: 100%;
    max-width: 510px;
    margin: auto; }
    @media screen and (min-width: 1024px) {
      .new-home-banner__leftlist {
        max-width: inherit;
        margin: 0 0 20px; } }
    .new-home-banner__leftlist li {
      font-size: 18px;
      line-height: normal;
      font-weight: 500;
      color: #8FC4F5;
      background: url(/images/new-home-banner/new-banner-sky-tick.svg) no-repeat 0 0;
      background-size: 20px 20px;
      padding-left: 28px;
      margin: 12px 0; }
      @media screen and (min-width: 992px) {
        .new-home-banner__leftlist li {
          font-size: 21px;
          line-height: 36px;
          background: url(/images/new-home-banner/new-banner-sky-tick.svg) no-repeat center left;
          background-size: 23px 23px;
          margin: 0;
          padding-left: 32px; } }
  .new-home-banner .new-banner-img {
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    transform: scale(1);
    transition: all 0.8s ease-in-out; }
    @media screen and (min-width: 1266px) {
      .new-home-banner .new-banner-img {
        height: auto; } }
    @media screen and (min-width: 1367px) {
      .new-home-banner .new-banner-img {
        height: 90%; } }
    .new-home-banner .new-banner-img.desktop-banner-img {
      display: none; }
      @media screen and (min-width: 992px) {
        .new-home-banner .new-banner-img.desktop-banner-img {
          display: block; } }
    .new-home-banner .new-banner-img.mobile-banner-img {
      display: block; }
      @media screen and (min-width: 992px) {
        .new-home-banner .new-banner-img.mobile-banner-img {
          display: none; } }
  .new-home-banner .new-home-banner-content {
    padding: 60px 0;
    position: relative; }
    @media screen and (min-width: 992px) {
      .new-home-banner .new-home-banner-content {
        padding: 125px 0 195px; } }
    @media screen and (min-width: 1089px) {
      .new-home-banner .new-home-banner-content {
        padding: 100px 0 220px; } }
    @media screen and (min-width: 1367px) {
      .new-home-banner .new-home-banner-content {
        padding: 100px 0 220px; } }
    @media screen and (min-width: 1600px) {
      .new-home-banner .new-home-banner-content {
        padding: 100px 0 215px; } }
    @media screen and (min-width: 1700px) {
      .new-home-banner .new-home-banner-content {
        padding: 100px 0 215px; } }
    @media screen and (min-width: 1813px) {
      .new-home-banner .new-home-banner-content {
        padding: 115px 0 232px; } }
    @media screen and (min-width: 2040px) {
      .new-home-banner .new-home-banner-content {
        padding: 170px 0 275px; } }
    @media screen and (min-width: 2720px) {
      .new-home-banner .new-home-banner-content {
        padding: 190px 0 400px; } }
    @media screen and (min-width: 4080px) {
      .new-home-banner .new-home-banner-content {
        padding: 86px 0 550px; } }
    .new-home-banner .new-home-banner-content .new-home-banner-text-order-1 {
      order: 2; }
      @media screen and (min-width: 992px) {
        .new-home-banner .new-home-banner-content .new-home-banner-text-order-1 {
          order: 1; } }
    .new-home-banner .new-home-banner-content .new-home-banner-text-order-2 {
      order: 1; }
      @media screen and (min-width: 992px) {
        .new-home-banner .new-home-banner-content .new-home-banner-text-order-2 {
          order: 2; } }
    .new-home-banner .new-home-banner-content .banner-main-heading-text {
      color: #fff;
      font-weight: 700;
      margin-bottom: 15px;
      font-size: 26px;
      line-height: 44px;
      text-align: center; }
      @media screen and (min-width: 992px) {
        .new-home-banner .new-home-banner-content .banner-main-heading-text {
          font-weight: 700;
          margin-bottom: 20px;
          font-size: 49px;
          line-height: 61px;
          text-align: left; } }
      @media screen and (min-width: 1280px) {
        .new-home-banner .new-home-banner-content .banner-main-heading-text {
          font-size: 51px;
          line-height: 62px; } }
    .new-home-banner .new-home-banner-content .on-demand-p {
      color: #fff;
      font-size: 22px;
      margin: 25px 0 20px; }
      @media screen and (min-width: 992px) {
        .new-home-banner .new-home-banner-content .on-demand-p {
          font-size: 24px;
          margin: 25px 0 35px; } }
    .new-home-banner .new-home-banner-content .new-banner-text-area .new-banner-text {
      margin-bottom: 0;
      padding-left: 0;
      position: relative;
      text-align: center; }
      @media screen and (min-width: 992px) {
        .new-home-banner .new-home-banner-content .new-banner-text-area .new-banner-text {
          padding-left: 40px;
          text-align: left; }
          .new-home-banner .new-home-banner-content .new-banner-text-area .new-banner-text::before {
            content: "";
            width: 2px;
            left: 0;
            top: 0;
            bottom: 0;
            position: absolute;
            background-color: #d8d8d8; } }
      .new-home-banner .new-home-banner-content .new-banner-text-area .new-banner-text .new-banner-text-li {
        margin-bottom: 10px;
        font-size: 22px;
        font-weight: 600;
        line-height: 22px;
        color: #46df75; }
        @media screen and (min-width: 992px) {
          .new-home-banner .new-home-banner-content .new-banner-text-area .new-banner-text .new-banner-text-li {
            font-size: 30px;
            font-weight: 600;
            line-height: 30px;
            margin-bottom: 20px; } }
    .new-home-banner .new-home-banner-content .btn-orange.banner-btn-new {
      margin: 0 auto; }
    .new-home-banner .new-home-banner-content .bottom-form-link {
      text-align: center; }
      @media screen and (min-width: 992px) {
        .new-home-banner .new-home-banner-content .bottom-form-link {
          text-align: left; } }
  .new-home-banner .new-banner-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 30px; }
    @media screen and (min-width: 992px) {
      .new-home-banner .new-banner-list {
        justify-content: space-between;
        flex-wrap: nowrap; } }
    .new-home-banner .new-banner-list li {
      color: #3cc065;
      position: relative;
      width: 100%;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      font-size: 20px;
      padding: 5px 0; }
      @media screen and (min-width: 992px) {
        .new-home-banner .new-banner-list li {
          font-size: 18px;
          line-height: 24px;
          text-transform: capitalize;
          margin: 0 15px;
          width: auto; } }
      .new-home-banner .new-banner-list li:before {
        content: "";
        position: absolute;
        background: url(/images/new-home-banner/banner-bullet.png) no-repeat 0 0;
        top: 13px;
        right: -65px;
        height: 40px;
        width: 40px;
        background-size: 100%;
        background-position-y: 50%;
        display: none; }
        @media screen and (min-width: 992px) {
          .new-home-banner .new-banner-list li:before {
            display: block;
            top: 10px;
            right: -25px;
            width: 20px; } }
      .new-home-banner .new-banner-list li:last-child::before {
        display: none; }
  .new-home-banner .new-banner-rated-area {
    background: #061226;
    position: relative; }
    .new-home-banner .new-banner-rated-area.m-height {
      height: 110px; }
      @media screen and (min-width: 992px) {
        .new-home-banner .new-banner-rated-area.m-height {
          height: auto; } }
    .new-home-banner .new-banner-rated-area p {
      margin-bottom: 0; }
    .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box,
    .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box-mod {
      flex-wrap: wrap; }
      @media screen and (min-width: 992px) {
        .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box,
        .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box-mod {
          display: flex;
          flex-wrap: nowrap;
          justify-content: center; } }
      .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box .new-banner-rated-area-box,
      .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box-mod .new-banner-rated-area-box {
        display: flex;
        align-items: center;
        padding: 18px 50px;
        border-right: 0;
        justify-content: center;
        position: relative; }
        @media screen and (min-width: 992px) {
          .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box .new-banner-rated-area-box,
          .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box-mod .new-banner-rated-area-box {
            padding: 18px 25px;
            justify-content: left; } }
        @media screen and (min-width: 1200px) {
          .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box .new-banner-rated-area-box,
          .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box-mod .new-banner-rated-area-box {
            padding: 14px 40px; } }
        @media screen and (min-width: 1280px) {
          .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box .new-banner-rated-area-box,
          .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box-mod .new-banner-rated-area-box {
            padding: 14px 44px; } }
        .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box .new-banner-rated-area-box:before,
        .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box-mod .new-banner-rated-area-box:before {
          content: "";
          position: absolute;
          background: url(/images/new-home-banner/banner-devider.png) no-repeat 0 0;
          width: 1px;
          height: 100%;
          top: 0;
          right: 0; }
        .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box .new-banner-rated-area-box:last-child:before,
        .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box-mod .new-banner-rated-area-box:last-child:before {
          display: none; }
        .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box .new-banner-rated-area-box .rated-left-area,
        .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box-mod .new-banner-rated-area-box .rated-left-area {
          text-align: center;
          padding-right: 20px; }
          @media screen and (min-width: 992px) {
            .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box .new-banner-rated-area-box .rated-left-area,
            .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box-mod .new-banner-rated-area-box .rated-left-area {
              padding-right: 20px; } }
          @media screen and (min-width: 1200px) {
            .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box .new-banner-rated-area-box .rated-left-area,
            .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box-mod .new-banner-rated-area-box .rated-left-area {
              padding-right: 38px; } }
          .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box .new-banner-rated-area-box .rated-left-area .rated-text,
          .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box-mod .new-banner-rated-area-box .rated-left-area .rated-text {
            color: #8d9eb2;
            font-size: 13px; }
          .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box .new-banner-rated-area-box .rated-left-area .rated-percentage,
          .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box-mod .new-banner-rated-area-box .rated-left-area .rated-percentage {
            color: #81baff;
            font-weight: 500;
            font-size: 36px;
            padding: 0px 0 5px; }
          .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box .new-banner-rated-area-box .rated-left-area .rated-star i,
          .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box-mod .new-banner-rated-area-box .rated-left-area .rated-star i {
            color: #f7b500;
            font-size: 10px; }
      .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box .slick-dots,
      .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box-mod .slick-dots {
        margin-top: 0;
        margin-bottom: 0;
        padding-bottom: 20px; }
  .new-home-banner .splide__arrows {
    display: none; }
  .new-home-banner--time-business-award {
    position: relative; }
    .new-home-banner--time-business-award:after {
      background: url(/images/new-home-banner/green-gradiant-tr.png) no-repeat 0 0;
      width: 710px;
      height: 486px;
      top: 0;
      right: 0;
      left: inherit;
      transform: none;
      z-index: 0; }
    @media screen and (min-width: 992px) {
      .new-home-banner--time-business-award {
        padding: 0; } }
    .new-home-banner--time-business-award .new-home-banner__left:before {
      display: none; }
    @media screen and (min-width: 992px) {
      .new-home-banner--time-business-award .new-home-banner__left {
        padding: 120px 0 0; } }
    .new-home-banner--time-business-award .new-home-banner__heading {
      font-size: 31px;
      line-height: 38px;
      font-weight: 700; }
      @media screen and (min-width: 992px) {
        .new-home-banner--time-business-award .new-home-banner__heading {
          font-size: 44px;
          line-height: 53px; } }
      .new-home-banner--time-business-award .new-home-banner__heading:before {
        display: none; }
    .new-home-banner--time-business-award .new-home-banner__heading-quote {
      color: #3CC065; }
    .new-home-banner--time-business-award .new-home-banner__blue-para {
      font-family: "Poppins";
      font-size: 17px;
      line-height: 23px;
      font-weight: 600;
      color: #8FC4F5;
      text-align: center;
      margin-top: 20px; }
      @media screen and (min-width: 992px) {
        .new-home-banner--time-business-award .new-home-banner__blue-para {
          padding-right: 290px;
          font-size: 21px;
          line-height: 27px;
          text-align: left;
          margin-top: 0; } }
    .new-home-banner--time-business-award .new-home-banner__btn-wrapper {
      position: relative;
      top: 0; }
      @media screen and (min-width: 992px) {
        .new-home-banner--time-business-award .new-home-banner__btn-wrapper {
          margin-top: 27px; } }
    @media screen and (min-width: 992px) {
      .new-home-banner--time-business-award .new-home-banner__award-img {
        position: absolute;
        top: 35px;
        right: 0;
        z-index: 999; } }
    @media screen and (min-width: 992px) {
      .new-home-banner--time-business-award .new-home-banner__award-img img {
        width: 94%;
        margin-left: auto;
        display: block; } }
  @media screen and (min-width: 992px) {
    .new-home-banner--et-award {
      padding: 60px 0 80px 0;
      background: url(/images/new-home-banner/et-award-slider.jpg) no-repeat center center/cover; } }
  .new-home-banner--et-award:after {
    display: none; }
  .new-home-banner--et-award .new-home-banner__left:before {
    display: none; }
  .new-home-banner--et-award .new-home-banner__heading:before {
    display: none; }
  .new-home-banner--et-award .new-home-banner__heading {
    font-family: "Poppins";
    font-weight: 700;
    margin-top: 20px; }
    @media screen and (min-width: 992px) {
      .new-home-banner--et-award .new-home-banner__heading {
        font-size: 44px;
        line-height: 50px;
        padding-bottom: 0;
        margin-bottom: 25px;
        margin-top: 0; } }
  .new-home-banner--et-award .new-home-banner__blue-para {
    font-family: "Poppins";
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    color: #97B3CE;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .new-home-banner--et-award .new-home-banner__blue-para {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 55px;
        text-align: left; } }
  .new-home-banner--et-award .new-home-banner__heading-quote {
    color: #3CC065; }
  @media screen and (min-width: 992px) {
    .new-home-banner--et-award .new-home-banner__left {
      padding-top: 100px; } }
  @media screen and (min-width: 1025px) {
    .new-home-banner--et-award .new-home-banner__left {
      flex: 0 0 55%; } }
  @media screen and (min-width: 992px) {
    .new-home-banner--et-excellence {
      padding: 80px 0;
      background: url(/images/new-home-banner/et-excellence-slider-new2.jpg) no-repeat center right;
      background-size: 100% 100%; } }
  @media screen and (min-width: 1600px) {
    .new-home-banner--et-excellence {
      background-size: cover; } }
  .new-home-banner--et-excellence:after {
    display: none; }
  .new-home-banner--et-excellence .new-home-banner__left {
    padding-right: 0;
    flex: 0 0 49%; }
    .new-home-banner--et-excellence .new-home-banner__left:before {
      display: none; }
  .new-home-banner--et-excellence .new-home-banner__right {
    padding: 30px 0; }
    @media screen and (min-width: 992px) {
      .new-home-banner--et-excellence .new-home-banner__right {
        padding: 0; } }
  @media screen and (min-width: 992px) {
    .new-home-banner--et-excellence .new-home-banner__heading {
      font-size: 44px;
      line-height: 58px;
      margin: 0; } }
  .new-home-banner--et-excellence .new-home-banner__heading:before {
    display: none; }
  .new-home-banner--et-excellence .new-home-banner__blue-para {
    font-family: "Poppins";
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    color: #fff;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .new-home-banner--et-excellence .new-home-banner__blue-para {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 55px;
        text-align: left; } }
  .new-home-banner--et-excellence .new-home-banner__btn-wrapper {
    margin-top: 35px; }
  @media screen and (min-width: 992px) {
    .new-home-banner--outsoucing-success-blueprint {
      padding: 60px 0 80px 0;
      background: url(/images/new-home-banner/outsourcing-bg.jpg) no-repeat center center/cover; } }
  .new-home-banner--outsoucing-success-blueprint:after {
    display: none; }
  .new-home-banner--outsoucing-success-blueprint:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 386px;
    height: 360px;
    background: url(/images/new-home-banner/banner-gradient.png) no-repeat 0 0;
    display: none; }
    @media screen and (min-width: 992px) {
      .new-home-banner--outsoucing-success-blueprint:before {
        display: block; } }
  .new-home-banner--outsoucing-success-blueprint .new-home-banner__btn-wrapper {
    position: relative;
    left: 0;
    bottom: 0;
    margin-bottom: 15px; }
  .new-home-banner--outsoucing-success-blueprint .new-home-banner__btn {
    background: #FF5800 !important; }
    .new-home-banner--outsoucing-success-blueprint .new-home-banner__btn svg path {
      fill: #fff; }
    .new-home-banner--outsoucing-success-blueprint .new-home-banner__btn:hover {
      background: #FFFFFF !important; }
      .new-home-banner--outsoucing-success-blueprint .new-home-banner__btn:hover svg path {
        fill: #FF5800; }
  @media screen and (min-width: 992px) {
    .new-home-banner--leap-mwc {
      padding: 80px 0 50px 0;
      background: url(/images/new-home-banner/leap-mwc-bg.jpg) no-repeat center center/cover; } }
  .new-home-banner--leap-mwc:after {
    display: none; }
  .new-home-banner--leap-mwc .new-home-banner__btn-wrapper {
    position: static;
    margin-bottom: 0; }
  .new-home-banner--leap-mwc .btn-orange {
    background: #ff5800 !important;
    font-size: 17px; }
    @media screen and (min-width: 992px) {
      .new-home-banner--leap-mwc .btn-orange {
        max-width: 350px;
        width: 350px; } }
    .new-home-banner--leap-mwc .btn-orange svg path {
      fill: #fff !important; }
    .new-home-banner--leap-mwc .btn-orange:hover {
      background: none !important;
      color: #fff; }
  .new-home-banner--leap-mwc .new-home-banner__right-items {
    color: #fff;
    font-size: 21px;
    font-weight: 500;
    margin: 8px 0;
    display: flex;
    align-items: center; }
    .new-home-banner--leap-mwc .new-home-banner__right-items svg {
      margin-right: 15px; }
  .new-home-banner--leap-mwc .new-home-banner__right-list {
    margin: 25px 0 35px; }

@keyframes animate {
  0% {
    background-position: 400%; }
  100% {
    background-position: 0%; } }

.text-left-mod {
  text-align: left !important; }

.margin-unset {
  margin-bottom: 0; }

.num-color {
  color: #43bf69; }

@media screen and (min-width: 992px) {
  .hm-about-section .about-details-para {
    width: 92%; } }

.hm-about-section .font-size-42 {
  font-size: 24px;
  line-height: 36px; }
  @media screen and (min-width: 992px) {
    .hm-about-section .font-size-42 {
      font-size: 41px;
      line-height: 52px; } }
  @media screen and (min-width: 1280px) {
    .hm-about-section .font-size-42 {
      font-size: 42px;
      line-height: 52px; } }

.new-home-banner-wrapper .splide__arrows {
  display: none; }

.new-home-banner-wrapper .custom-pagination-wrapper {
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  bottom: 0;
  height: 60px; }

.new-home-banner-wrapper .custom-pagination {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  max-width: 1150px;
  margin: 0 auto;
  align-items: center;
  padding: 0 15px; }
  @media screen and (min-width: 992px) {
    .new-home-banner-wrapper .custom-pagination {
      padding: 0; } }

.new-home-banner-wrapper .pagination-dot {
  position: relative;
  background: linear-gradient(to right, #ffffff 50%, #acacac 50%);
  background-size: 200% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  color: transparent;
  /* Hide the original text color */
  border: none;
  padding: 0;
  margin: 0 30px 0 0;
  cursor: pointer;
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 300;
  width: auto;
  text-transform: capitalize;
  background-position: 100% 0%; }

.new-home-banner-wrapper .pagination-dot.is-active {
  background-position: 0% 0%; }

.new-home-banner-wrapper .pay-pause-wrapper {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  text-align: right; }

.new-home-banner-wrapper .pagination-pay-pause {
  width: 30px;
  height: 30px;
  color: #acacac;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 0;
  padding: 0;
  border: 2px solid #fff;
  border-radius: 50%;
  background: url(/images/new-home-banner/pause-white.svg) no-repeat 55% 50%/60%;
  transition: .3s all ease-in-out;
  opacity: 0.5; }
  .new-home-banner-wrapper .pagination-pay-pause.paused {
    border: 2px solid #fff;
    border-radius: 50%;
    background: url(/images/new-home-banner/play-white.svg) no-repeat 62% 50%/60%; }
  .new-home-banner-wrapper .pagination-pay-pause:hover {
    transform: scale(1.1); }

.new-home-banner-wrapper .splide__pagination {
  position: relative;
  bottom: 25px; }
  @media screen and (min-width: 992px) {
    .new-home-banner-wrapper .splide__pagination {
      display: none; } }

.new-home-banner-wrapper .splide__progress {
  position: absolute;
  top: 0;
  width: 100%; }

.new-home-banner-wrapper .splide__progress__bar {
  background: #a8d04d;
  height: 4px;
  animation: 4s linear animate infinite;
  background: -moz-linear-gradient(90deg, #03a9f4, #ff5800, #fbba14, #03a9f4);
  background: -webkit-linear-gradient(90deg, #03a9f4, #ff5800, #fbba14, #03a9f4);
  background: linear-gradient(90deg, #03a9f4, #ff5800, #fbba14, #03a9f4);
  background-size: 200%; }

.new-home-banner-wrapper {
  background: #0d2642; }

@keyframes fillLine {
  from {
    width: 0%; }
  to {
    width: 100%; } }

@media screen and (min-width: 992px) {
  .convergence-gitx-left {
    flex: 0 0 60%;
    padding-right: 20px; } }

@media screen and (min-width: 1025px) {
  .convergence-gitx-left {
    flex: 0 0 63%; } }

@media screen and (min-width: 992px) {
  .convergence-gitx-left .new-home-banner__sub-heading-white {
    font-family: Poppins;
    font-size: 50px;
    line-height: 60px; } }

.btn-v2 {
  max-width: 409px;
  height: 62px;
  margin: 0 auto;
  border-width: 2px;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins' !important;
  font-size: 19px;
  font-weight: 600;
  border-radius: 5px;
  text-transform: capitalize; }
  .btn-v2--transparent {
    background-color: transparent; }
  .btn-v2--transparent-black {
    background-color: transparent;
    color: #333;
    border-color: #333; }
    .btn-v2--transparent-black:hover {
      background: #333;
      color: #fff;
      transition: .3s all ease-in-out; }
      .btn-v2--transparent-black:hover path {
        fill: #fff;
        transition: .3s all ease-in-out; }
  .btn-v2 .btn-arrow {
    margin-left: 10px; }

.btn-orange {
  font-family: 'Poppins' !important;
  background: #FF5800;
  color: #fff;
  transition: .3s all ease-in-out; }
  .btn-orange:hover {
    background: #fff;
    color: #FF5800; }
    .btn-orange:hover path {
      fill: #FF5800; }

.btn-wrapper-v2--pair {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap; }
  .btn-wrapper-v2--pair .btn-v2 {
    flex: 0 0 360px;
    max-width: 360px;
    margin: 15px auto 0; }
    @media screen and (min-width: 770px) {
      .btn-wrapper-v2--pair .btn-v2 {
        max-width: 49%; } }
    @media screen and (min-width: 992px) {
      .btn-wrapper-v2--pair .btn-v2 {
        margin: 50px 5px 20px;
        flex: 0 0 370px;
        max-width: 370px; } }
  .btn-wrapper-v2--pair .btn-v3 {
    flex: 0 0 360px;
    max-width: 360px;
    margin: 15px auto 0;
    text-transform: capitalize; }
    @media screen and (min-width: 770px) {
      .btn-wrapper-v2--pair .btn-v3 {
        max-width: 49%; } }
    @media screen and (min-width: 992px) {
      .btn-wrapper-v2--pair .btn-v3 {
        margin: 50px 5px 20px;
        flex: 0 0 410px;
        max-width: 410px; } }

.cmn-btn-transition {
  transition: .3s all ease-in-out; }
  .cmn-btn-transition svg {
    transition: .3s all ease-in-out; }
  .cmn-btn-transition path {
    transition: .3s all ease-in-out; }
  .cmn-btn-transition:hover svg {
    transform: translate(15px, 0); }

.btn-arrow {
  margin-left: 10px; }

.white-hover:hover path {
  fill: #FF5800; }

.btn--transparent {
  background: transparent; }

.btn-center {
  margin: 0 auto !important; }

/*Custom Solutions*/
.custom-solution {
  background-color: #F9F9F9;
  padding: 30px 0 40px; }
  @media screen and (min-width: 992px) {
    .custom-solution {
      padding: 60px 0; } }
  .custom-solution__heading {
    color: #262E36;
    font-weight: 700;
    position: relative;
    padding-bottom: 20px; }
    @media screen and (min-width: 992px) {
      .custom-solution__heading {
        padding-bottom: 0; } }
  .custom-solution__sub-heading {
    font-family: 'Poppins' !important;
    font-size: 25px;
    line-height: 34px;
    font-weight: 600;
    color: #474747;
    margin-top: 15px; }
    .custom-solution__sub-heading--home-sub {
      padding: 10px 0 30px; }
    .custom-solution__sub-heading--small {
      font-size: 21px;
      line-height: 32px;
      margin: 25px 0 0; }
  .custom-solution__card-gradient {
    margin-bottom: 15px;
    border: 1px solid #898989;
    display: block;
    position: relative; }
    @media screen and (min-width: 992px) {
      .custom-solution__card-gradient {
        cursor: pointer;
        overflow: hidden;
        padding: 2px;
        border: 0;
        margin-bottom: 25px; } }
    .custom-solution__card-gradient:before {
      content: '+';
      position: absolute;
      top: 20px;
      right: 10px;
      font-size: 36px;
      line-height: 0;
      font-weight: 300;
      color: #898989;
      z-index: 99;
      display: none; }
      @media screen and (min-width: 992px) {
        .custom-solution__card-gradient:before {
          display: block; } }
    .custom-solution__card-gradient:after {
      content: '';
      position: absolute;
      top: -106px;
      right: 0;
      bottom: 0;
      left: -31px;
      background: #898989;
      width: 430px;
      height: 430px;
      transition: .3s all ease;
      display: none; }
      @media screen and (min-width: 992px) {
        .custom-solution__card-gradient:after {
          display: block; } }
    .custom-solution__card-gradient:hover:after {
      animation: rotation2 3s linear infinite;
      background: -moz-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: -webkit-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1);
      display: none; }
      @media screen and (min-width: 992px) {
        .custom-solution__card-gradient:hover:after {
          display: block; } }
    .custom-solution__card-gradient:hover:before {
      color: #35b15c; }
  @media screen and (min-width: 992px) {
    .custom-solution__card-gradient--active {
      background: -moz-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: -webkit-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1); } }
  .custom-solution__card-gradient--active:before {
    content: '-';
    top: 16px;
    font-size: 44px;
    color: #35b15c; }
  @media screen and (min-width: 992px) {
    .custom-solution__card-gradient--active:after {
      animation: rotation2 3s linear infinite;
      background: -moz-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: -webkit-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1); } }
  .custom-solution__card {
    background: #F9F9F9;
    page-break-inside: avoid;
    padding: 20px 40px 20px 15px;
    position: relative;
    z-index: 9; }
    @media screen and (min-width: 992px) {
      .custom-solution__card {
        padding: 20px 20px 20px 20px; } }
  .custom-solution__collapse {
    display: flex;
    align-items: center; }
  .custom-solution__icon {
    display: flex;
    align-items: center;
    height: 50px;
    margin-right: 15px; }
    .custom-solution__icon--fixed-flex {
      flex: 0 0 45px;
      max-width: 45px; }
  .custom-solution__card-heading {
    font-family: 'Poppins' !important;
    font-size: 19px;
    line-height: 31px;
    font-weight: 600;
    margin-bottom: 0; }
  @media screen and (min-width: 992px) {
    .custom-solution__card-description-wrapper {
      display: none;
      margin-top: 15px; } }
  .custom-solution__card-description {
    font-family: 'Poppins' !important;
    font-size: 14px;
    line-height: 22px;
    color: #727272;
    display: none; }
    @media screen and (min-width: 992px) {
      .custom-solution__card-description {
        display: block; } }
  .custom-solution__card-link {
    font-family: 'Poppins' !important;
    font-size: 0;
    font-weight: 600;
    color: #333;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    @media screen and (min-width: 992px) {
      .custom-solution__card-link {
        font-size: 14px;
        position: static; } }
    .custom-solution__card-link::before {
      content: '';
      position: absolute;
      background: url(/images/custom-solutions/r-arrow.png) no-repeat 0 0;
      width: 22px;
      height: 12px;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      display: block; }
      @media screen and (min-width: 992px) {
        .custom-solution__card-link::before {
          display: none; } }
    .custom-solution__card-link svg {
      margin-left: 10px;
      display: none; }
      @media screen and (min-width: 992px) {
        .custom-solution__card-link svg {
          display: inherit; } }
  .custom-solution .btn-wrapper-v2--pair .btn-v2 {
    margin-bottom: 0; }
    @media screen and (min-width: 992px) {
      .custom-solution .btn-wrapper-v2--pair .btn-v2 {
        margin-top: 25px; } }

@keyframes rotation2 {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

/*Home Skill*/
/*Pool Setion*/
.pool-section {
  padding: 25px 0 40px;
  border-bottom: 1px solid #e0e0e0; }
  @media screen and (min-width: 992px) {
    .pool-section {
      padding: 60px 0;
      border-bottom: 0; } }
  .pool-section h2 {
    font-family: "Poppins" !important;
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 34px;
    font-weight: 700;
    position: relative;
    padding-bottom: 15px; }
    @media screen and (min-width: 992px) {
      .pool-section h2 {
        font-size: 48px;
        line-height: 61px;
        padding-bottom: 0; } }
    .pool-section h2::before {
      display: block;
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: #3cc065;
      width: 83px;
      height: 7px;
      margin: 0 auto; }
      @media screen and (min-width: 992px) {
        .pool-section h2::before {
          display: none; } }
  .pool-section h3 {
    font-family: "Poppins" !important;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: #35b15c; }
    @media screen and (min-width: 992px) {
      .pool-section h3 {
        margin-bottom: 40px;
        font-size: 25px;
        line-height: 34px; } }
    .pool-section h3 .heading-underline {
      position: relative;
      display: inline-block; }
      .pool-section h3 .heading-underline:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #35b15c;
        height: 1px;
        width: 100%; }
  .pool-section h4 {
    font-family: "Poppins" !important;
    text-align: center;
    margin-top: 15px;
    font-size: 20px;
    font-weight: 500; }
    @media screen and (min-width: 992px) {
      .pool-section h4 {
        font-size: 17px;
        line-height: 30px;
        text-align: left;
        margin-top: 0; } }
    @media screen and (min-width: 1280px) {
      .pool-section h4 {
        font-size: 18px;
        line-height: 30px; } }
  .pool-section .p-box {
    display: flex;
    flex-wrap: wrap; }
  .pool-section .pool-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    .pool-section .pool-box.mb-40 {
      margin-bottom: 45px; }
    .pool-section .pool-box .owl-dots {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 0 0;
      position: relative;
      gap: 0 10px;
      height: 45px; }
      @media screen and (min-width: 992px) {
        .pool-section .pool-box .owl-dots {
          top: 35px;
          padding: 30px 0;
          height: 73px; } }
      .pool-section .pool-box .owl-dots .owl-dot {
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background: #e1e1e1; }
        .pool-section .pool-box .owl-dots .owl-dot.active {
          width: 13px;
          height: 13px;
          background: #ff5800; }
    .pool-section .pool-box .splide__pagination__page {
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background: #e1e1e1; }
      .pool-section .pool-box .splide__pagination__page.is-active {
        width: 13px;
        height: 13px;
        background: #ff5800;
        transform: scale(1); }
    .pool-section .pool-box-list {
      background: #d6e3f0;
      border-radius: 10px;
      border: 1px solid #d6e3f0;
      padding: 2px;
      flex: auto;
      text-align: center;
      background: #fff;
      margin: 7px 3px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      flex-wrap: wrap;
      position: relative;
      height: 77px;
      transition: 0.3s all ease-in-out; }
      @media screen and (min-width: 992px) {
        .pool-section .pool-box-list {
          flex: inherit;
          padding: 2px;
          overflow: hidden;
          border: none;
          border: none;
          height: inherit;
          transform: translateZ(0); } }
      .pool-section .pool-box-list:before {
        display: none; }
        @media screen and (min-width: 992px) {
          .pool-section .pool-box-list:before {
            display: block;
            content: "";
            position: absolute;
            top: -106px;
            right: 0;
            bottom: 0;
            left: -100px;
            background: #d6e3f0;
            width: 560px;
            height: 500px;
            transition: 0.5s all ease-in-out; } }
      .pool-section .pool-box-list img {
        display: block;
        height: 100%;
        object-fit: contain; }
      .pool-section .pool-box-list small {
        font-family: "Poppins" !important;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        margin-left: 15px; }
      .pool-section .pool-box-list i {
        display: inline-block;
        width: auto; }
      .pool-section .pool-box-list .wd-50 {
        width: 45px; }
      .pool-section .pool-box-list .rltv-3 {
        position: relative;
        top: -3px; }
      .pool-section .pool-box-list a {
        background: #fff;
        color: #333333;
        border-radius: 10px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
        position: relative;
        z-index: 99; }
        @media screen and (min-width: 992px) {
          .pool-section .pool-box-list a {
            padding: 17px 23px; } }
      .pool-section .pool-box-list:hover {
        border-color: #508ec8; }
        .pool-section .pool-box-list:hover:before {
          animation: rotation2 3s linear infinite;
          background: -moz-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
          background: -webkit-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
          background: linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1); }
  .pool-section .btn_wrapper {
    margin-top: 15px; }
    @media screen and (min-width: 992px) {
      .pool-section .btn_wrapper {
        width: 180px;
        margin: 20px 0 0; } }
    .pool-section .btn_wrapper .btn {
      font-family: "Poppins" !important;
      margin: 0 auto;
      font-weight: 500; }
      @media screen and (min-width: 992px) {
        .pool-section .btn_wrapper .btn {
          margin: 0;
          padding: 8px;
          font-size: 15px; } }
  @media screen and (min-width: 992px) {
    .pool-section .col-lg-8 {
      width: 69%; } }
  @media screen and (min-width: 1280px) {
    .pool-section .col-lg-8 {
      width: 66%; } }
  @media screen and (min-width: 992px) {
    .pool-section .col-lg-4 {
      width: 31%; } }
  @media screen and (min-width: 1280px) {
    .pool-section .col-lg-4 {
      width: 34%; } }
  .pool-section .pool-slider {
    position: relative; }
    .pool-section .pool-slider .owl-prev {
      position: absolute;
      left: -30px;
      top: 32px;
      width: auto; }
      .pool-section .pool-slider .owl-prev span {
        font-family: "Poppins" !important;
        display: inline-block;
        background: #fff;
        border: 1px solid #ccc;
        color: #3cc065;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        position: relative;
        font-size: 30px;
        line-height: 26px; }
    .pool-section .pool-slider .owl-next {
      position: absolute;
      right: -30px;
      top: 32px;
      width: auto; }
      .pool-section .pool-slider .owl-next span {
        font-family: "Poppins" !important;
        display: inline-block;
        background: #fff;
        border: 1px solid #ccc;
        color: #3cc065;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        position: relative;
        font-size: 30px;
        line-height: 26px; }
  .pool-section .btn-wrapper-v2--pair .btn-v2 {
    margin-bottom: 0;
    margin-top: 44px; }
  .pool-section--subpage h2 {
    margin-bottom: 40px; }
  .pool-section--subpage .pool-box-list {
    margin: 7px; }
  .pool-section--cloud-engineering .pool-box-list i {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; }
    .pool-section--cloud-engineering .pool-box-list i img {
      width: 100%;
      object-fit: fill; }
  .pool-section .splide__pagination {
    position: absolute;
    bottom: -25px; }

.o-platform-work {
  border-bottom: 1px solid #dedede; }
  .o-platform-work__spacing {
    padding: 30px 0; }
    @media screen and (min-width: 992px) {
      .o-platform-work__spacing {
        padding: 60px 0; } }
    .o-platform-work__spacing--inner {
      border-top: 1px solid #cae0e7; }
  .o-platform-work__title {
    font-family: Poppins;
    font-size: 30px;
    line-height: 35px;
    color: #262e36;
    text-align: center;
    font-weight: 700; }
    @media screen and (min-width: 767px) {
      .o-platform-work__title {
        font-size: 38px;
        line-height: 45px; } }
    @media screen and (min-width: 992px) {
      .o-platform-work__title {
        font-size: 48px;
        line-height: 58px; } }
    .o-platform-work__title--inner {
      margin-bottom: 20px; }
  .o-platform-work__subtitle {
    font-family: Poppins;
    color: #35b15c;
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    font-size: 25px;
    line-height: 34px;
    margin-bottom: 50px; }
  .o-platform-work__box {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d6e3f0;
    border-radius: 10px;
    height: 100%;
    background-color: #fff;
    transition: 0.3s all ease-in-out; }
    .o-platform-work__box--noanchor {
      padding: 30px; }
      @media screen and (min-width: 767px) {
        .o-platform-work__box--noanchor {
          padding: 20px 10px; } }
    .o-platform-work__box:hover {
      box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.1); }
  .o-platform-work__anchor {
    display: block;
    text-align: center;
    width: 100%;
    padding: 30px; }
    @media screen and (min-width: 767px) {
      .o-platform-work__anchor {
        padding: 20px 10px; } }
  .o-platform-work__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center; }
    .o-platform-work__icon img {
      max-height: 100%; }
  .o-platform-work__text {
    font-family: Poppins;
    color: #333333;
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    margin: 0; }
  .o-platform-work .btn-wrapper-v2--pair .btn-v2 {
    margin: 20px 5px 0; }

.bg-gray {
  background-color: #f9f9f9; }

.mb-30 {
  margin-bottom: 30px; }

/*How We Help You*/
.how-help-you {
  background-color: #052C58;
  padding: 25px 0 40px; }
  @media screen and (min-width: 992px) {
    .how-help-you {
      padding: 60px 0 80px;
      background-repeat: no-repeat;
      background-position: top left; } }
  @media screen and (min-width: 992px) {
    .how-help-you__card {
      display: flex;
      justify-content: center;
      max-width: 100%;
      margin: 0 auto; } }
  .how-help-you__card-items {
    flex: 1;
    background: #fff;
    padding: 30px 20px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 20px;
    position: relative;
    flex-wrap: wrap;
    margin: 0; }
    @media screen and (min-width: 770px) {
      .how-help-you__card-items {
        margin: 15px 0;
        height: 380px; } }
    @media screen and (min-width: 992px) {
      .how-help-you__card-items {
        padding: 30px 25px 30px;
        flex-direction: column;
        flex-wrap: inherit;
        height: inherit;
        margin: 0 7px !important; } }
    .how-help-you__card-items a svg {
      transition: .3s all ease-in-out; }
    .how-help-you__card-items a:hover svg {
      transform: translateX(10px); }
    .how-help-you__card-items a:hover path {
      fill: #FF5800; }
  .how-help-you__card-items--border-blue {
    border-bottom: 8px solid #4492DC; }
  .how-help-you__card-items--border-green {
    border-bottom: 8px solid #3CC065; }
  .how-help-you__label {
    font-family: 'Poppins' !important;
    display: block;
    position: absolute;
    top: 15px;
    left: 15px;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
    .how-help-you__label:hover .how-help-you__radio ~ .how-help-you__checkmark {
      background-color: #333; }
  .how-help-you__radio {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .how-help-you__radio:checked ~ .how-help-you__checkmark {
      background-color: #fff;
      border: 2px solid #333; }
    .how-help-you__radio:checked ~ .how-help-you__checkmark:after {
      display: block; }
  .how-help-you__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: #fff;
    border: 2px solid #333;
    border-radius: 50%; }
    .how-help-you__checkmark:after {
      content: "";
      position: absolute;
      display: none;
      top: 3px;
      left: 3px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #333333; }
  .how-help-you__icon {
    height: auto;
    display: flex;
    flex: 0 0 100%;
    text-align: center;
    justify-content: center;
    align-items: center; }
    @media screen and (min-width: 992px) {
      .how-help-you__icon {
        flex: inherit;
        justify-content: inherit; } }
  .how-help-you__heading {
    font-family: 'Poppins' !important;
    font-size: 25px;
    line-height: 38px;
    font-weight: 700;
    color: #fff; }
    @media screen and (min-width: 992px) {
      .how-help-you__heading {
        font-size: 51px;
        line-height: 64px; } }
  .how-help-you__sub-heading {
    font-family: 'Poppins' !important;
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
    color: #35B15C;
    margin-bottom: 35px; }
    @media screen and (min-width: 992px) {
      .how-help-you__sub-heading {
        font-size: 25px;
        line-height: 34px;
        margin-bottom: 25px; } }
  .how-help-you__card-heading {
    font-family: 'Poppins' !important;
    font-size: 21px;
    line-height: 30px;
    font-weight: 700;
    margin: 0 0 15px;
    flex: 0 0 100%; }
    @media screen and (min-width: 992px) {
      .how-help-you__card-heading {
        font-size: 31px;
        line-height: 36px;
        flex: inherit; } }
  .how-help-you__card-sub-heading {
    font-weight: 600;
    color: #333333;
    font-size: 15px;
    line-height: 21px;
    margin: 0; }
    @media screen and (min-width: 992px) {
      .how-help-you__card-sub-heading {
        margin-bottom: 0; } }
  .how-help-you__badge {
    font-family: 'Poppins' !important;
    position: absolute;
    top: -5px;
    right: 15px;
    background: #3CC065;
    color: #000;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px;
    height: 37px; }
  .how-help-you .how-we-help-buttons,
  .how-help-you .let-help__btn {
    font-family: 'Poppins' !important;
    display: block;
    margin: 0 auto;
    width: 300px;
    background: #FF5800;
    text-align: center;
    color: #fff;
    font-size: 17px;
    font-weight: 600;
    padding: 16px 0;
    border-radius: 5px;
    border: 1px solid #FF5800;
    position: relative;
    z-index: 99;
    margin-top: 20px; }
    .how-help-you .how-we-help-buttons:hover,
    .how-help-you .let-help__btn:hover {
      color: #FF5800;
      background: #fff; }
  .how-help-you .owl-dots {
    display: none; }
  .how-help-you__details {
    margin: 20px 0; }
  .how-help-you__link {
    text-align: center; }
  .how-help-you__anchor {
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 600;
    color: #1D52C7;
    display: inline-block;
    margin-top: 0;
    text-decoration: underline;
    background: none;
    width: auto;
    max-width: inherit; }
    .how-help-you__anchor:hover {
      color: #3CC065; }

.let-help {
  max-width: 100%;
  position: relative;
  text-align: center;
  margin-top: 25px; }
  @media screen and (min-width: 992px) {
    .let-help {
      max-width: 1050px;
      margin: 60px auto 0;
      padding: 0 20px 20px 20px; } }
  .let-help:before {
    content: '';
    position: absolute;
    border: 1px solid #5787be;
    top: 14px;
    left: 0;
    right: 0;
    height: 100%;
    border-radius: 5px;
    display: none; }
    @media screen and (min-width: 992px) {
      .let-help:before {
        display: block; } }
  .let-help__heading {
    font-family: 'Poppins' !important;
    font-size: 23px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    display: inline-block;
    background-color: #052C58; }
    @media screen and (min-width: 992px) {
      .let-help__heading {
        font-size: 25px;
        padding: 0 15px; } }
  .let-help__sub-heading {
    font-family: 'Poppins' !important;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    margin-bottom: 20px; }
    @media screen and (min-width: 992px) {
      .let-help__sub-heading {
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 30px; } }
  .let-help__checkbox-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
    @media screen and (min-width: 992px) {
      .let-help__checkbox-list {
        flex-wrap: inherit;
        justify-content: center; } }
  .let-help__checkbox-items {
    flex: 0 47%;
    margin: 10px; }
    @media screen and (min-width: 992px) {
      .let-help__checkbox-items {
        flex: inherit;
        overflow: hidden;
        border-radius: 5px;
        padding: 1px;
        background: #1e8e41;
        background: -moz-linear-gradient(180deg, #1e8e41 0%, #274dad 100%);
        background: -webkit-linear-gradient(180deg, #1e8e41 0%, #274dad 100%);
        background: linear-gradient(180deg, #1e8e41 0%, #274dad 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1e8e41",endColorstr="#274dad",GradientType=1); } }
  @media screen and (min-width: 992px) {
    .let-help__inner-wrapper {
      border-radius: 5px;
      background-color: #05284D;
      padding: 20px 15px 20px 15px; } }
  .let-help__checkbox-label {
    font-family: 'Poppins' !important;
    text-align: left;
    color: #fff;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    display: block;
    position: relative;
    padding-left: 28px;
    margin-bottom: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
    @media screen and (min-width: 992px) {
      .let-help__checkbox-label {
        font-size: 14px;
        line-height: 21px; } }
    .let-help__checkbox-label:hover__checkbox-input ~ .let-help__checkbox-label:hover__checkmark {
      background-color: #ccc; }
      .let-help__checkbox-label:hover__checkbox-input ~ .let-help__checkbox-label:hover__checkmark:after {
        display: block; }
  .let-help__checkbox-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .let-help__checkbox-input:checked ~ .let-help__checkmark {
      background-color: transparent; }
      .let-help__checkbox-input:checked ~ .let-help__checkmark:after {
        content: "";
        position: absolute;
        display: block;
        left: 2px;
        top: 2px;
        width: 5px;
        height: 11px;
        border: solid #000;
        width: 12px;
        height: 12px;
        border: 0;
        background: #3CC065;
        border-radius: 50%; }
  .let-help__checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 2px;
    border: 1px solid #3CC065;
    background-color: transparent;
    border-radius: 50%;
    transform: translateY(-50%); }
    @media screen and (min-width: 992px) {
      .let-help__checkmark {
        top: 2px;
        transform: inherit; } }
  .let-help__btn {
    font-family: 'Poppins' !important;
    background-color: #FF5800;
    border: 1px solid #FF5800;
    border-radius: 5px;
    padding: 18px 15px;
    color: #fff;
    font-size: 17px;
    font-weight: 600;
    width: 360px;
    margin: 30px auto 0;
    transition: .3s all ease-in-out;
    text-transform: capitalize;
    margin-bottom: 0; }
    .let-help__btn svg {
      margin-left: 10px; }
    .let-help__btn:hover {
      background-color: #fff;
      color: #FF5800; }
      .let-help__btn:hover path {
        fill: #FF5800; }
  .let-help .compare-engage-link {
    font-size: 13px;
    line-height: 20px;
    text-decoration: underline;
    position: relative;
    z-index: 99;
    margin-top: 10px;
    font-weight: 700; }
    .let-help .compare-engage-link:hover {
      color: #00b54a !important; }

.link {
  background: none !important;
  padding: 0 !important;
  width: auto !important;
  border: none !important;
  display: block !important;
  margin: 10px auto;
  color: #fff; }

.custom-dev-help .how-help-you {
  background: url(/images/how-we-help-you/custom-dev-how-we-bg.jpg) no-repeat bottom right/cover; }

.custom-dev-help .let-help__heading {
  background: none; }

.custom-dev-help .how-help-you__heading {
  font-weight: 500; }

@media screen and (min-width: 992px) {
  .custom-dev-help .how-help-you__card-heading {
    font-size: 24px;
    line-height: 29px; } }

.custom-dev-help .how-help-you__card-items {
  border-bottom: none; }

.custom-dev-help .how-we-help-buttons {
  background: none;
  color: #333;
  transition: .3s all ease-in-out; }
  .custom-dev-help .how-we-help-buttons svg path {
    fill: #333; }
  .custom-dev-help .how-we-help-buttons:hover {
    background: #FF5800;
    color: #fff; }
    .custom-dev-help .how-we-help-buttons:hover svg path {
      fill: #fff; }

.custom-dev-help .how-we-help-knowmore {
  color: #0D65CA; }

.custom-dev-help .how-help-you__card-sub-heading {
  margin: 0; }
  @media screen and (min-width: 992px) {
    .custom-dev-help .how-help-you__card-sub-heading {
      font-weight: 500;
      font-size: 15px;
      line-height: 21px;
      margin: 0 0 25px; } }

/*Home case study*/
.h-case-study {
  position: relative; }
  .h-case-study__heading {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 221px;
    height: 58px;
    margin-bottom: 0;
    border-radius: 26px;
    padding: 2px;
    overflow: hidden;
    background: #23d476;
    background: -moz-linear-gradient(138deg, #23d476 0%, #365ad9 84%);
    background: -webkit-linear-gradient(138deg, #23d476 0%, #365ad9 84%);
    background: linear-gradient(138deg, #23d476 0%, #365ad9 84%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#23d476",endColorstr="#365ad9",GradientType=1); }
    .h-case-study__heading::before {
      content: '';
      position: absolute;
      top: -77px;
      right: -29px;
      bottom: 0;
      left: 0;
      width: 225px;
      height: 225px;
      animation: rotation2 3s linear infinite;
      background: -moz-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: -webkit-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1);
      z-index: 9; }
  .h-case-study__heading-inner {
    color: #333;
    font-family: 'Poppins' !important;
    font-size: 25px;
    line-height: 34px;
    font-weight: 600;
    background: #fff;
    background-repeat: no-repeat;
    background-position: -344px -76px;
    display: flex;
    flex: 1;
    height: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 26px;
    position: relative;
    z-index: 9; }
  .h-case-study__title {
    font-family: 'Poppins' !important;
    font-size: 50px;
    line-height: 60px;
    font-weight: 700;
    max-width: 850px;
    margin-bottom: 25px;
    color: #fff; }
  .h-case-study__list {
    display: flex;
    align-items: center;
    margin-bottom: 25px; }
  .h-case-study__items {
    color: #fff;
    font-family: 'Poppins' !important;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;
    border-radius: 70px;
    padding: 10px 20px;
    border-radius: 70px;
    padding: 10px 20px;
    background: rgba(171, 168, 168, 0.3);
    margin: 3px; }
  .h-case-study__read-more {
    font-family: 'Poppins' !important;
    background: #FF5800;
    border: 1px solid #FF5800;
    border-radius: 5px;
    color: #fff;
    font-size: 17px;
    font-weight: 600;
    max-width: 248px;
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    margin-top: 35px; }
    .h-case-study__read-more:hover {
      background: #fff;
      color: #FF5800; }
      .h-case-study__read-more:hover path {
        fill: #FF5800; }
    .h-case-study__read-more svg {
      padding-left: 10px; }
  .h-case-study .owl-dots {
    margin-bottom: 25px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 1140px; }
    @media screen and (min-width: 992px) {
      .h-case-study .owl-dots {
        bottom: 60px;
        max-width: 930px; } }
    @media screen and (min-width: 1200px) {
      .h-case-study .owl-dots {
        max-width: 1140px; } }
    .h-case-study .owl-dots .owl-dot {
      background: #fff;
      width: 10px;
      height: 10px;
      display: inline-block;
      border-radius: 50%;
      margin-right: 15px; }
      .h-case-study .owl-dots .owl-dot.active {
        background: #3F6EE7;
        width: 13px;
        height: 13px; }
  .h-case-study .splide__pagination {
    margin-bottom: 25px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 1140px; }
    @media screen and (min-width: 992px) {
      .h-case-study .splide__pagination {
        bottom: 60px;
        max-width: 930px;
        justify-content: flex-start;
        padding-left: 0; } }
    @media screen and (min-width: 1200px) {
      .h-case-study .splide__pagination {
        max-width: 1140px; } }
    .h-case-study .splide__pagination .splide__pagination__page {
      background: #fff;
      width: 10px;
      height: 10px;
      display: inline-block;
      border-radius: 50%;
      margin-right: 10px;
      opacity: 1; }
      .h-case-study .splide__pagination .splide__pagination__page.is-active {
        background: #3F6EE7;
        width: 13px;
        height: 13px; }
  .h-case-study__slide-img-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .h-case-study__container {
    position: absolute;
    top: 60px;
    right: 0;
    left: 0;
    margin: 0 auto; }
  .h-case-study__slide-container {
    position: absolute;
    top: 130px;
    right: 0;
    left: 0;
    margin: 0 auto; }
  .h-case-study__slide-img-wrapper {
    height: 540px; }
  .h-case-study .sk-fading-circle {
    margin: 0 auto;
    position: absolute;
    text-align: center;
    top: 225px;
    left: 0;
    right: 0; }
  .h-case-study .cs-cont.invisible {
    height: 540px; }
  .h-case-study .sr-only {
    white-space: nowrap; }

/*Employee speak*/
.employees-speak {
  background-color: #062F5C;
  padding: 50px 0; }
  @media screen and (min-width: 992px) {
    .employees-speak {
      padding: 60px 0; } }
  .employees-speak__heading {
    margin-bottom: 40px;
    display: flex;
    align-items: center; }
  .employees-speak__heading-text {
    flex: 1; }
  .employees-speak__heading-rating {
    flex: 0 0 370px;
    max-width: 370px;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column; }
    .employees-speak__heading-rating:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 48px;
      height: 117px;
      background: url(/images/employees-speak/left-arrow.svg) no-repeat; }
    .employees-speak__heading-rating:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 69px;
      height: 130px;
      background: url(/images/employees-speak/right-arrow.svg) no-repeat; }
  .employees-speak__rating-title {
    font-family: 'Poppins' !important;
    color: #fff;
    font-size: 18px;
    line-height: 30px;
    font-weight: 600; }
  .employees-speak__rating {
    display: flex;
    align-items: center; }
  .employees-speak__title {
    font-family: 'Poppins' !important;
    font-size: 38px;
    line-height: 50px;
    font-weight: 700;
    color: #fff; }
    @media screen and (min-width: 992px) {
      .employees-speak__title {
        font-size: 51px;
        line-height: 56px; } }
  .employees-speak__sub-title {
    font-family: 'Poppins' !important;
    font-size: 25px;
    line-height: 32px;
    font-weight: 600;
    color: #35B15C; }
  .employees-speak__video {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 65px;
    flex-wrap: wrap; }
    @media screen and (min-width: 992px) {
      .employees-speak__video {
        flex-wrap: nowrap;
        justify-content: space-between; } }
  .employees-speak__card {
    max-width: 265px;
    height: 230px;
    position: relative;
    width: 44%;
    margin: 20px; }
    @media screen and (min-width: 992px) {
      .employees-speak__card {
        width: 24.5%;
        margin: 0; } }
    .employees-speak__card img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .employees-speak__card .vid-play-btn {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      font-size: 0; }
      .employees-speak__card .vid-play-btn:before {
        content: '';
        position: absolute;
        bottom: -30px;
        right: 20px;
        width: 59px;
        height: 59px;
        background: url(/images/employees-speak/play-btn.svg) no-repeat 0 0; }
  .employees-speak .testimonial-clip-modal {
    z-index: 9999999;
    background: rgba(0, 0, 0, 0.95); }
  .employees-speak .modal {
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0; }
  .employees-speak .modal.show .modal-dialog {
    transform: translate(0, 50px); }
  .employees-speak .testimonial-clip-modal .modal-dialog {
    max-width: 990px !important;
    margin: 0 auto; }
  .employees-speak .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
    display: flex;
    align-items: center; }
  .employees-speak .modal-dialog {
    position: relative;
    width: auto;
    pointer-events: none; }
  .employees-speak .testimonial-clip-modal .modal-content {
    background: 0 0;
    padding: 0;
    border: 0; }
  .employees-speak .testimonial-clip-modal .modal-header {
    border: 0;
    height: 0;
    padding: 0; }
  .employees-speak .modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px); }
  .employees-speak .testimonial-clip-modal .modal-content .modal-body {
    padding: 0; }
  .employees-speak .modal-backdrop.show {
    opacity: 0;
    display: none; }
  .employees-speak .modal.show .modal-dialog {
    transform: translate(0, 50px); }
  .employees-speak .testimonial-clip-modal .modal-header .btn-close {
    position: absolute;
    top: 0;
    right: 20px;
    background-color: #fff;
    opacity: 1;
    border-radius: 50%; }
    @media screen and (min-width: 992px) {
      .employees-speak .testimonial-clip-modal .modal-header .btn-close {
        top: -7px;
        right: 64px; } }
  .employees-speak .block-btn {
    font-family: 'Poppins' !important;
    max-width: 330px;
    padding: 15px;
    margin: 0 auto;
    background: #FF5800;
    border: 2px solid #FF5800;
    border-radius: 5px;
    color: #fff;
    display: block;
    text-align: center;
    font-size: 17px;
    font-weight: 600;
    transition: .3s all ease-in-out; }
    .employees-speak .block-btn svg {
      margin-left: 10px; }
    .employees-speak .block-btn:hover {
      color: #FF5800;
      background: #fff; }
      .employees-speak .block-btn:hover path {
        fill: #FF5800; }

/*Latest Blog*/
.latest-blog {
  padding: 60px 0; }
  .latest-blog__heading {
    font-family: 'Poppins' !important;
    font-size: 51px;
    font-weight: 700;
    color: #262E36; }
  .latest-blog__sub-heading {
    font-family: 'Poppins' !important;
    color: #35B15C;
    font-size: 25px;
    font-weight: 600; }
  .latest-blog__wrapper {
    font-family: 'Poppins' !important;
    display: flex;
    margin-top: 60px;
    flex-wrap: wrap; }
    @media screen and (min-width: 992px) {
      .latest-blog__wrapper {
        flex-wrap: nowrap; } }
  .latest-blog__left {
    flex: 0 0 100%; }
    @media screen and (min-width: 992px) {
      .latest-blog__left {
        flex: 0 0 550px; } }
  .latest-blog__title {
    font-family: 'Poppins' !important;
    font-size: 31px;
    line-height: 34px;
    font-weight: 600;
    color: #333;
    margin-top: 30px;
    transition: .3s all ease-in-out; }
    .latest-blog__title:hover {
      color: #35b15c; }
  .latest-blog__author {
    font-family: 'Poppins' !important;
    color: #000;
    font-size: 13px;
    font-weight: 400;
    margin-top: 10px; }
    .latest-blog__author span {
      color: #898989; }
    .latest-blog__author strong {
      font-weight: 600; }
  .latest-blog__right {
    margin-top: 50px; }
    @media screen and (min-width: 992px) {
      .latest-blog__right {
        margin-top: 0;
        margin-left: 70px; } }
  .latest-blog__items {
    padding-bottom: 34px;
    margin-bottom: 28px;
    border-bottom: 1px dashed #C8C8C8; }
    .latest-blog__items h5 {
      font-family: 'Poppins' !important;
      font-size: 25px;
      line-height: 32px;
      color: #333;
      transition: .3s all ease-in-out; }
    .latest-blog__items:hover h5 {
      color: #35b15c; }
    .latest-blog__items:last-child {
      border-bottom: none;
      margin-top: 35px; }
  .latest-blog .block-btn {
    font-family: 'Poppins' !important;
    background: #FF5800;
    color: #fff;
    border: 2px solid #FF5800;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 600;
    text-align: center;
    display: block;
    max-width: 333px;
    padding: 15px 0;
    transition: .3s all ease-in-out; }
    .latest-blog .block-btn svg {
      margin-left: 10px; }
    .latest-blog .block-btn:hover {
      background: #fff;
      color: #FF5800; }
      .latest-blog .block-btn:hover path {
        fill: #FF5800; }

/*Excellence Meets*/
.excellence-meets {
  position: relative;
  overflow: hidden;
  padding: 25px 0 0; }
  @media screen and (min-width: 992px) {
    .excellence-meets {
      padding: 0; } }
  .excellence-meets--toppadding {
    padding-top: 30px; }
    @media screen and (min-width: 992px) {
      .excellence-meets--toppadding {
        padding-top: 60px; } }
  .excellence-meets__tittle {
    font-family: 'Poppins';
    font-size: 22px;
    color: #333;
    line-height: 30px;
    margin-bottom: 25px;
    font-weight: 700;
    text-align: center;
    position: relative; }
    @media screen and (min-width: 992px) {
      .excellence-meets__tittle {
        font-size: 22px;
        line-height: 30px;
        text-align: left;
        margin-bottom: 16px;
        padding-bottom: 15px; } }
    .excellence-meets__tittle:after {
      content: "";
      width: 52px;
      height: 3px;
      position: absolute;
      background-color: #3CC065;
      position: absolute;
      left: 0;
      bottom: 0;
      display: none; }
      @media screen and (min-width: 992px) {
        .excellence-meets__tittle:after {
          display: block; } }
  .excellence-meets__subtittle {
    font-family: 'Poppins';
    font-size: 16px;
    line-height: 24px;
    color: #333;
    margin-bottom: 25px;
    font-weight: 500;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .excellence-meets__subtittle {
        text-align: left;
        font-size: 18px;
        line-height: 26px; } }
  .excellence-meets__clientlists-mb {
    margin-bottom: 25px; }
    @media screen and (min-width: 992px) {
      .excellence-meets__clientlists-mb {
        margin-bottom: 0; } }
    .excellence-meets__clientlists-mb .owl-dots {
      top: 30px;
      height: 73px; }
  .excellence-meets__clientlist {
    display: inherit; }
    @media screen and (min-width: 768px) {
      .excellence-meets__clientlist {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; } }
    @media screen and (min-width: 992px) {
      .excellence-meets__clientlist {
        justify-content: flex-start; } }
    .excellence-meets__clientlist .owl-stage {
      display: flex;
      align-items: center; }
  .excellence-meets__clientitem {
    display: flex;
    align-items: center;
    justify-content: center; }
    @media screen and (min-width: 992px) {
      .excellence-meets__clientitem {
        flex: 0 0 47%;
        display: flex;
        justify-content: flex-start;
        margin: 0 5px 20px !important; } }
  .excellence-meets .owl-stage {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0 10px; }
    @media screen and (min-width: 992px) {
      .excellence-meets .owl-stage {
        gap: 0; } }
  .excellence-meets__clienttext {
    font-family: 'Poppins';
    font-size: 15px;
    line-height: 26px;
    color: #333;
    font-weight: 500;
    margin-top: 0;
    display: block;
    margin-bottom: 30px;
    text-align: center;
    position: relative;
    top: -20px; }
    @media screen and (min-width: 770px) {
      .excellence-meets__clienttext {
        top: inherit; } }
    @media screen and (min-width: 992px) {
      .excellence-meets__clienttext {
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 0;
        text-align: left;
        position: static;
        margin-top: 20px;
        margin-bottom: 30px;
        top: -55px; } }
  .excellence-meets__meetsarea {
    position: relative;
    background-color: #355BBF;
    width: 100%;
    height: 100%;
    padding: 45px 20px 25px; }
    @media screen and (min-width: 992px) {
      .excellence-meets__meetsarea {
        padding: 40px 0 40px 40px; } }
    .excellence-meets__meetsarea:before {
      position: absolute;
      content: "";
      width: 1000%;
      height: 100%;
      background-color: #355BBF;
      right: -1000%;
      top: 0;
      bottom: 0;
      display: none; }
      @media screen and (min-width: 992px) {
        .excellence-meets__meetsarea:before {
          display: block; } }
    .excellence-meets__meetsarea:after {
      position: absolute;
      content: "";
      width: 1000%;
      height: 2px;
      background-color: #355BBF;
      bottom: -1px;
      left: 0;
      display: none; }
      @media screen and (min-width: 992px) {
        .excellence-meets__meetsarea:after {
          display: block; } }
    .excellence-meets__meetsarea .splide__pagination {
      bottom: -15px !important; }
    .excellence-meets__meetsarea .owl-stage-outer {
      position: relative; }
      .excellence-meets__meetsarea .owl-stage-outer::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: -16px;
        margin: 0 auto;
        width: 1px;
        height: 100%;
        background: #5B81E1; }
    .excellence-meets__meetsarea .owl-dots {
      top: 10px;
      height: 73px; }
  .excellence-meets .splide__pagination {
    position: absolute;
    bottom: -55px; }
  .excellence-meets .splide__pagination__page {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: #e1e1e1; }
    .excellence-meets .splide__pagination__page.is-active {
      width: 13px;
      height: 13px;
      background: #ff5800;
      transform: scale(1); }
  .excellence-meets__meetslist .splide__track {
    position: relative; }
    .excellence-meets__meetslist .splide__track::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: -16px;
      margin: 0 auto;
      width: 1px;
      height: 100%;
      background: #5B81E1; }
  .excellence-meets__meetstittle {
    font-family: 'Poppins';
    font-size: 34px;
    line-height: 36px;
    color: #84A5F9;
    font-weight: 900;
    margin-bottom: 20px; }
    @media screen and (min-width: 992px) {
      .excellence-meets__meetstittle {
        font-size: 60px;
        line-height: 66px; } }
    .excellence-meets__meetstittle svg {
      margin-top: -33px;
      width: 40px;
      position: relative;
      top: 2px;
      right: -6px; }
      @media screen and (min-width: 992px) {
        .excellence-meets__meetstittle svg {
          width: inherit;
          position: static; } }
  .excellence-meets__meetslist {
    display: flex;
    flex-wrap: wrap; }
    .excellence-meets__meetslist .splide__pagination {
      margin-top: 15px; }
    .excellence-meets__meetslist .splide__pagination__page {
      background: #9b9b9b; }
  .excellence-meets__meetsitem {
    position: relative;
    width: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0;
    padding-top: 0;
    padding-left: 10px;
    padding-right: 10px; }
    @media screen and (min-width: 770px) {
      .excellence-meets__meetsitem {
        width: 45%;
        margin: 10px;
        justify-content: flex-start; } }
    @media screen and (min-width: 992px) {
      .excellence-meets__meetsitem {
        width: 33.33%;
        padding-bottom: 30px;
        align-items: flex-start;
        flex-direction: row;
        border-right: 1px solid #5B81E1;
        border-bottom: 1px solid #5B81E1;
        padding-bottom: 15px;
        padding-top: 15px;
        padding-left: 15px;
        padding-right: 15px;
        margin: 0; } }
    .excellence-meets__meetsitem:after {
      display: none; }
      @media screen and (min-width: 992px) {
        .excellence-meets__meetsitem:after {
          display: block;
          position: absolute;
          content: "";
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: #355BBF;
          bottom: -7.5px;
          right: -7.5px;
          z-index: 1; } }
    .excellence-meets__meetsitem:nth-child(2n+2) {
      border-right: none; }
      .excellence-meets__meetsitem:nth-child(2n+2):after {
        display: none; }
        @media screen and (min-width: 992px) {
          .excellence-meets__meetsitem:nth-child(2n+2):after {
            display: block; } }
      @media screen and (min-width: 992px) {
        .excellence-meets__meetsitem:nth-child(2n+2) {
          border-right: 1px solid #5B81E1; } }
    @media screen and (min-width: 992px) {
      .excellence-meets__meetsitem:nth-child(3n+3) {
        border-right: none;
        padding-left: 10px; }
        .excellence-meets__meetsitem:nth-child(3n+3):after {
          display: none; } }
    @media screen and (min-width: 992px) {
      .excellence-meets__meetsitem:nth-last-child(-n+3) {
        border-bottom: none;
        padding-bottom: 5px; }
        .excellence-meets__meetsitem:nth-last-child(-n+3):after {
          display: none; } }
    .excellence-meets__meetsitem:nth-last-child(-n+2) {
      border-bottom: none; }
      @media screen and (min-width: 992px) {
        .excellence-meets__meetsitem:nth-last-child(-n+2) {
          padding-bottom: 5px; } }
    .excellence-meets__meetsitem:nth-child(5) {
      justify-content: center; }
      @media screen and (min-width: 770px) {
        .excellence-meets__meetsitem:nth-child(5) {
          justify-content: flex-start; } }
      @media screen and (min-width: 992px) {
        .excellence-meets__meetsitem:nth-child(5) {
          justify-content: center; } }
    .excellence-meets__meetsitem img {
      margin-right: 10px; }
      @media screen and (min-width: 992px) {
        .excellence-meets__meetsitem img {
          margin-right: 0; } }
  .excellence-meets__listcontent {
    margin-top: 5px;
    margin-left: 0;
    max-width: 100%;
    text-align: left; }
    @media screen and (min-width: 992px) {
      .excellence-meets__listcontent {
        margin-top: 0;
        margin-left: 15px;
        max-width: 162px; } }
  .excellence-meets__contenttittle {
    font-family: 'Poppins';
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
    color: #fff;
    margin: 0; }
    @media screen and (min-width: 992px) {
      .excellence-meets__contenttittle {
        font-size: 25px;
        line-height: 26px; } }
  .excellence-meets__contenttext {
    font-family: 'Poppins';
    color: #fff;
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
    margin: 0; }
  .excellence-meets__officetittle {
    margin: 30px 0 15px;
    font-family: 'Poppins';
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    color: #84A5F9;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .excellence-meets__officetittle {
        margin: 28px 0 15px;
        text-align: left; } }
  .excellence-meets__officelist {
    width: 100%;
    display: flex;
    padding: 6px 8px;
    border-radius: 5px;
    border: 1px solid #84A5F9; }
  .excellence-meets__officeitem {
    height: 120px;
    border-radius: 5px;
    overflow: hidden; }
  .excellence-meets__officeitems {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%; }
    .excellence-meets__officeitems:before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.1); }
    .excellence-meets__officeitems img {
      object-fit: cover;
      width: 100%;
      height: 100%; }
    .excellence-meets__officeitems:hover:before {
      background-color: rgba(0, 0, 0, 0.3); }
    .excellence-meets__officeitems:hover:after {
      position: absolute;
      content: "";
      width: 30px;
      height: 30px;
      background: url(/images/two-for-one/pop-up-icon.svg) no-repeat center;
      background-size: 100%;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      margin: auto; }
  .excellence-meets .office-wrapper {
    padding: 6px 8px;
    border-radius: 5px;
    border: 1px solid #84a5f9; }
  .excellence-meets__officebuttonarea {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
  .excellence-meets__officebtn {
    font-family: 'Poppins';
    margin: 0 0 15px 0;
    max-width: 100%;
    width: 100%;
    font-size: 17px;
    font-weight: 600;
    padding: 17px 25px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media screen and (min-width: 992px) {
      .excellence-meets__officebtn {
        max-width: 215px;
        margin: 0 13px 0 0; } }
    .excellence-meets__officebtn svg {
      margin-left: 10px; }
    .excellence-meets__officebtn:hover g {
      fill: #ff5800; }
  .excellence-meets__takebtn {
    margin: 0;
    max-width: 100%;
    width: 100%;
    border: 2px solid #fff;
    color: #fff;
    font-family: 'Poppins';
    font-size: 17px;
    font-weight: 600;
    padding: 17px 25px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media screen and (min-width: 992px) {
      .excellence-meets__takebtn {
        max-width: 307px; } }
    .excellence-meets__takebtn img {
      max-width: 20px;
      margin-right: 10px; }
      @media screen and (min-width: 992px) {
        .excellence-meets__takebtn img {
          max-width: 30px;
          margin-right: 15px; } }
    .excellence-meets__takebtn svg {
      margin-left: 10px; }
    .excellence-meets__takebtn:hover {
      background-color: #fff;
      color: #ff5800; }
      .excellence-meets__takebtn:hover g {
        fill: #ff5800; }
  .excellence-meets .owl-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0 25px;
    position: relative;
    gap: 0 10px;
    height: 45px;
    margin: 0; }
    @media screen and (min-width: 992px) {
      .excellence-meets .owl-dots {
        padding: 30px 0;
        top: 35px;
        height: 73px; } }
    .excellence-meets .owl-dots .owl-dot {
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background: #E1E1E1; }
      .excellence-meets .owl-dots .owl-dot.active {
        width: 13px;
        height: 13px;
        background: #FF5800; }
  .excellence-meets__asterisktext {
    color: #fff;
    margin: 5px 0 0;
    line-height: normal; }

.slbOverlay {
  z-index: 999999 !important;
  opacity: 0.9 !important; }

.slbWrapOuter {
  z-index: 999999 !important; }

@media screen and (min-width: 767px) {
  .home-excellence {
    border-top: 1px solid #cbcbcb; } }

@media screen and (min-width: 767px) {
  .home-excellence .col-lg-4 {
    padding-top: 30px; } }

/*Custom Review*/
.custom-review {
  position: relative; }
  .custom-review__spacing {
    padding: 30px 0 35px; }
    @media screen and (min-width: 992px) {
      .custom-review__spacing {
        padding: 60px 0 50px; } }
    .custom-review__spacing--light {
      padding: 35px 0; }
      @media screen and (min-width: 992px) {
        .custom-review__spacing--light {
          padding: 50px 0; } }
  .custom-review__tittleimg {
    display: block;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .custom-review__tittleimg {
        text-align: left; } }
  .custom-review__tittle {
    position: relative;
    font-family: 'Poppins';
    font-size: 25px;
    line-height: 35px;
    color: #262E36;
    padding-bottom: 20px;
    margin-bottom: 22px;
    font-weight: 700;
    text-align: center; }
    @media screen and (min-width: 770px) {
      .custom-review__tittle {
        margin-top: 10px; } }
    @media screen and (min-width: 992px) {
      .custom-review__tittle {
        text-align: left;
        font-size: 34px;
        line-height: 46px;
        margin-top: 25px;
        padding-bottom: 34px; } }
    .custom-review__tittle:after {
      position: absolute;
      content: "";
      width: 83px;
      height: 7px;
      background-color: #498DF9;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto; }
      @media screen and (min-width: 992px) {
        .custom-review__tittle:after {
          right: inherit;
          margin: 0; } }
  .custom-review__subtittle {
    font-family: 'Poppins';
    font-size: 18px;
    line-height: 27px;
    color: #333;
    font-weight: 600;
    margin: 0 0 15px;
    text-align: left; }
    @media screen and (min-width: 992px) {
      .custom-review__subtittle {
        font-size: 22px;
        line-height: 32px;
        text-align: left;
        padding: 0 20px 0 0; } }
  .custom-review__reviewarea {
    position: relative; }
    .custom-review__reviewarea .owl-nav {
      display: block !important;
      text-align: left;
      margin-bottom: 0;
      margin-top: 0; }
      @media screen and (min-width: 992px) {
        .custom-review__reviewarea .owl-nav {
          float: right;
          margin-bottom: 0;
          margin-top: 10px;
          text-align: center; } }
    .custom-review__reviewarea .splide__arrow--prev {
      font-size: 45px !important;
      color: #3cc065 !important;
      width: 30px;
      height: 30px;
      border: 1px solid #3cc065 !important;
      line-height: 0;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 0 5px;
      font-weight: 300 !important;
      background: none; }
      @media screen and (min-width: 992px) {
        .custom-review__reviewarea .splide__arrow--prev {
          width: 50px;
          height: 50px; } }
      .custom-review__reviewarea .splide__arrow--prev:hover {
        color: #fff !important;
        background-color: #3cc065 !important; }
        .custom-review__reviewarea .splide__arrow--prev:hover svg path {
          fill: #fff; }
      .custom-review__reviewarea .splide__arrow--prev span {
        position: relative;
        top: -3px;
        left: -1px;
        height: 50px;
        line-height: 50px; }
    .custom-review__reviewarea .splide__arrow--next {
      font-size: 45px !important;
      color: #3cc065 !important;
      width: 30px;
      height: 30px;
      border: 1px solid #3cc065 !important;
      line-height: 0;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 0 5px;
      font-weight: 300 !important;
      background: none; }
      @media screen and (min-width: 992px) {
        .custom-review__reviewarea .splide__arrow--next {
          width: 50px;
          height: 50px; } }
      .custom-review__reviewarea .splide__arrow--next:hover {
        color: #fff !important;
        background-color: #3cc065 !important; }
        .custom-review__reviewarea .splide__arrow--next:hover svg path {
          fill: #fff; }
      .custom-review__reviewarea .splide__arrow--next span {
        position: relative;
        top: -3px;
        right: -1px;
        height: 50px;
        line-height: 50px; }
  .custom-review__reviewitem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 18px; }
  .custom-review__reviewitems {
    max-width: 100%;
    width: 100%;
    min-height: 300px;
    height: auto;
    border: 1px solid #ddd;
    padding: 25px;
    margin-bottom: 5px;
    transition: all .5s ease-in-out;
    display: flex;
    flex-direction: column; }
    @media screen and (min-width: 1024px) {
      .custom-review__reviewitems {
        max-width: 277px;
        margin-bottom: 15px; } }
    @media screen and (min-width: 1120px) {
      .custom-review__reviewitems {
        max-width: 330px; } }
    @media screen and (min-width: 1300px) {
      .custom-review__reviewitems {
        max-width: 345px; } }
    .custom-review__reviewitems:hover {
      border: 1px solid #fff;
      box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.2); }
  .custom-review__quoteimg {
    display: block;
    margin-bottom: 10px; }
  .custom-review__quotecontent {
    font-family: 'Poppins';
    color: #333;
    font-size: 18px;
    line-height: 27px;
    font-weight: 500; }
  .custom-review__authorarea {
    margin-top: 30px;
    display: flex; }
    @media screen and (min-width: 992px) {
      .custom-review__authorarea {
        margin-top: auto; } }
  .custom-review__authorimage {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden; }
  .custom-review__authordetails {
    margin-left: 16px; }
  .custom-review__authorname {
    font-family: 'Poppins';
    font-size: 18px;
    line-height: normal;
    color: #333;
    font-weight: 600;
    margin: 0; }
  .custom-review__authordesignation {
    font-family: 'Poppins';
    font-size: 14px;
    line-height: 20px;
    color: #333;
    margin: 0;
    font-weight: 600; }
  .custom-review__authorcompany {
    font-family: 'Poppins';
    font-size: 14px;
    line-height: 20px;
    color: #898989;
    margin: 0;
    font-weight: 500; }
  .custom-review__btn {
    margin: 0 auto;
    max-width: 360px;
    width: auto;
    font-family: 'Poppins';
    font-size: 17px;
    font-weight: 600;
    padding: 17px 25px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media screen and (min-width: 992px) {
      .custom-review__btn {
        max-width: 333px;
        margin-left: 15px;
        width: 100%;
        margin: 0 0 0 14px; } }
    .custom-review__btn svg {
      margin-left: 10px; }
    .custom-review__btn:hover g {
      fill: #ff5800; }
  .custom-review__partner {
    column-count: 2;
    margin: 18px 0 25px; }
  .custom-review__partnerlist {
    min-height: 120px;
    margin: 0 20px 7px 0;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media screen and (min-width: 992px) {
      .custom-review__partnerlist {
        margin: 0 30px 10px 0; } }
    .custom-review__partnerlist:nth-last-child(-n+2) {
      margin: 0 30px 0 0; }
  .custom-review__rankribbon {
    position: relative;
    font-family: 'Poppins';
    color: #fff;
    font-size: 21px;
    line-height: 34px;
    font-weight: 700;
    max-width: 318px;
    width: 100%;
    text-align: center;
    margin: auto;
    background-color: #3CC065;
    padding: 5.5px;
    margin-bottom: 30px; }
    @media screen and (min-width: 992px) {
      .custom-review__rankribbon {
        margin-bottom: 0; } }
    .custom-review__rankribbon:after {
      position: absolute;
      content: "";
      right: 0;
      bottom: -16px;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 16px 30px 0 0;
      border-color: #4E6454 transparent transparent transparent;
      transform: rotate(0deg); }
  .custom-review .col-lg-8-rel {
    position: relative; }
  .custom-review .sk-fading-circle {
    margin: 0 auto;
    position: absolute;
    text-align: center;
    top: 120px;
    left: 0;
    right: 0; }
    @media screen and (min-width: 992px) {
      .custom-review .sk-fading-circle {
        top: 239px; } }
  .custom-review .cs-cont.invisible {
    height: 324px; }
    @media screen and (min-width: 992px) {
      .custom-review .cs-cont.invisible {
        height: 648px; } }
  .custom-review .splide__arrows {
    display: flex;
    position: absolute;
    bottom: -6px;
    right: 40px; }
    @media screen and (min-width: 992px) {
      .custom-review .splide__arrows {
        bottom: -30px;
        right: 53px; } }
  .custom-review .splide__arrow--prev {
    right: 8px;
    left: inherit; }
    @media screen and (min-width: 992px) {
      .custom-review .splide__arrow--prev {
        right: 20px; } }
    .custom-review .splide__arrow--prev svg {
      width: 18px; }
  .custom-review .splide__arrow--next {
    right: -40px;
    left: inherit; }
    .custom-review .splide__arrow--next svg {
      width: 18px; }

.trustpilot-widget-mod {
  top: 20px; }

.custom-review__reviewitem {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  @media screen and (min-width: 992px) {
    .custom-review__reviewitem .slide {
      padding: 0 15px 0 15px; } }

.grt-slider-light .splide__arrows {
  display: none; }

/*New FAQ*/
.new-faq {
  font-family: 'Poppins';
  font-size: 51px;
  font-weight: 700;
  color: #262E36;
  background: #DDF0F8;
  padding-bottom: 50px;
  padding-top: 30px !important; }
  @media screen and (min-width: 992px) {
    .new-faq {
      padding: 60px 0 60px !important; } }
  .new-faq__wrapper {
    height: 335px;
    overflow: hidden;
    position: relative;
    max-width: 830px;
    margin: 0 auto; }
    @media screen and (min-width: 992px) {
      .new-faq__wrapper {
        height: 275px; } }
  .new-faq__blur--active {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 50%;
    background: #ddf0f8;
    background: -moz-linear-gradient(180deg, rgba(221, 240, 248, 0) 0, #ddf0f8 100%);
    background: -webkit-linear-gradient(180deg, rgba(221, 240, 248, 0) 0, #ddf0f8 100%);
    background: linear-gradient(180deg, rgba(221, 240, 248, 0) 0, #ddf0f8 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ddf0f8",endColorstr="#ddf0f8",GradientType=1);
    z-index: 99; }
    @media screen and (min-width: 992px) {
      .new-faq__blur--active {
        height: 52%; } }
    .new-faq__blur--active:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(0.1rem); }
  @media screen and (min-width: 992px) {
    .new-faq__blur--active-dita {
      height: 65%; } }
  .new-faq .faq-holder {
    background: none;
    border-bottom: 1px solid #333333;
    box-shadow: none;
    margin-bottom: 0; }
    .new-faq .faq-holder .accordion1 {
      font-family: 'Poppins';
      font-size: 17px;
      line-height: 25px;
      color: #262E36;
      font-weight: 600;
      background: none;
      padding: 15px 50px 15px 0; }
      @media screen and (min-width: 992px) {
        .new-faq .faq-holder .accordion1 {
          font-size: 17px;
          line-height: 20px;
          padding: 20px 0; } }
      .new-faq .faq-holder .accordion1:after {
        font-size: 36px;
        position: absolute;
        right: 2px;
        width: 37px;
        height: 37px;
        line-height: 37px;
        color: #333;
        border: 1px solid #333;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center; }
    .new-faq .faq-holder .panel1 {
      font-family: 'Poppins';
      padding: 0;
      background: none;
      font-size: 16px;
      line-height: 26px;
      font-weight: 400; }
      .new-faq .faq-holder .panel1 p {
        font-family: 'Poppins' !important;
        font-size: 16px;
        line-height: 26px;
        font-weight: 400; }
      .new-faq .faq-holder .panel1 li {
        font-family: 'Poppins' !important;
        font-size: 16px;
        line-height: 26px;
        font-weight: 400; }
  .new-faq .click-to-btn {
    text-align: center;
    position: absolute;
    bottom: -60px;
    z-index: 99; }
    .new-faq .click-to-btn span {
      font-family: 'Poppins';
      font-size: 17px;
      font-weight: 600;
      background: #fff;
      color: #333;
      max-width: 230px;
      display: block;
      border: 2px solid #333;
      border-radius: 5px;
      padding: 15px 0;
      margin: 0 auto;
      cursor: pointer; }
    .new-faq .click-to-btn svg {
      position: relative;
      top: -10px; }
  .new-faq h2 {
    font-weight: 700; }
  .new-faq .subheading {
    font-family: 'Poppins';
    font-size: 25px;
    line-height: 34px;
    font-weight: 600;
    color: #333;
    display: block;
    max-width: 810px;
    margin: 0 auto 35px;
    text-align: center; }

/*New Bottom Form*/
.new-bottom-form {
  padding: 35px 0 40px;
  background: #052c58 !important; }
  @media screen and (min-width: 992px) {
    .new-bottom-form {
      background-color: #052c58 !important;
      background-repeat: no-repeat !important;
      background-position: 0 0;
      padding: 0 0 40px; } }
  .new-bottom-form .container {
    padding: 0 15px; }
  .new-bottom-form__wrapper {
    position: relative; }
    @media screen and (min-width: 992px) {
      .new-bottom-form__wrapper {
        display: flex; } }
  .new-bottom-form__left {
    position: relative;
    z-index: 99;
    margin-right: 0; }
    @media screen and (min-width: 992px) {
      .new-bottom-form__left {
        padding-top: 60px;
        flex: 0 0 58%;
        margin-right: 45px; } }
    @media screen and (min-width: 1180px) {
      .new-bottom-form__left {
        margin-right: 90px; } }
    .new-bottom-form__left .attachment-list {
      width: 100%; }
      @media screen and (min-width: 992px) {
        .new-bottom-form__left .attachment-list {
          width: 99%;
          display: flex;
          flex-wrap: wrap;
          gap: 0 10px; } }
      @media screen and (min-width: 992px) {
        .new-bottom-form__left .attachment-list li {
          flex: 0 0 49%;
          max-width: 49% !important; } }
  .new-bottom-form__right {
    position: relative;
    display: inline-block; }
    @media screen and (min-width: 992px) {
      .new-bottom-form__right {
        display: inherit;
        flex: 1; } }
  .new-bottom-form h2 {
    font-family: "Poppins";
    font-size: 31px;
    line-height: 38px;
    font-weight: 700;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .new-bottom-form h2 {
        text-align: left; } }
  .new-bottom-form .form-sub-heading {
    font-family: "Poppins";
    padding: 0;
    color: #fff;
    font-size: 25px;
    line-height: 30px;
    font-weight: 600;
    margin: 0 0 25px; }
    @media screen and (min-width: 992px) {
      .new-bottom-form .form-sub-heading {
        color: #75a3f0;
        margin: 15px 0 25px; } }
  .new-bottom-form .o-contact-form__contact-form {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  .new-bottom-form .form-panel {
    width: 100%;
    padding: 0;
    background: none; }
  .new-bottom-form input {
    font-family: "Poppins";
    background: none;
    border: 1px solid #98bcf8;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 500;
    padding: 15px 15px;
    margin-bottom: 15px;
    color: #fff;
    flex: 0 0 100%; }
    @media screen and (min-width: 992px) {
      .new-bottom-form input {
        flex: 0 0 48.5%;
        margin-bottom: 20px;
        padding: 20px 15px; } }
    .new-bottom-form input::placeholder {
      font-size: 17px;
      font-weight: 500 !important;
      color: #fff; }
    .new-bottom-form input[type="number"] {
      -moz-appearance: textfield;
      -webkit-appearance: none;
      appearance: none; }
    .new-bottom-form input::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0; }
    .new-bottom-form input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; }
  .new-bottom-form textarea {
    font-family: "Poppins";
    flex: 0 0 100%;
    border: 1px solid #98bcf8;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 500;
    background: none !important;
    padding: 25px 15px;
    height: 120px;
    margin-bottom: 15px;
    color: #fff; }
    @media screen and (min-width: 992px) {
      .new-bottom-form textarea {
        flex: 0 0 100%;
        height: 150px; } }
    .new-bottom-form textarea::placeholder {
      font-size: 17px;
      font-weight: 500 !important;
      color: #fff; }
  .new-bottom-form .attach {
    background-position: 0 15px;
    flex: 0 0 100%; }
    @media screen and (min-width: 767px) {
      .new-bottom-form .attach {
        background-position: 0 0;
        padding: 0px 20px 0 40px;
        margin-bottom: 20px;
        margin-top: 10px; } }
  .new-bottom-form .o-contact-form__attachement-text {
    font-family: "Poppins";
    color: #75a3f0;
    font-size: 16px; }
  .new-bottom-form label {
    color: #fff; }
  .new-bottom-form__btn-wrapper {
    margin-top: 15px;
    margin-bottom: 0;
    flex-direction: column-reverse;
    width: 100%;
    display: flex; }
    @media screen and (min-width: 992px) {
      .new-bottom-form__btn-wrapper {
        margin-top: 10px;
        margin-bottom: 50px;
        flex: 0 0 100%;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-end;
        width: inherit; } }
    .new-bottom-form__btn-wrapper button {
      font-family: "Poppins";
      margin: 0 auto !important;
      padding: 19px 0;
      font-size: 17px;
      font-weight: 600;
      width: 360px;
      text-transform: capitalize; }
      @media screen and (min-width: 992px) {
        .new-bottom-form__btn-wrapper button {
          width: 280px;
          margin: 0 !important; } }
  .new-bottom-form .confidential-text-wrap {
    flex: 1;
    text-align: center;
    margin-top: 0;
    margin-bottom: 20px; }
    @media screen and (min-width: 992px) {
      .new-bottom-form .confidential-text-wrap {
        text-align: left;
        margin-top: 0;
        margin-bottom: 0; } }
    .new-bottom-form .confidential-text-wrap ul li {
      font-family: "Poppins";
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      padding: 0 15px; }
      .new-bottom-form .confidential-text-wrap ul li:first-child {
        padding-left: 0; }
      .new-bottom-form .confidential-text-wrap ul li::after {
        background: #215894;
        height: 16px;
        top: 4px; }
      .new-bottom-form .confidential-text-wrap ul li img {
        width: 17px;
        height: 17px; }
  .new-bottom-form__whats-next {
    padding-top: 35px;
    border-top: 1px solid #215894;
    margin-top: 45px; }
    @media screen and (min-width: 992px) {
      .new-bottom-form__whats-next {
        margin-top: 0; } }
    .new-bottom-form__whats-next h3 {
      font-family: "Poppins";
      font-size: 31px;
      font-weight: 700;
      color: #fff; }
    .new-bottom-form__whats-next p {
      font-family: "Poppins";
      font-size: 21px;
      font-weight: 600;
      color: #4492dc; }
  .new-bottom-form .form-right {
    flex: 0 0 60%; }
  .new-bottom-form .form-left {
    flex: 0 0 100%;
    width: 100%;
    padding: 0;
    position: relative;
    background-color: transparent; }
  .new-bottom-form__email {
    text-align: center;
    background: #135195;
    padding: 60px 40px 60px; }
    .new-bottom-form__email img {
      margin-bottom: 25px; }
    .new-bottom-form__email h4 {
      font-family: "Poppins";
      font-size: 22px;
      line-height: 30px;
      font-weight: 600;
      color: #bad2ed; }
      .new-bottom-form__email h4 a {
        font-weight: 700;
        color: #f3f6f9;
        position: relative; }
        .new-bottom-form__email h4 a:before {
          content: "";
          background: #5289c7;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px; }
  .new-bottom-form__trust {
    background: #04254a;
    padding: 50px 40px 40px; }
    .new-bottom-form__trust h5 {
      font-family: "Poppins";
      font-size: 22px;
      line-height: 28px;
      font-weight: 700;
      color: #f3f6f9;
      margin-bottom: 25px; }
      .new-bottom-form__trust h5 span {
        color: #bad2ed;
        font-weight: 400; }
    .new-bottom-form__trust ul {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 0;
      padding-bottom: 20px;
      margin-bottom: 20px;
      gap: 15px; }
      .new-bottom-form__trust ul li {
        flex: 0 0 31%;
        margin-right: 0;
        display: flex;
        align-items: center;
        justify-content: center; }
        @media screen and (min-width: 992px) {
          .new-bottom-form__trust ul li {
            flex: 0 0 47%;
            justify-content: flex-start; } }
        .new-bottom-form__trust ul li img {
          filter: none; }
        @media screen and (min-width: 992px) {
          .new-bottom-form__trust ul li:nth-of-type(odd) {
            justify-content: flex-start; } }
        @media screen and (min-width: 992px) {
          .new-bottom-form__trust ul li:nth-of-type(even) {
            justify-content: flex-end; } }
        .new-bottom-form__trust ul li:nth-child(3) {
          margin-top: 15px; }
        .new-bottom-form__trust ul li:nth-child(4) {
          margin-top: 15px; }
          @media screen and (min-width: 992px) {
            .new-bottom-form__trust ul li:nth-child(4) {
              align-items: flex-start; } }
        .new-bottom-form__trust ul li:nth-child(2) {
          justify-content: center; }
        .new-bottom-form__trust ul li:nth-child(6) {
          justify-content: center; }
    .new-bottom-form__trust h6 {
      font-family: "Poppins";
      color: #75a3f0;
      font-size: 15px;
      font-weight: 500; }

.skill-bottom-form-custom-dev h2 {
  font-weight: 500; }

/*Home Event Section*/
.o-latest-events {
  position: relative; }
  .o-latest-events__list {
    margin: 0 auto 120px;
    max-width: 950px;
    width: 100%; }
  .o-latest-events__box {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: fit-content;
    max-height: fit-content;
    border: 1px solid #d9d9d9;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    text-decoration: none;
    color: #333;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column; }
    .o-latest-events__box:hover {
      color: #333; }
    @media screen and (min-width: 768px) {
      .o-latest-events__box {
        flex-direction: row;
        max-height: 280px;
        min-height: 280px; } }
  .o-latest-events__box-img {
    position: relative;
    flex: 0 0 auto; }
    @media screen and (min-width: 768px) {
      .o-latest-events__box-img {
        height: 100%;
        flex: 0 0 53%; } }
    .o-latest-events__box-img img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
  .o-latest-events__box-status-current {
    position: absolute;
    right: 5px;
    top: 5px;
    height: 40px;
    background-color: #28a745;
    font-family: Poppins;
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px; }
    @media screen and (min-width: 768px) {
      .o-latest-events__box-status-current {
        top: 15px;
        right: -15px; } }
  .o-latest-events__box-status-upcoming {
    position: absolute;
    right: 5px;
    top: 5px;
    height: 40px;
    background-color: #fd7e14;
    font-family: Poppins;
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px; }
    @media screen and (min-width: 768px) {
      .o-latest-events__box-status-upcoming {
        top: 15px;
        right: -15px; } }
  .o-latest-events__box-details {
    padding: 20px;
    flex: 0 0 auto;
    display: inline-flex;
    flex-direction: column;
    justify-content: center; }
    @media screen and (min-width: 768px) {
      .o-latest-events__box-details {
        padding: 20px 38px;
        flex: 0 0 47%; } }
    @media screen and (min-width: 992px) {
      .o-latest-events__box-details {
        padding: 25px 60px; } }
  .o-latest-events__box-name {
    font-family: Poppins;
    font-size: 24px;
    line-height: 29px;
    font-weight: 700;
    color: #333;
    margin-bottom: 5px; }
  .o-latest-events__box-sub {
    font-family: Poppins;
    font-size: 18px;
    line-height: 26px; }
  .o-latest-events__btn {
    max-width: 248px;
    height: 62px;
    text-transform: capitalize;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .o-latest-events__box-btn {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    color: #333;
    border-radius: 5px;
    border: 1px solid #ff5800;
    max-width: 245px;
    padding: 15px 0;
    display: block;
    text-align: center;
    transition: .3s all ease-in-out;
    margin: 20px 0 0; }
    .o-latest-events__box-btn svg {
      transition: .3s all ease-in-out;
      margin-left: 15px; }
    .o-latest-events__box-btn:hover {
      background: #ff5800;
      color: #fff; }
      .o-latest-events__box-btn:hover svg {
        transform: translateX(10px); }
        .o-latest-events__box-btn:hover svg path {
          fill: #fff; }
  .o-latest-events .splide__arrows {
    display: block; }
  .o-latest-events .splide__arrow {
    font-size: 45px;
    color: #000;
    background-color: #fff;
    width: 45px;
    height: 45px;
    border: 1px solid #3cc065;
    line-height: 0;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    font-weight: 300;
    bottom: -110px;
    top: inherit; }
    .o-latest-events .splide__arrow svg {
      position: relative;
      height: 20px;
      line-height: 0;
      width: 20px; }
    .o-latest-events .splide__arrow--prev {
      left: 32%; }
      @media screen and (min-width: 992px) {
        .o-latest-events .splide__arrow--prev {
          left: 40%; } }
      @media screen and (min-width: 1110px) {
        .o-latest-events .splide__arrow--prev {
          left: 44%; } }
    .o-latest-events .splide__arrow--next {
      right: 32%; }
      @media screen and (min-width: 992px) {
        .o-latest-events .splide__arrow--next {
          right: 40%; } }
      @media screen and (min-width: 1110px) {
        .o-latest-events .splide__arrow--next {
          right: 44%; } }

/* Section Footer */
.section-footer {
  background-color: #04254a;
  padding: 40px 0px 30px;
  overflow: hidden;
  position: relative; }
  @media screen and (min-width: 992px) {
    .section-footer {
      padding: 50px 0 30px; } }
  .section-footer__navarea {
    padding-bottom: 30px; }
    @media (min-width: 767px) {
      .section-footer__navarea {
        padding-bottom: 40px; } }
  .section-footer__navbar-heading {
    color: #fff;
    font-size: 16px;
    line-height: 25px;
    font-weight: 600;
    font-family: "Poppins";
    margin-bottom: 15px; }
    .section-footer__navbar-heading::after {
      content: " ";
      display: block;
      background: #3cc065;
      height: 2px;
      width: 31px;
      position: relative;
      margin-top: 5px; }
  .section-footer__two-navbar-list {
    display: grid;
    grid-template-columns: 1fr 1fr; }
  .section-footer__a-tag {
    color: #c4e0f5;
    font-size: 15px;
    font-weight: 400;
    font-family: "Poppins";
    line-height: 28px;
    transition: 0.3s all ease-in-out; }
    .section-footer__a-tag:hover {
      color: #fff; }
  .section-footer__divider {
    background-color: #15467e;
    height: 1px;
    width: 100%;
    margin-bottom: 35px; }
  .section-footer__copy-right-details {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: #aec7da;
    font-family: "Poppins";
    margin-bottom: 0px;
    margin-bottom: 15px; }
    @media (min-width: 767px) {
      .section-footer__copy-right-details {
        margin-bottom: 0px; } }
    @media screen and (min-width: 992px) {
      .section-footer__copy-right-details {
        margin-bottom: 30px; } }
  .section-footer__social-list {
    display: flex;
    gap: 5px;
    position: relative;
    z-index: 1; }
  .section-footer__align-cener {
    display: flex;
    align-items: center; }
